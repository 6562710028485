.header_not_found {
  margin-top: 70px;
  font-size: 42px;
  font-weight: bold;
  text-align: right;
  color: #005589;
}
.header_not_found2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
  color: #005589;
}
.button_not_found {
  width: 169px;
  height: 48px;
  margin-top: 23px;
  border-radius: 10px;
  border: solid 1px #33e0a0;
  background-color: #fff;
  color: #005589;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_not_found:hover,
.button_not_found:focus,
.button_not_found:active {
  color: #fff;
  background-color: #33e0a0;
}
.list_notfound {
  list-style: none;
  width: 100%;
  padding: 0px;
  margin-bottom: 110px;
  margin-top: 30px;
}
.img_notfound {
  width: 405px;
}
.img_right_notfound {
  width: 405px;
  margin-top: -403px;
  position: relative;
  animation-delay: 0.7s;
  animation-name: example;
  animation-timing-function: ease-in-out;
  animation-duration: 4s;
  animation-iteration-count: 10000;
}
.img_left_notfound {
  width: 405px;
  margin-top: -453px;
  position: relative;
  animation-name: example;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 10000;
}
.mt-30notfound {
  margin-top: -15px;
}
@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }
  /* 17% {
    left: -2.5px;
    top: -2.5px;
  } */
  34% {
    left: -5px;
    top: -5px;
  }
  /* 50% {
    left: -2.5px;
    top: -6.25px;
  } */
  66% {
    left: 0px;
    top: -7.5px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
@media only screen and (min-width: 673.5px) and (max-width: 767.5px) {
}
@media only screen and (max-width: 767.5px) {
  .header_not_found {
    margin-top: 60px;
    font-size: 26px;
    text-align: center;
  }
  .header_not_found2 {
    margin-top: -80px;
    font-size: 20px;
    text-align: center;
  }
  .mt-30notfound {
    margin-top: 0px;
    text-align: center;
  }
  .img_notfound {
    max-width: 325px;
    width: 100%;
  }
  .img_right_notfound {
    max-width: 325px;
    width: 100%;
    margin-top: -326px;
  }
  .img_left_notfound {
    max-width: 325px;
    width: 100%;
    margin-top: -376px;
  }
  .list_notfound {
    list-style: none;
    width: 100%;
    padding: 0px;
    margin-bottom: 65px;
    margin-top: -30px;
  }
  .button_not_found {
    width: 130px;
    height: 45px;
    margin: 6px 6px;
    border-radius: 6px;
    font-size: 18px;
    display: flex;
  }
}
