.costum_modal_login {
    width: 528px;
    height: 143px;
    padding: 10px 30px 10px 10px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgb(176 176 176 / 16%);
    border: solid 1px #c9c9c9;
    background-color: #fff;
    /* z-index: 16000; */
    border: solid 1px #c9c9c9;
}

.close_modal_login {
    position: absolute;
    left: 0px;
    display: block;
    width: 12px;
    height: 12px;
    background-size:12px ;
}

.text_modal_login {
    margin-top: 24px;
    margin-right: -5px;
    margin-bottom: 0;
    /* width: 452px; */
    font-size: 16px;
    /* line-height: 0.63; */
    letter-spacing: -0.4px;
    text-align: center;
    color: #005589;
}

.button_modal_login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
    text-align: center;
    border: 0px;
    color: #fff;
    width: 78px;
    height: 33px;
    /* margin: 26px 40px 0 208px; */
    /* padding: 0 22px 3px; */
    border-radius: 6px;
    background-color: #005589;
}
.button_modal_login:hover {
    box-shadow: 0 2px 6px -1px rgb(0 0 0 / 18%), 0 6px 18px -1px rgb(0 0 0 / 6%) !important;
    color: #fff;
}
@media only screen and (max-width: 767.5px) {

    .button_modal_login {
        margin-top: 14px;
        text-align: center;
        border: 0px;
        color: #fff;
        width: 78px;
        height: 33px;
        border-radius: 6px;
    }
    .text_modal_login {
        margin-top: 20px;
        margin-right: -12px;
    }
    .costum_modal_login {
        width: 95%;
        max-width: 528px;
        height: fit-content;
        padding: 10px 30px 20px 10px;
    }
}