.map-div {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: -webkit-center;
}
.map-div > div {
  width: 100%;
}
.app-div {
  text-align: right;
}

.map-leaflet {
  width: 100%;
  min-height: 250px;
  /* height:calc(100vh - 203.4px) !important; */

  /* margin: 0 auto 100px; */
}
.map-leaflet-skeleton {
  height: calc(100vh - 203.4px) !important;
}
.fixed-map {
  position: sticky !important;
  top: 203.4px;
}
@media only screen and (max-width: 767.5px) {
  .map-leaflet {
    width: 100%;
    min-height: 200px;
    /* height:calc(100vh - 145.5px) !important; */
  }
  .map-leaflet-skeleton {
    height: calc(100vh - 145.5px) !important;
  }
}

.col12-search-header {
  /* height: calc(100vh - 215.4px); */
  /* overflow: auto; */
  min-height: 250px;
  direction: ltr;
}
/* from leaflet css */
.leaflet-popup-content {
  margin: 0px;
  width: 100%;
  font-family: IRANSansWeb !important;
}
.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-tip {
  display: none;
}
.leaflet-popup-content-wrapper {
  border-radius: 6px;
  padding: 0px;
}
.leaflet-popup-content p {
  margin: 0px;
}
.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  margin-top: -37px;
}
