.btn_choose_job {
  /* width: 122px; */
  width: 100%;
  flex-basis: content;
  padding: 0 12px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #cbcbcb !important;
  border-radius: 6px;
  box-shadow: none;
  color: #005589 !important;
  font-size: 16px;
  justify-content: center;
  margin-top: 12px;
  margin-left: 8px;
}
.btn_choose_job:hover,
.btn_choose_job_active:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 7%), 0 6px 18px -1px rgb(0 0 0 / 4%) !important;
}

.btn_choose_job_active {
  width: 100%;
  flex-basis: content;
  padding: 0 12px;
  height: 47px;
  margin-top: 12px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  background-color: #c9b2f8 !important;
  border: 1px solid #c9b2f8 !important;
  border-radius: 6px;
  box-shadow: none;
  color: #005589!important;
}
button:disabled,
button[disabled] {
  opacity: 0.3;
  /* background-color: #cccccc !important; */
  color: #666666;
}


.rb-step2-tit2 {
  margin: 4px 0px 10px 0px;
  font-weight: normal;
  color: #1d1d1d;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 45px;
  text-align: center;
}

.btn-group-div-bus > button .digar-img {
  height: 13px;
  background-image: url("../../images/green-add.png");
  margin-right: 3px;
  background-repeat: no-repeat;

  width: 12px !important;
  height: 2.8رw !important;
  background-size: 12px;
  margin-right: 5px;
}
.btn-group-div-bus > .btn_choose_job_active > .digar-img {
  background-image: url("../../images/black-add.png");
}
.btn-group-div-bus {
  margin: 0;
  padding-right: 8px;
  width: 528px;
}
@media only screen and (max-width: 767.5px) {
  .btn_choose_job {
    width: 70px;
    height: 30px;
    font-size: 13px;
    margin-top: 8px;
    margin-left: 8px;
  }
  .btn_choose_job_active {
    width: 70px;
    height: 30px;
    font-size: 13px;
    margin-top: 8px;
    margin-left: 8px;
  }
  .btn-group-div-bus {
    width: 320px;
    margin: 0 -7px;
  }
  .rb-step2-tit2 {
    margin: 40px 0px 16px 0px;
    font-size: 17px;
  }
}
