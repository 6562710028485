#pagination1 > div {
  align-items: center;
}
.css-19micn4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-1kz0k3h.Mui-selected {
  background-color: #33e0a0 !important;
  color: #005589 !important;
}
.paginatio_set {
  margin-top: 20px;
  margin-bottom: 30px;
}
.MuiPagination-ul li button {
  color: #67808f;
}
@media only screen and (max-width: 767.5px) {
  .paginatio_set {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.MuiPagination-ul li button:disabled {
  background-color: unset !important;
}
