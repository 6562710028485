/* .result-modal-launch-btn {
  padding: 0;
  box-shadow: none;
  background-color: unset;
  color: #005589;
  font-size: 14px;
}
.result-modal-launch-btn:hover,
.result-modal-launch-btn:focus,
.result-modal-launch-btn:active,
.result-modal-launch-btn:focus.result-modal-launch-btn:active {
  box-shadow: none;
  background-color: unset;
  color: #005589;
} */
.result-modal-launch-btn > img {
  width: 17px;
  height: 17px;
  /* margin-left: 9px; */
}
/* .result-modal-close-btn {
  width: 102px;
  height: 34px;
  margin: 20px 0px 12px 28px;
  padding: 0;
  border-radius: 6px;
  background-color: #33e0a0;
  box-shadow: none;
  font-size: 14px;
  color: #005589;
} */
.result-modal-close-btn:hover,
.result-modal-close-btn:focus,
.result-modal-close-btn:active,
.result-modal-close-btn:focus.result-modal-close-btn:active {
  background-color: #33e0a0;
  color: #005589;
}
.result-modal-header {
  direction: ltr;
  padding: 12px 25px 8px 25px;
  justify-content: right;
  border: none;
}
.result-modal-header > h5 {
  font-size: 16px;
  font-weight: bold;
  color: #005589;
  margin-right: 18px;
}
.result-modal-header > button {
  padding: 0px !important;
  width: 12px;
  height: 12px;
  opacity: 1;
}
.result-modal-footer {
  border: none;
  padding-top: 0px;
}
 
.city-auto .MuiAutocomplete-popper {
  height: 80% !important;
}
.city-auto .MuiAutocomplete-paper {
  margin-top: 1px;
  height: 100% !important;
}