.main-border {
  padding: 22px 25px 12px 33px;
  border: solid 1px #c9c9c9;
  border-radius: 6px;
}
progress {
  /* background: #33E0A0; */
  border-radius: 3px;
  color: #ebebeb;
  width: 100%;
  height: 6px;
  margin-bottom: 10px;
}
progress::-moz-progress-bar {
  background: #33e0a0;
  border-radius: 3px;
}
progress::-webkit-progress-value {
  background: #33e0a0;
  border-radius: 3px;
}
progress::-webkit-progress-bar {
  background: #ebebeb;
  border-radius: 3px;
}
.text1-comment {
  float: right !important;
  margin-right: 8px;
  color: #67808f;
  font-size: 10px;
}
.mb--5 {
  margin-bottom: -12px;
}
.comment-prog-numb {
  font-size: 10px;
  color: #005589;
}
.comment-prog-text {
  font-size: 12px;
  color: #005589;
}
.ml--5 {
  margin-left: 5px;
}
.btn-nazar-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #b294f2;
  border: 1px solid #b294f2;
  border-radius: 6px;
  width: 100%;
  height: 34px;
  background-color: white;
}
.btn-nazar-comment:hover,
.btn-nazar-comment:active,
.btn-nazar-comment:focus {
  color: #b294f2;
  border: 1px solid #b294f2;
  background-color: white;
}
.f-10px {
  font-size: 10px;
}
.f-12px {
  font-size: 12px;
}
.f-22px {
  font-size: 22px;
}
.text-main-nazar {
  color: #005589;
  font-weight: bold;
  font-size: 17px;
  letter-spacing: -0.34px;
  margin: 13px 0;
}
.image-card-comments {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}
.name-comments {
  vertical-align: top;
  font-size: 12px;
  /* margin-right: 6px; */
}
.icon-comments {
  position: relative;
  margin-right: 6px;
  /* top: 9px;
  margin-right: -25px; */
}
.text-comments-label {
  color: #67808f !important;
  font-size: 11px;
  margin: 12px 0 0px;
}
.comments {
  font-size: 14px;
  line-height: 1.07;
  text-align: justify;
  color: #005589;
  margin-bottom: 20px;
}
.text-align-last-left {
  text-align-last: left;
  text-align: left !important;
}
.ml20 {
  margin-left: 20px;
}
.ml5 {
  margin-left: 5px;
}
.custom-hr {
  /* width: 438px; */
  height: 1px;
  margin: 2px 0px 0px 2px;
  background-color: #c9c9c9;
}
.stle-goza {
  font-size: 11px;
  text-align: right;
  color: #005589;
}
.arrow-comments {
  margin-right: 4px;
}
.a-arrow-comments {
  color: #33e0a0;
  font-size: 12px;
  background-color: white;
  box-shadow: none;
  padding: 0px;
}
.a-arrow-comments:hover,
.a-arrow-comments:active,
.a-arrow-comments:focus {
  color: #33e0a0;
  background-color: white;
  box-shadow: none !important;
  color: #00000069;
}

.padding-col4-comment {
  padding: 0 0px 0 0px;
}
@media only screen and (min-width: 991.5px) {
  .padding-col4-comment {
    padding: 0 0px 0 33px;
  }
}

.card-components-col {
  margin-top: 10px;
}

.sabt-nazar-comment {
  font-size: 10px;
  margin: 8px 0 6px 0;
  color: #005589;
}
.business-comment-img1 {
  width: 15px;
  height: 15px;
  margin-left: 22px;
}
.business-comment-img2 {
  width: 20px;
  height: 20px;
}
.business-comment-img2:hover {
  cursor: pointer;
}
.business-comment-more-img {
  width: 15px;
  height: 15px;
}
@media only screen and (max-width: 766.5px) {
  /*  */
  .main-border {
    padding: 0px 8px 12px 8px;
    border: none;
  }
  /*  */
  progress {
    border-radius: 3px;
    height: 6px;
    margin-bottom: 10px;
  }
  /*  */
  progress::-moz-progress-bar {
    border-radius: 3px;
  }
  /*  */
  progress::-webkit-progress-value {
    border-radius: 3px;
  }
  /*  */
  progress::-webkit-progress-bar {
    border-radius: 3px;
  }
  /*  */
  .text1-comment {
    margin-right: 8px;
    font-size: 10px;
  }
  /*  */
  .mb--5 {
    margin-bottom: -12px;
  }
  .ml--5 {
    margin-left: 5px;
  }
  .btn-nazar-comment {
    width: 185px;
    background-color: white;
  }
  .text-main-nazar {
    padding-right: 3.2%;
    font-size: 17px;
    letter-spacing: 0px;
    margin: 13px 0;
  }
  .icon-comments {
    position: relative;
  }
  .text-comments-label {
    /* margin: -3px 0 12px; */
  }
  .comments {
    line-height: 1.16;
  }

  .ml20 {
    margin-left: 20px;
  }
  .ml5 {
    margin-left: 5px;
  }
  .custom-hr {
    /* width: 438px; */
    height: 1px;
    margin: 2px 0px 0px 0px;
    background-color: #c9c9c9;
  }
  .arrow-comments {
    margin-right: 4px;
  }

  /*  */
  .padding-col4-comment {
    padding: 0 16px;
  }
  /*  */
  .card-components-col {
    margin-top: 22px;

    padding: 0;
  }
  .padding-row-cardcomment {
    padding: 0 4px;
    margin: 0px;
  }
  /*  */
  .sabt-nazar-comment {
    margin: 10px 0 10px 0;
  }
  /*  */
  .business-comment-img1 {
    margin-left: 15px;
  }
  .comments > div > a {
    font-size: 13px;
  }
}
