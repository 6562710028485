.gish-page-back {
  background-color: #f7f7f7;
  padding: 45px 50px;
}

/* about */
.gish-about-tit {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #020202;
  margin: 0 auto 40px;
  width: fit-content;
}
.gish-about-body {
  font-size: 18px;
  text-align: justify !important;
  color: #020202;
  width: 82%;
  line-height: 2;
  margin: 20px auto 20px;
}
.gish-about-end {
  font-size: 20px;
  text-align: center;
  color: #33e0a0;
  margin-top: 15px;
  margin-bottom: 50px;
}

/* privacy */
.gish-pri-tit {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #020202;
  margin: 0 auto 40px;
  width: fit-content;
}
.gish-pri-body {
  font-size: 18px;
  /* text-align: right;
   */
  text-align: justify;
  color: #020202;
  width: 82%;
  line-height: 2;
  margin: 0 auto;
  margin-bottom: 50px;
}
/* rules */
.gish-rul-tit {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #020202;
  margin: 0 auto 40px;
  width: fit-content;
}
/* contact */
.gish-con-tit {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #020202;
  margin: 0 auto 40px;
  width: fit-content;
}
.gish-con-body {
  /* font-size: 18px;
  color: #020202; */
  text-align: center;
  width: 82%;
  /* line-height: 2; */
  margin: 0 auto;
  margin-bottom: 40px;
}
.con-right-0 {
  right: 0px;
}
.material-con,
.material-con .input_custom {
  width: 536px;
}
.material-con-area,
.material-con-area .input_custom {
  width: 536px;
  height: 156px;
  resize: none;
  margin-top: 40px;
}
.material-con-area .input_custom {
  resize: none;
  padding: 10px 14px;
}
.input-error-con {
  width: 536px !important;
  margin: auto !important;
}

.con-btn {
  width: 113px;
  height: 41px;
  border: none;
  border-radius: 6px;
  font-size: 17px;
  height: 34.8px;
  padding: 0;
  box-shadow: none;
  background-color: #33e0a0;
  color: #005589;
  margin: 36px auto 0px;
}
.con-btn:hover,
.con-btn:active,
.con-btn:focus {
  color: #005589;
  background-color: #33e0a0;
}
.con-btn:disabled {
  background-color: #cccccc;
  box-shadow: 0 4px 9px -4px #fff;
}
.borda-error {
  border: 1px solid #f82020 !important;
}
@media only screen and (max-width: 767.5px) {
  .gish-page-back {
    padding: 25px 24px;
  }
  .gish-about-tit {
    font-size: 20px;
    margin: 0 auto 20px;
    width: fit-content;
  }
  .gish-about-body {
    /* font-size: 15px; */
    width: 100%;
    line-height: unset;
    margin: 0 auto;
  }
  .gish-about-end {
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  /* privacy */
  .gish-pri-tit {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  .gish-pri-body {
    font-size: 15px;
    width: 100%;
    margin-bottom: 20px;
  }
  /* rules */
  .gish-rul-tit {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  /* contact */
  .gish-con-tit {
    font-size: 20px;
    margin: 0 auto 20px;
  }
  .gish-con-body {
    font-size: 15px;
    width: 100%;
    margin-bottom: 20px;
  }
  .material-con,
  .material-con .input_custom {
    width: 100%;
  }
  .input-error-con {
    width: 100% !important;
  }
  .material-con-area,
  .material-con-area .input_custom {
    width: 100%;
    margin-top: 25px;
  }
  .con-btn {
    font-size: 14px;
    margin: 24px auto 0px;
  }
}

/*  */

/* input */
/* .muirtl-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline */
.custom-text-field > div.Mui-focused > fieldset {
  border-color: #33e0a0 !important;
}
.custom-text-field-error > div.Mui-focused > fieldset {
  /* border-color: #f82020 !important; */
}

.muirtl-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  /* color: #c9c9c9 !important; */
  /* top: 0px; */
}
.custom-text-field fieldset {
  border-radius: 6px;
  border-color: #c9c9c9 !important;
}
@media only screen and (max-width: 767.5px) {
  .dash-text-field fieldset {
    border-color: #f7f7f7 !important;
  }
}
.custom-text-field-error fieldset {
  border-color: #f82020 !important;
}
.custom-text-field input {
  padding: 10.5px 14px;
}

.custom-text-field label {
  /* top: -6px; */
  color: #c9c9c9 !important;
}
.muirtl-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -5px !important;
}
.custom-text-field label.Mui-focused {
  /* top: 0px; */
  color: #c9c9c9 !important;
}
.label-field-more .muirtl-1ftyaf0 {
  /* overflow: unset !important; */
}
.label-field-more .muirtl-1in441m {
  /* padding: 0 6px 0 10px !important; */
}
.label-field-less .muirtl-1in441m {
  /* padding: 0 6px 0 0px !important; */
}
.label-field-more > div > fieldset > legend > span {
  padding: 0px 5px 0 11px !important;
}
/* contact */
.custom-text-field-contact {
  width: 536px !important;
  background: #fff;
}
@media only screen and (max-width: 767.5px) {
  .custom-text-field-contact {
    width: 100% !important;
  }
  .custom-text-field-error {
  }
}
