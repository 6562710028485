.body-comment-business {
  max-width: 843px;
  width: 100%;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 20px;
  box-shadow: none;
  border: solid 1px #c9c9c9;
  border-radius: 6px;
  box-sizing: 6px;
}
.body-comment-business:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 6%), 0 6px 18px -1px rgb(0 0 0 / 2%) !important;
}
.h_auto_business {
  height: auto;
}
.img-comment-business {
  width: 50px;
  height: 50px !important;
  margin: 10px 10px 5px 0px !important;
  border-radius: 6px;
  background-color: #eaeaea;
  object-fit: cover;
}

.show-1line {
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
}

/* modal comment business */
.body-comment-business-dialog {
  max-width: 843px;
  width: 100%;
  /* min-height: -moz-fit-content;
    min-height: fit-content; */
  height: fit-content;
  margin: auto;
  /* margin-top: 20px; */
  box-shadow: none;
  /* border: solid 1px #c9c9c9; */
  border-radius: 6px;
  box-sizing: 6px;
}
.costum_modal-comment-business {
  max-width: 843px;
  width: 100%;
  border: solid 1px #c9c9c9;

  min-height: fit-content;
  height: fit-content;
  margin: auto;

  border-radius: 6px;
  box-sizing: 6px;

  padding: 10px 10px 0px 10px;
  box-shadow: 0 3px 6px 0 rgb(176 176 176 / 16%);
  background-color: #fff;
  /* z-index: 16000; */
}

.side-modal {
  width: 224px;
  /* height: 100vh; */
  display: block;
}

@media only screen and (min-width: 1328.5px) {
  .side-modal {
    width: 224px;
    /* height: 100vh; */
    display: block;
  }
}
@media only screen and (max-width: 1328.5px) {
  .side-modal {
    width: 213px;
    /* height: 100vh; */
    display: block;
  }
  .costum_modal-comment-business {
    margin: auto 46px;
  }
}

@media only screen and (max-width: 1244.5px) {
}
.body-comment-business-dialog {
  max-width: 903px;
  margin: auto;
}

.whole-modal {
  min-height: calc(100% - 3.5rem);
}

@media only screen and (max-width: 767.5px) {
  .dash-bus-comment {
    width: 100%;
    height: fit-content;
    margin: 16px 0px 16px 0px;
  }
  .dash-bus-comment-tit {
    padding: 18px 23px 10px 0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.35px;
    text-align: right;
    color: #005589;
    border-bottom: 1px solid #eaeaea;
  }
  .dash-bus-comment-body {
    padding: 23px 22px 37px 22px;
    /* display: grid; */
  }
  .dash-bus-comment-body > span {
    width: 164px;
    padding-right: 2px;
  }
  .dash-bus-comment-img {
    width: 41px;
    height: 41px;
    margin: auto;
    margin-bottom: 7px;
  }
  .img-comment-business {
    margin: 10px auto !important;
  }
}
