.res-card {
  box-shadow: none;
  border-radius: 6px;
  border: solid 1px rgba(112, 112, 112, 0.12);
  min-height: 196px;
  /* min-height: 230px; */
  /* height: fit-content; */
  text-align: right;
  padding: 14px;
}
.res-card > div {
  height: 100%;
}
.res-card > div > div > span {
  height: 164px;
  width: 100%;
}
.res-card-img {
  object-fit: cover;
  width: 164px;
  height: 164px;
  /* min-height: 230px; */
  /* height: 100%; */
  border-radius: 6px;
  border: solid 1px rgba(112, 112, 112, 0.12);
}
.res-card .card-body {
  padding: 0px 12px 0 0;
  color: #005589;
  height: 100%;
  position: relative;
  padding-bottom: 20px;
}
.res-card-tit {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 2px 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}
.res-card-tit-img {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-right: 6px;
}
.res-card-text1 {
  margin: 4px 0px 4px 0px;
  font-size: 15px;
  font-stretch: expanded;
  letter-spacing: -0.27px;
  text-align: right;
}
.res-card-text2 {
  opacity: 0.53;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.31;
  letter-spacing: -0.23px;
  text-align: right;
  margin-bottom: 0px;
  color: #02314e;
}
.res-card-text3 {
  max-width: 412px;
  margin: 4px 0 0 0;
  /* padding: 17px 374px 0 29px; */
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.23px;
  text-align: right;
  display: flex;
  align-items: end;
  color: #02314e;
  position: absolute;
  bottom: 0px;
}
.res-card-text3 span {
  color: #33e0a0;
}
.res-card-box2 {
  float: right;
}
.res-card-box {
  display: flex;
  justify-content: right;
  /* margin: 10px 0 0 0; */
}
.res-card-box p {
  width: fit-content;
  height: 17px;
  padding: 0 7px;
  margin: 0px 0px 8px 6px;
  border-radius: 6px;
  background-color: #eaeaea;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.55;
  letter-spacing: -0.2px;
  text-align: right;
  color: #005589;
}
@media only screen and (max-width: 767.5px) {
  .res-card {
    max-width: 334px;
    height: fit-content;
  }
  .res-card-a {
    display: block;
    width: fit-content;
  }
  .res-card-img {
    max-height: 220px;
    min-height: 215px;
    height: fit-content;
    border-left: 0px;
    border-bottom: solid 1px rgba(112, 112, 112, 0.12);
  }
  .res-card .card-body {
    padding: 12px 14px 18px;
  }
  .res-card-box p {
    margin: 8px 0px 4px 6px;
  }
  .res-card-tit-img {
    width: 17px;
    height: 17px;
    /* margin-top: 12px; */
  }

  .res-card-tit {
    font-size: 15px;
  }
  .res-card-text1 {
    font-size: 12px;
    margin: -7px 0px 2px 0px;
  }
  .res-card-text2 {
    font-size: 10px;
  }
  .result-res-card {
    height: 100% !important;
  }
  .result-res-card .img-r-card {
    border-bottom-right-radius: 0px !important;
    border-bottom: solid 0.5px rgba(139, 125, 125, 0.12);
  }
}
