.registery-bg {
  /* height: 900px; */
  background-color: #ede6fc;
  padding-top: 200px;
  /* text-align: center; */
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.registery-cir-bg {
  /* shape-outside: ellipse(100px 200px at 50% 50%); */
  clip-path: ellipse(52% 300px at 50% 100%);
  width: 100%;
  height: 300px;
  background-color: #02314e;
  text-align: center;
}
.registery-rec-bg {
  height: 115px;
  background-color: #02314e;
}
.registery-welcome-text {
  font-size: 34px;
  color: #fff;
  padding-top: 175px;
}
.registery-welcome-img {
  position: absolute;
  z-index: 1;
  top: 50px;
}
@media only screen and (max-width: 767.5px) {
  .registery-bg {
    padding-top: 170px;
  }
  .registery-cir-bg {
    clip-path: ellipse(52% 150px at 50% 100%);
    width: 100%;
    height: 150px;
  }
  .registery-rec-bg {
    height: 340px;
    background-color: #02314e;
  }
  .registery-welcome-text {
    font-size: 25px;
    padding-top: 170px;
  }
  .registery-welcome-img {
    width: 150px;
  }
}
/* email registery page */
.registery-welcome-img2 {
  position: absolute;
  z-index: 1;
  top: 50px;
  margin-right: 30px;
}
.registery-welcome-text2 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 125px;
  width: 100%;
}
.registry-resend-email {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #33e0a0;
  width: fit-content;
  cursor: pointer;
}
.registry-resend-email:hover {
  color: #fff;
}
.registry-resend-email:active,
.registry-resend-email:focus {
  font-size: 19px;
  text-shadow: 2px 2px 5px #100701;
}

.registery-welcome-btn1 {
  width: 145px;
  height: 52px;
  margin: -20px 0 0 20px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  color: #005589;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.registery-welcome-btn2 {
  width: 145px;
  height: 52px;
  margin: -20px 20px 0 0;
  border-radius: 10px;
  background-color: #fff;
  font-size: 18px;
  font-weight: bold;
  color: #33e0a0;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.registery-welcome-btn1:hover,
.registery-welcome-btn2:hover,
.registery-welcome-btn1:active,
.registery-welcome-btn1:focus,
.registery-welcome-btn2:active,
.registery-welcome-btn2:focus {
  background-color: #e5f5ffbf;
  color: #fff;
}
@media only screen and (max-width: 767.5px) {
  .registery-welcome-text2 {
    font-size: 16px;
    padding-top: 30px;
    position: relative;
    top: 0px;
  }
  .registery-welcome-img2 {
    width: 150px;
    margin-right: 40px;
  }
  .registry-resend-email {
    font-size: 14px;
    margin-top: 78px;
  }
  .registry-resend-email:active,
  .registry-resend-email:focus {
    font-size: 15px;
    text-shadow: 2px 2px 5px #100701;
  }

  .registery-welcome-btn1,
  .registery-welcome-btn2 {
    margin: 0px auto 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .registery-welcome-btn1{
    margin-top: 70px;

  }
}
