.img_mobile_f {
  width: 25px;
  height: 25px;
}
.img_mobile_fa {
  width: 33px;
  height: 33px;
}
.ul_profile_mobile {
  list-style: none;
  width: 100%;
  max-width: 375px;
  display: flex;
  padding: 0 20px;
}
.ul_profile_mobile a {
  width: 16.6666%;
}
.header_profule_mobile {
  text-align-last: center;
  height: 103px;
  box-shadow: 0 0 3px 0 #c9c9c9;
  background-color: #fff;
}
.logo_header_mobile {
  /* width: 65px; */
  height: 21px;
  margin: 8px 0 25px 0;
}

/* new */
.button_header_mobile {
  max-width: 46px;
  width: 14%;
  height: 46px;
  background-color: #33e0a0;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 0;
  margin-top: -40px;
}
.new_header {
  position: relative;
  z-index: 10;
}
.ul_new {
  background-color: #f7f7f7;
  list-style: none;
  width: 100%;
  max-width: 375px;
  display: flex;
  margin-bottom: 0px;
  padding: 0 20px 14px 20px;
}
.ul_new li {
  width: 16.6666%;
}
.ul_new_back {
  background-color: #f7f7f7;
}
