.popup-card {
  /* max-width: 290px; */
  width: 290px;
  height: 116px;
  padding: 12px 12px 12px 12px;
  border-radius: 6px;
  border: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}
.popup-card-img {
  object-fit: cover;
  /* width: 92px; */
  height: 92px;
  border-radius: 6px;
  border: solid 1px rgba(112, 112, 112, 0.12);
}
.popup-card-tit {
  font-size: 12px;
  text-align: right;
  font-weight: bold;
  color: #005589;
}
.popup-card-p {
  font-size: 10px;
  font-weight: 300;
  text-align: right;
  color: #67808f;
}

/* .popup-fixed {
  position: fixed;
  top: auto;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  margin: 0;
  border-radius: 0;
} */
/* .leaflet-popup-tip-container {
  display: none;
} */

/* .leaflet-popup {
  transform: none !important;
  position: absolute !important;
  top: 1px !important;
  bottom: 0px !important;
  right: 0% !important;
  display: flex !important;
  align-items: right !important;
  justify-items: right !important;
}

.leaflet-container .leaflet-popup-content-wrapper .popup_modle {
  z-index: 1000;
  position: absolute;
  right: 0 !important;
  height: 400px;
  width: 300px;
  top: -1px;
  background-color: rgba(5, 5, 5, 0.7) !important;
  background: linear-gradient(
    60deg,
    rgba(134, 134, 134, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 59%
  ) !important;
  align-items: right !important;
  justify-items: right !important;
  justify-content: right !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
} */

.marker1-popup {
  /* transform: none !important;
  position: absolute !important;
  top: 1px !important;
  bottom: 0px !important;
  right: 0% !important;
  display: flex !important;
  align-items: right !important;
  justify-items: right !important; */
  z-index: 1000000000;
  position: absolute;
  bottom: 28px;
  right: calc(50% - 145px);
}

@media only screen and (max-width: 766.5px) {
  .marker1-popup {
    z-index: 1000000000;
    position: absolute;
    bottom: 48px;
    right: calc(50% - 140px);
  }
  .popup-card {
    max-width: 290px;
    width: 100%;
  }
}