.display-modal-none {
  display: none;
}
.fontSize-modal-data {
  font-size: 30px;
}

/*  */
.eb-modal-dialog {
  max-width: 690px;
}
.eb-modal-content {
  border-radius: 6px;
}
.eb-modal-header {
  padding: 0 24px;
  background-color: #33e0a0;
  height: 86px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.header-modal-data {
  position: relative;
  left: 35%;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.eb-modal-body {
  padding: 35px 16px 0px 16px;
  height: 569px;
  overflow-y: auto;
}
.eb-modal-body2 {
  padding: 49px 49px 103px 49px;
}

.btn-modal-data {
  display: block;
  width: 312px;
  height: 55px;
  margin-top: 0px;
  margin-bottom: 20px;
  box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  color: #005589;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #33e0a0;
}
.btn-modal-data:hover,
.btn-modal-data:active,
.btn-modal-data:focus {
  color: #fff;
  background-color: #33e0a0;
  border: solid 1px #33e0a0;
}

/* other pages */
.eb-modal-icon {
  width: 23px;
  height: 23px;
  margin-right: 1px;
}
.eb-modal-tit1 {
  margin: 0px 10px 0px 0px;
  font-size: 18px;
  color: #005589;
}
.eb-modal-input {
  /* direction: ltr; */
  font-family: "IRANSansWeb";
  width: 100%;
  height: 55px;
  margin: 10px 0 0 0 !important;
  padding: 0 15px;
  border-radius: 6px;
  border: solid 1px rgba(150, 148, 148, 0.6) !important;
  display: block;
  /* font-size: 19px; */
  /* letter-spacing: -0.29px; */
  /* color: #005589; */
  /* font-family: Roboto; */
}
.eb-modal-input:placeholder {
  text-align: right;
}
.eb-modal-input > div {
  width: inherit;
  padding: 0 0 0 14px;

  height: inherit;
}
.eb-modal-textarea:focus,
.eb-modal-input:focus,
.eb-modal-input:active {
  border: solid 1px #33e0a0 !important;
}
.eb-modal-input:focus-visible,
.eb-modal-input:focus-within {
  outline: none;
  border: solid 1px #33e0a0 !important;
}
.eb-modal-input-delete {
  position: absolute !important;
  right: 9px !important;
  top: 22px !important;
}
.eb-modal-textarea {
  /* direction: rtl; */
  resize: none;
  width: 100%;
  min-height: 152px;
  height: fit-content;
  /* max-height: 290px; */
  margin: 10px 0 0 0;
  padding: 16px 27px;
  border-radius: 6px;
  border: solid 1px rgba(150, 148, 148, 0.6);
  display: block;
  font-size: 18px;
  /* letter-spacing: -0.29px; */
  color: #005589;
}
.eb-modal-textarea:focus-visible {
  outline: none;
}
.eb-modal-add1 {
  width: 100%;
  margin-top: 10px;
}
.eb-modal-add {
  display: block;
  width: 17px;
  height: 17px;
  border: none;
  float: right;
  border-radius: 6px;
  margin-right: 0px;
  padding: 0px;
}
.eb-modal-add-img {
  width: 17px;
  height: 17px;
  display: block;
}

.eb-modal-mapbg {
  width: 100%;
  height: 129px;
  margin: 63px 0px 6px 0px;
  opacity: 0.49;
  border-radius: 8px;
  border: solid 1px #707070;
}
.eb-modal-mapbtn {
  width: 39%;
  height: 57px;
  margin: 17px 0px 0 0;
  padding: 0;
  border-radius: 6px;
  border: solid 1px #b294f2;
  background-color: #fff;
  font-size: 17px;
  color: #005589;

  position: absolute;
  right: 30%;
  bottom: 40px;
}

/* times */
.eb-modal-right {
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align: -ms-right;
  /* margin-top: 25px; */
  margin-right: 12px;
}
.eb-modal-body2::-webkit-scrollbar {
  float: left;
}
.bg-modal {
  background-color: rgb(86, 86, 86, 0.15);
}

.eb-modal-bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin: 4px -49px 0 -49px;
}
.eb-modal-bottom > hr {
  background-color: #c9c9c9;
  height: 2px;
}
.eb-modal-button {
  width: 163px;
  height: 45px;
  /* margin: 6px 105px 0 266px; */
  /* padding: 4px 24px 3px 20px; */
  border-radius: 6px;
  border: solid 1px #f7f7f7;
  background-color: #eaeaea;

  font-size: 18px;
  letter-spacing: -0.2px;
  color: #67808f;
}
.eb-modal-button-active {
  background-color: #c9b2f8 !important;
  color: #fff !important;
}

.eb-modal-bottom2 {
  position: initial;
  /* bottom: 20px;
  width: 100%; */
  padding-top: 0.25px;
  margin: 0px -49px -95px -49px;
}
.eb-modal-bottom2 > hr {
  background-color: #c9c9c9;
  height: 2px;
}
.eb-modal-button2 {
  width: 163px;
  height: 45px;
  /* margin: 6px 105px 0 266px; */
  /* padding: 4px 24px 3px 20px; */
  border-radius: 6px;
  border: solid 1px #f7f7f7;
  background-color: #eaeaea;
  font-size: 20px;
  letter-spacing: -0.2px;
  color: #67808f;
}
.eb-modal-backimg {
  /* width: 23px;
  height: 22.4px;; */
  width: 23px;
  height: 19.5px;
  cursor: pointer;
}
.eb-modal-closeimg {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.eb-modal-lan {
  font-size: 15px;
  font-weight: 300;
  text-align: right;
  color: #005589;
  margin-bottom: -10px;
  margin-top: 10px;
}

/* .d-unset{
  display: unset;
} */

.auto10.auto10-new > div > div > div > div > .MuiAutocomplete-endAdornment {
  padding-right: 0px !important;
  padding-left: 10px;
}

.auto10.auto10-new > div > div > div > .MuiOutlinedInput-root,
.auto10.auto10-new > div > div > div > .MuiAutocomplete-inputRoot {
  height: 55px;
  padding: 0 15px 0 15px !important;
}
.MuiAutocomplete-popupIndicatorOpen .icon-ib-new10 {
  rotate: 0deg !important;
}
.auto10.auto10-new .icon-ib {
  rotate: 180deg;
}

.auto10.auto10-new > div > div > div > div > .MuiOutlinedInput-notchedOutline,
.auto10.auto10-new
  > div
  > div
  > div
  > div
  > .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}
/*  */
.eb-modal-body-map {
  padding: 35px 20px;
}

.eb-modal-map-text {
  font-size: 14px;
  text-align: right;
  color: #005589;
  margin: 22px 0 6px;
}

.eb-modal-map-text img {
  width: 26px;
  height: 26px;
  margin-left: 8px;
}

.eb-modal-map-btn {
  color: #b294f2;
  cursor: pointer;
}
.eb-modal-body-map-close {
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 1000;
  top: 45px;
  left: 35px;
  cursor: pointer;
}
.eb-modal-body-map-close img {
  width: 12px;
  height: 12px;
}

.eb-modal-body-scroll {
  padding: 0px 0px 0px 0px;
}
.eb-modal-body-scroll-div {
  height: calc(690px - 199px);
  /* overflow: auto; */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 49px;
}
.eb-modal-bottom-scroll {
  position: relative;
  bottom: 18px;
}
.eb-modal-bottom-scroll > hr {
  background-color: #c9c9c9;
  height: 2px;
}
/*  */

/* The container */
.container-s5 {
  /* display: block; */
  width: fit-content;
  position: relative;
  /* padding-right: 16px;
  margin-bottom: 10px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 30px;
}
.container-s5.disabled {
  opacity: 0.5;
}
/* Hide the browser's default checkbox */
.container-s5 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.s5-24h {
  /* position: absolute;
  top: 0;
  right: 0; */
  height: 14px;
  width: 14px;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #005589;
  margin-right: 6px;
}
/* When the checkbox is checked, add a blue background */
.container-s5 input:checked ~ .s5-24h {
  background-color: #33e0a0;
  border: solid 0px #33e0a0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.s5-24h:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-s5 input:checked ~ .s5-24h:after {
  display: block;
}

/*  */
.rb-s5-group2 {
  width: 530px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.rb-s5-group2:hover,
.rb-s5-group2:focus,
.rb-s5-group2:active {
  box-shadow: none;
}

.rb-s5-group2-btn-off {
  width: 181px;
  min-width: 181px;
  height: 41.5px;
  box-shadow: none;
  border-radius: 20.7px;
  border: solid 1px #c9c9c9;
  background-color: #fff !important;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
  padding: 0px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rb-s5-group2-btn-on {
  width: 181px;
  min-width: 181px;
  height: 41.5px;
  box-shadow: none;
  border-radius: 20.7px;
  border: solid 0px #cbcbcb;
  background-color: #005589;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 0px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rb-s5-group2-btn-on:hover,
.rb-s5-group2-btn-off:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 4%), 0 6px 13px -1px rgb(0 0 0 / 2%) !important;
}

.rb-s5-group-btn-off {
  width: 104px;
  min-width: 104px;
  height: 40.5px;
  box-shadow: none;
  border-radius: 20.7px;
  border: solid 1px #c9c9c9;
  background-color: #fff !important;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
  padding: 0px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rb-s5-group-btn-on {
  width: 104px;
  min-width: 104px;
  height: 40.5px;
  box-shadow: none;
  border-radius: 20.7px;
  border: solid 0px #cbcbcb;
  background-color: #005589;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 0px;
  margin-left: 18px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rb-s5-group-btn-on:hover,
.rb-s5-group-btn-off:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 4%), 0 6px 13px -1px rgb(0 0 0 / 2%) !important;
}
.rb-s5-group {
  width: 530px;
  box-shadow: none;
  display: flex;
  justify-content: center;
}

.rb-s5-group:hover,
.rb-s5-group:focus,
.rb-s5-group:active {
  box-shadow: none;
}
