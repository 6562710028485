.map-disc {
  margin: 0px 0 8px 0;
  font-size: 18px;
  text-align: right;
  color: #005589;
}
.map-div-modal {
  height: 100%;
  position: relative;
  border-radius: 6px;
  border: solid 1px #707070;
}
.map-leaflet-step4 {
  width: 100%;
  height:100%;
  min-height: 100px;
  margin-top: -6px;
  /* margin-bottom: 400px; */
  margin-right: -12px;
  margin-left: -12px;
}
.map-leaflet-dash {
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin: 0 -42px;
  border-radius: 6px;

}
.map-save-btn {
  width: 81px;
  height: 38px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0px auto 0 -42px;
  left: 50%;
  padding: 0px;
  border-radius: 6px;
  border: solid 1px #fefefe;
  background-color: #fefefe;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #005589;
  position: absolute;
  bottom: 22px;
  z-index: 1000;
}
.map-save-btn:hover,
.map-save-btn:active,
.map-save-btn:focus {
  background-color: #fefefe;
  border: solid 1px #fefefe;
  color: #005589;

}

@media only screen and (max-width: 767.5px) {
  .map-disc {
    margin: 24px 0 24px 0;
    font-size: 15px;
    text-align: center;
    color: #005589;
  }
  .map-leaflet-step4 {
    width: 100%;
    height: 100%;
    min-height: 100px;
    margin-top: 0px;
    /* margin-bottom: 400px; */
    margin-right: 0px;
    margin-left: 0px;
  }
  .map-leaflet-dash {
    width: 100%;
    height: 100%;
    min-height: 100px;
    margin-top: 0px;
    /* margin-bottom: 400px; */
    margin-right: -12px;
    margin-left: -12px;
  }
  .map-save-btn {
    width: 95px;
    /* margin: 0px auto; */
    font-size: 16px;
    position: absolute;
    bottom: 22px;
  }
}

.map-marker-centered {
  background-image: url("../../images/pin\ location.png");
  /* background-size: cover; */
  background-size: 41px;
  width: 38px;
  height: 41px;
  position: absolute;
  z-index: 400;
  left: calc(50% - 19px);
  top: calc(50% - 41px);
  transition: all 0.4s ease;
}