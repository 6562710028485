.loading-modal-footer {
  justify-content: center;
  border: 0px;
  padding: 0 0 55px 0;
  color: #005589;
}
.loading-modal-img {
  width: 99px;
  height: 99px;
}
.loading-modal-body {
  text-align: center;
  padding-top: 55px;
  margin-bottom: 10px;
}
#loading-modal .modal-dialog {
  max-width: 380px;
  width: 90%;
  margin: auto auto !important;
  z-index: 100000 !important;
}

.loading-modal-body-div {
  width: fit-content;
  margin: auto;
  position: relative;
}
#loading-modal {
  z-index: 100000 !important;
  background-color: #5d5d5d47;
}

/* // Here is where the magic happens */
.loader-bg {
  position: absolute;
  bottom: 16px;
  left: calc(50% - 20px);
  background-color: white;
  border: 4px solid #c9bcbc4f;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  background-color: blueviolet;
}
@keyframes colors {
  0% {
    stroke: #005589;
  }
  25% {
    stroke: #005589;
  }
  50% {
    stroke: #005589;
  }
  75% {
    stroke: #005589;
  }
  100% {
    stroke: #005589;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
