.bg-main {
  height: max-content;
  /* height: 100%; */
  min-height: 69vh;
  width: 100%;
  margin-left: 90px;
  margin-top: 110px;
  margin-bottom: 110px;
  /* margin-top: auto;
  margin-bottom: auto; */
  padding: 40px;
  background-color: white;
}
