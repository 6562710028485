.header-drawer .MuiPaper-root {
  width: 100%;
  height: 100%;
}
.drawer-body {
  margin: 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-drawer-item {
  font-size: 14px;
  color: #33e0a0;
  padding: 12px 25px;
}
.header-drawer-item a,
.header-drawer-item a:hover {
  color: #33e0a0;
}
.drawer-menu-icon {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.w-fit {
  width: fit-content;
}

.z1300 {
  z-index: 1300 !important;
}