.rules-modal-dialog {
  max-width: 1087px;
  width: 70%;
}

.rules-modal-div {
  width: 550px;
  height: 100%;
  max-height: 85vh;
  padding: 40px 0px;
  border-radius: 6px;
  border: solid 1px #707070;
  margin-right: 5px;
}
.rules-modal-text {
  padding: 0px 35px;
  height: 85vh;
  overflow-y: scroll;
  direction: ltr;
  text-align: right;
}
/* .rules-modal-footer {
  display: block;
  height: 35px;
} */

.rules-modal-close {
  position: absolute;
  left: 0px;
  top: -40px;
  padding: 0px;
  display: block;
  width: 21px;
  height: 21px;
  margin: 15px;
  background-size: 17px;
  z-index: 900;
  cursor: pointer;
}

@media only screen and (max-width: 767.5px) {
  /* .rules-modal-header {
    border-bottom: solid 1px #c9c9c9;
    height: 53px;
    font-size: 12px;
    color: #005589;
  } */
  .rules-modal-dialog {
    max-width: unset;
    width: 100%;
    margin: 0px;
    padding: 10px;
  }
  .rules-modal-div {
    width: 100%;
    margin-right: 0px;
  }
}
