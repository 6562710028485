.bg-main-dashbord {
  width: 100%;
  height: max-content;
  /* height: 100vh; */
  /* min-height: 706px; */
  min-height: 65vh;
  display: flex;
  background-color: #f7f7f7;
}
.morecomments {
  background-color: #f7f7f7;
  height: auto;
  display: flex;
}
.margin-0 {
  margin: 0px;
}
.border-left-dashbord {
  border-left: 1px solid #eaeaea;
  padding: 0px;
  width: 214px;
  min-width: 214px;
}
.header-main {
  position: relative;
  top: -80px;
  font-size: 18px;
  color: #005589;
  font-weight: bold;
}

.input-costum-profile {
  width: 58vw;
  max-width: 755px;
  height: 44px;
  margin: auto;
}
.w-100-profile {
  width: 100%;
}
.input_in-profile:focus ~ .lable_input_custom-white,
:valid ~ .lable_input_custom-white {
  background-image: linear-gradient(to bottom, #ffffff, #ffffff) !important;
}
.input_in-profile {
  width: 100%;
}
.costum-autocompelette-profile {
  /* max-width: 370px; */
  height: 44px;
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}
.mt35 {
  margin-top: 35px;
}
.mt28 {
  margin-top: 28px;
}
.mt15 {
  margin-top: 15px !important;
}
.costum-autocompelette-country {
  width: 58vw;
  max-width: 755px;
  height: 44px;
  margin: auto;
  margin-top: 15px;
}

.costum-autocompelette-profile.css-1sh0ayc-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 44px;
}
.costum-autocompelette-country.css-1sh0ayc-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 44px;
}
.saveButton {
  width: 126px;
  height: 39px;
  border-radius: 6px;
  background-color: #33e0a0;
  border: 0px;
  color: white;
  float: left;
  position: absolute;
  bottom: -137px;
  left: 0;
}
span.saveButton1 {
  float: left;
  left: 0px !important;
  bottom: -40px;
}
.saveButton1 {
  bottom: -95px;
}
.saveButton:hover {
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.2),
    0 6px 18px -1px rgba(0, 0, 0, 0.065) !important;
}
.row_saveButton {
  /* float: left;
  position: relative;
  top: 44px;
  right: -119px; */
  width: 58vw;
  max-width: 755px;
  margin: auto;
  position: relative;
}
.mt80 {
  margin-top: 80px;
}
@media only screen and (min-width: 1046.5px) {
  .favorites-row {
    width: 68vw;
    max-width: 873px;
    margin: 0 auto;
    /* display: flex; */
  }
}
.w352 {
  width: 352px;
  height: fit-content !important;
  min-height: 145px;
  box-shadow: none;
  border-radius: 6px;
  border: solid 1px rgba(112, 112, 112, 0.12);
}
.w352:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0/7%), 0 6px 18px -1px rgb(0 0 0/4%) !important;
  top: -0px;
}

.rate-pos {
  text-align: left;
  position: absolute;
  left: 9px;
  bottom: 8px;
}
.img-r-card-dash {
  height: 145px;
}
.mb35 {
  margin-bottom: 35px;
}
.img_delete1 {
  width: 18px;
  height: 18px;
  float: left;
  margin-left: 15px;
  margin-top: 0px;
  cursor: pointer;
}
.img_delete-fav {
  width: 18px;
  height: 18px;
  /* float: left; */
  /* margin-right: 15px; */
  /* margin-top: -4px; */
  position: absolute;
  bottom: 8px;
  cursor: pointer;
}

.f14 {
  font-size: 14px;
}
.f12 {
  font-size: 12px;
}
.img_delete {
  width: 18px;
  height: 18px;
  float: left;
  margin-left: 25px;
  margin-top: -12px;
}
.img_edit {
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 6px;
}
.emptyGish_profile {
  width: 16px;
}
.span_emptyGish_profile {
  width: 80px;
  float: left;
  margin-left: 25px;
}
.span_emptyGish_profile1 {
  width: 80px;
  text-align: left;
  position: absolute;
  left: 9px;
  bottom: 8px;
}
/* .proofile_tooltip{
  position: absolute;
  left: 3px;;
} */
.proofile_tooltip .tooltiptext {
  visibility: hidden;
  width: 64px;
  height: 33px;
  background: #fff;
  color: black;
  border: 1px solid #33e0a0;
  text-align: center;
  border-radius: 6px;
  padding: 8.5px 0;
  position: absolute;
  z-index: 1;
  font-size: 12px;
}
.proofile_tooltip:hover .tooltiptext {
  visibility: visible;
  position: absolute;
  /* top: -25px;
  left: 13px; */
  bottom: 30px;
  color: #005589;
}

.body-comment-profile {
  min-height: fit-content;
  height: 193px;
  width: 969px;
  margin: auto;
  margin-top: 15px;
  box-shadow: none;
  border: solid 1px #c9c9c9;
  border-radius: 6px;
  box-sizing: 6px;
}
.body-comment-profile:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0/7%), 0 6px 18px -1px rgb(0 0 0/4%) !important;
}
.more2 {
  height: auto;
  background-color: white;
  margin-bottom: 115px;
  margin-top: 108px;
  margin-bottom: -20px;
  padding: 40px;
  width: 100%;
  margin-left: 90px;
}
.img-comment-profile {
  width: 207px;
  min-height: 191px;
  height: 191px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  object-fit: cover;
}
.d-contents {
  display: contents;
}
.first-title-profile {
  color: #005589;
  font-size: 14px;
  line-height: 1.36;
  margin-left: 25px;
}
.first-title-profile2 {
  margin-left: 12px;
  top: -2px;
}
.ml20 {
  margin-left: 20px;
}
/* .w697 {
  width: 697px;
} */
.time-title-profile {
  color: #67808f;
  font-size: 11px;
  line-height: 1.43;
  margin-left: 25px;
}

.proofile_tooltip .tooltiptext_option {
  visibility: hidden;
  width: 80px;
  height: 33px;
  color: black;
  border: 1px solid #33e0a0;
  background-color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
.proofile_tooltip:hover .tooltiptext_option {
  visibility: visible;
  position: absolute;
  top: 66px;
  left: 24px;
  color: #005589;
}
.mr10 {
  margin-right: 10px;
}

.edite_profile_icon {
  font-size: 17px;
  color: #005589;
}

.proofile_tooltip .tooltiptext_edite {
  visibility: hidden;
  width: 93px;
  height: 33px;
  color: black;
  border: 1px solid #33e0a0;
  background-color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
.proofile_tooltip:hover .tooltiptext_edite {
  visibility: visible;
  position: absolute;
  top: 117px;
  left: 51px;
  color: #005589;
}
.costum_option_center {
  position: relative;
  top: -39px;
}
.img_message_profile {
  width: 120px;
  height: 120px;
  display: block;
}
.text_message_profile {
  font-size: 22px;
  color: #005589;
}
.container_main_message_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
label#profile-label,
label#birthday-label,
label#gender-label {
  color: #c9c9c9;
  position: absolute;
  top: -5px;
}
.mt150 {
  /* margin-top: 100px; */
  margin-top: 80px;
}
.close_logout {
  width: 13px;
  height: 13px;
}
.main_div_logout {
  width: 451px;
  height: 136px;
  padding: 10px 10px 20px 10px;
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  margin: auto;
  color: #005589;
}
.balebutton {
  width: 85px;
  height: 30px;
  /* padding: 3px 34px 2px; */
  border-radius: 6px;
  background-color: #33e0a0;
  color: white;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: -webkit-center;
}
.balebutton:hover {
  color: white;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.2),
    0 6px 18px -1px rgba(0, 0, 0, 0.065) !important;
}
.text-last-center {
  text-align-last: center;
}
.reletive_profile {
  position: relative;
}
.close_modal {
  position: absolute;
  left: 0px;
  display: block;
}
.costum_modal {
  width: 518px;
  height: 188px;
  padding: 10px 30px 10px 10px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(176, 176, 176, 0.16);
  border: solid 1px #c9c9c9;
  background-color: #fff;
  /* z-index: 16000; */
}
.text_modal {
  margin-top: 45px;
  margin-right: -5px;
  margin-bottom: 0;
  /* width: 452px; */
  font-size: 16px;
  /* line-height: 0.63; */
  letter-spacing: -0.4px;
  text-align: center;
  color: #005589;
}
.button_modal {
  width: 123px;
  height: 39px;
  background-color: #eaeaea;
  margin-top: 30px;
  text-align: center;
  border: 0px;
  border-radius: 6px;
  color: #005589;
}
.button_modal:hover {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 20%), 0 6px 18px -1px rgb(0 0 0 / 7%) !important;
  color: #005589;
}
.a_s_c {
  align-self: center;
}
/*  */
.img_no_comments {
  width: 100px;
  height: 100px;
  margin: 110px 0px 0px 0px;
}
.text_no_image {
  /* width: 272px; */
  height: 42px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: expanded;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #005589;
  margin: 18px 0px 0px 0px;
}
.bg-main-dashbord-more {
  width: 100%;
  height: auto;
  min-height: 706px;
  display: flex;
  background-color: #f7f7f7;
  padding-bottom: 20px;
}

/* auto 10 page profile  */
/* auto10 */
.auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 6px;
}
.auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  width: 6px;
  /* background: #59e6a3; */
  border-radius: 6px;
  background-color: #babac0;
  border: 0px solid #fff;
}
.auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}
/* step2 auto10 */
.auto10 > div > div > ul {
  max-height: 25vh;
}
/* .auto10 > .MuiAutocomplete-root {
  width: 755px;
  max-width: 755px;
  height: 44px;
  height: 58px;
} */
.auto10 > div > div > div > div > .MuiAutocomplete-endAdornment {
  position: initial;
}
.auto-error > div > .MuiOutlinedInput-notchedOutline {
  border: solid 1px red !important ;
}
/* .auto10 > div > div > div > div > .MuiOutlinedInput-notchedOutline, */
.auto10
  > div
  > div
  > div
  > div
  > .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline,
.auto10 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  position: absolute;
  top: -5px;
}

.auto10 {
  width: fit-content;
}

.auto10 > .MuiAutocomplete-popper {
  /* transform: translate3d(0px, 50px, 0px) !important; */
  /* transform:translate3d(0px, 990.5px, 0px) !important; ; */
  /* display: contents; */
  /* width: 569px; */
  /* left: 0px; */
  /* margin: 0px; */
  /* transform: translate3d(458.5px, 990.5px, 0px); */
  /* inset: 0px 0px 0px 0px; */
  padding: 0px 0px !important;
}

.auto10 > div > .MuiAutocomplete-paper {
  width: inherit;
  /* position: relative; */
  position: fixed;
  top: 1px;
}

.auto10 > div > div > div > .MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto10 > div > div > div > .MuiAutocomplete-inputRoot {
  height: 43px;
  padding: 0 15px 0 5px !important;
  background-color: #fff;
}

.auto10 > div > div > div > div > div > .MuiAutocomplete-popupIndicatorOpen {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg) !important;
  -ms-transform: rotate(-90deg) !important;
  transform: rotate(-90deg) !important;
}
.auto10 > div > div > div > label {
  /* right: 6px; */
  font-size: 14px !important ;
  top: -4px;
  color: #c9c9c9;
}
.auto10 > div > div > div > div > .MuiAutocomplete-input {
  /* font-size: 14px !important ; */
  top: -4px;
  /* color: black; */
  padding: 0px !important;
}
.auto10 > div > div > div > label.Mui-focused,
.auto10 > div > div > div > label.MuiInputLabel-shrink {
  font-size: 14px !important ;
  top: 0px;
  color: #c9c9c9;
}
.row-profilefirst {
  /* width: 58vw; */
  max-width: 755px;
  /* display: flex; */
  justify-content: space-between;
}
.input-costum-name {
  width: 48.5%;
  height: 44px;
  margin-top: 15px;
}

/* message box */
.top__39 {
  margin-top: -40px;
}
.message-box {
  border-radius: 6px;
  border: solid 1px #eaeaea;
  background-color: #fff;
  width: 100%;
  max-width: 928px;
  margin-top: 16px;
}
.message-box:hover {
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 6%), 0 6px 18px -1px rgb(0 0 0 / 2%) !important;
}
.message-box-tit {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #005589;
  border-bottom: solid 1px #eaeaea;
  padding: 12px 25px 12px 0px;
}
.message-box-body {
  font-size: 12px;
  letter-spacing: -0.31px;
  text-align: right;
  color: #005589;
  padding: 18px 25px;
}

.proofile_tooltip:hover .tooltiptext.tooltiptext_mbox {
  top: -22px;
  left: 14px;
}

/* page 6a = 7 modal */
.text_modal_delete_img {
  font-size: 16px;
  font-weight: normal;
  font-stretch: expanded;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: -0.4px;
  text-align: right;
  color: #005589;
  text-align: center;
}
.button_modal_delete_img {
  width: 123px;
  height: 39px;
  background-color: #eaeaea;
  margin-top: 18px;
  text-align: center;
  border: 0px;
}
.modal_delete_img {
  width: 518px;
  height: 188px;
  border-radius: 6px;
}
.modal-delete-img-header {
  border: none;
  height: 39px;
}
.button_delete_modal_img {
  position: absolute;
  left: 7px;
  top: 20px;
}

/* new favorits for dashbord */

/* .bg_modal_fade {
  background-color: rgb(224 224 224 / 10%);
} */
.succsess_delete {
  /* position: absolute;
  left: -28%;
  top: 32%; */
  /* max-width: 360px;
  margin: 1.75rem auto; */
  position: fixed;
  top: 35% !important;
  right: calc(55% - 214px);
  z-index: 2000;
}
.alert_succsess_delete {
  width: 302px;
  height: 122px;
  /* padding: 34px 40px 34px 44px; */
  border-radius: 6px;
  border: solid 1px #67808f;
  background-color: #005589 !important;
  color: white !important;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: absolute;
  /* margin-top: -200px; */
  z-index: 100;
  letter-spacing: 0.5px;
  box-shadow: 0 6px 8px -1px rgb(0 0 0 / 19%), 0 8px 22px -1px rgb(0 0 0 / 9%) !important;
}
.text_alert_delete {
  width: 218px;
  font-size: 14px !important;
  font-weight: normal;
  text-align: center;
  color: #fff;
  margin: auto;
  font-family: IRANSansWeb;
}
/* input  */
.custom-text-field-dash {
  width: 100% !important;
  max-width: 755px;
  margin: auto;
}
/*  */
/* new 17 aban */
.icon-pro-auto {
  width: 20px;
  height: 20px;
}
.dash-img-mask {
  display: none;
}
/* 21 azar */
.dash-text-field input {
  background-color: #fff;
  border-radius: 6px;
}
.custom-text-field.custom-text-field-border-error > div > fieldset {
  /* border-color: #33E0A0 !important; */
  border: 1px solid #f82020 !important;
}

@media only screen and (max-width: 767.5px) {
  .custom-text-field-dash {
    width: 100% !important;
    max-width: 755px;
  }

  /*  */
  .bg-main-dashbord {
    height: 100%;
    min-height: 100%;
  }
  .bg-main-dashbord-more {
    min-height: 80vh;
  }
  .bg-main {
    background-color: #f7f7f7 !important;
    height: auto !important;

    /* height: 83%; */
    /* min-height: 480px !important; */
    min-height: 80vh !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 16px 27px !important;
  }
  .header-main {
    position: relative;
    top: 0px;
    font-size: 15px;
  }
  .mt80 {
    margin-top: 18px;
  }
  .input-costum-profile {
    width: 100%;
    height: 39px;
  }
  .input_in-profile {
    height: 39px;
    border-radius: 6px;
  }
  .row-profilefirst {
    width: 100%;
  }
  .input-costum-name {
    width: 100%;
    height: 39px;
    margin-top: 15px;
  }
  .lable_input_custom-white {
    font-size: 14px;
  }
  .input_in-profile:focus ~ .lable_input_custom-white,
  :valid ~ .lable_input_custom-white {
    /* background-image: linear-gradient(
      to bottom,
      #f7f7f7,
      #f7f7f7,
      #f7f7f7,
      #ffffff,
      #ffffff
    ) !important; */
    background-image: none !important;
  }
  .row_saveButton {
    width: 100%;
  }
  .saveButton {
    bottom: -100px;
  }
  .borda-pro-res {
    border: solid 1px #f7f7f7;
    border-radius: 6px;
  }
  /* auto10 */
  .costum-autocompelette-profile {
    width: 100%;
  }
  .costum-autocompelette-profile {
    height: 39px;
  }
  .auto10 > div > div > div > .MuiOutlinedInput-root,
  .auto10 > div > div > div > .MuiAutocomplete-inputRoot {
    height: 39px;
  }
  .auto10
    > div
    > div
    > div
    > div
    > .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline,
  .auto10 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
    border: solid 1px #f7f7f7;
  }

  .icon-pro-auto {
    width: 17px;
    height: 17px;
  }

  .more2 {
    height: auto;
    background-color: #f7f7f7;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 16px 27px;
  }
  .top__39 {
    margin-top: 0px;
  }

  /* modal */
  .costum_modal {
    width: 100%;
    max-width: 359px;
    height: 188px;
    padding: 10px 10px 10px 10px;
  }
  .text_modal {
    margin-top: 35px;
    margin-right: 0px;
    padding: 0 10px;
    /* line-height: 0.63; */
  }
  .button_modal {
    margin-top: 22px;
  }

  /* delete success modal */
  .succsess_delete {
    right: -2px;
    top: 50% !important;
  }
  .alert_succsess_delete {
    width: fit-content;
    height: fit-content;
    padding: 12px 25px 12px 23px !important;
    border-radius: 6px;
    box-shadow: none !important;
  }
  .text_alert_delete {
    width: max-content;
    max-width: 175px;
    font-size: 12px !important;
    font-weight: normal;
    text-align: right;
    margin: auto;
    line-height: 1.58;
  }
  .succsess_delete_resp1 {
    right: -18px;
    z-index: 2000;
  }

  /* exit */
  .main_div_logout {
    width: 276px;
    height: 199px;
    padding: 10px 10px 20px 10px;
    position: inherit;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
    margin: 80px auto 0 auto;
  }
  .mt28 {
    margin-top: 52px;
  }

  /* favorites */
  .mb35 {
    margin-bottom: 16px;
  }
  .w352 {
    max-width: 100%;
    width: 100%;
  }

  /* options */
  .costum_option_center {
    /* position: relative; */
    top: 0px;
  }
  .body-comment-profile {
    height: fit-content;
    min-height: 193px;
    width: 100%;
    max-width: 334px;
    margin-top: 16px;
    border: solid 1px #eaeaea;
    border-radius: 6px;
  }
  .span_emptyGish_profile {
    width: 80px;
    float: left;
    margin-left: 0px;
  }

  .title-option-res {
    /* position: absolute; */
    /* top: 15px; */
    /* right: 25px; */
    /* color: #fff; */
  }
  .img-comment-profile {
    width: 100%;
    max-width: 332px;
    height: 205px;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .first-title-profile {
    margin-left: 0px;
    text-align: justify;
  }
  .first-title-profile-res-div {
    width: fit-content;
    margin: auto;
  }

  .first-title-profile2 {
    margin-left: 8px;
  }

  .proofile_tooltip2 {
    position: relative;
  }
  .proofile_tooltip:hover .tooltiptext_option {
    visibility: visible;
    position: absolute;
    top: -49px;
    left: -27px;
    color: #005589;
  }
  .img_no_comments {
    width: 80px;
    height: 80px;
    margin: 80px 0px 0px 0px;
  }
  .text_no_image {
    margin: 18px 0px 0px 0px;
    font-size: 19px;
  }
  .dash-img-mask {
    display: block;
    background-color: rgba(0, 0, 0, 0.35);
    height: 205px;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .img_message_profile {
    width: 85px;
    height: 85px;
  }
  .text_message_profile {
    font-size: 19px;
  }
}

.fs-16-pro {
  font-size: 16px;
}
.saveButton:active {
  background-color: #2ee59d;
  /* box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4); */
  /* background-color: #34b174; */
  color: #fff;
  /* transform: translateY(-7px); */
}

.auto10 > div > div > ul {
  max-height: 216px;
  padding: 0;
}

.input-error-dash {
  text-align: center;
  color: #f82020;
  font-size: 14px;
  letter-spacing: -0.7px;
  display: block;
  margin-bottom: -14px;
}
/*  */
@media only screen and (max-width: 767.5px) {
  .auto10 > div > div > ul {
    max-height: 214px;
    padding: 0;
  }
  .auto10 > .MuiAutocomplete-popper {
    padding: 0px 0px !important;
  }

  .auto10 > div > .MuiAutocomplete-paper {
    width: inherit;
    position: fixed;
    top: 1px;
  }
  .muirtl-1in441m {
    margin-left: 0px !important;
    width: fit-content !important;
  }
  .auto10 div > div > ul > .MuiAutocomplete-option {
    padding: 8.5px 4px;
    font-size: 12px;
    border-bottom: 0.35px solid #dfdfdf;
    min-height: unset;
  }
  .auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 6px;
  }
  .auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 6px;
  }
  .auto10 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 12px;
    background-color: #babac0;
    border: 0px solid #fff;
  }
  .auto10
    > div
    > div
    > .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
  }
  .auto10 > div > div > div > .MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto10 > div > div > div > .MuiAutocomplete-inputRoot {
    height: 40px;
    padding: 0 15px 0 5px !important;
    background-color: #fff;
  }

  .input-error-dash {
    font-size: 12px;
    margin-bottom: -14px;
  }
}

/* .width-80-fav {
  max-width: 79%;
  display: inline-block;
  color: #005589;
} */
.width-80-fav {
  /* max-width: 79%;
  display: inline-block; */
  display: flex;
  justify-content: space-between;
  color: #005589 !important;
}
.width-80-fav a {
  color: #005589 !important;
}
.minh-fav-card {
  min-height: 143px;
}

.profile-Skeleton {
  width: 100% !important;
  max-width: 755px;
  margin: auto;
  border-radius: 6px !important;
  height: 69px !important;
}
.mt-n13 {
  margin-top: -13px;
}

.change-pash-dahs-error {
  width: 100% !important;
  max-width: 755px;
  margin-right: 0px !important;
}
.rtl-password-input input {
  direction: ltr;
  text-align: right;
}

.list-left > div > div > ul > .MuiAutocomplete-option {
  justify-content: left;
  text-align: left;
}
