@media only screen and (max-width: 767.5px) {
  .modal-dialog-centered.eb-modal-dialog {
    justify-content: center;
  }
  .eb-modal-content {
    max-width: 338px;
  }
  /* .display-modal-none {
    display: none;
  } */
  .eb-modal-dialog {
    max-width: 90%;
  }
  .eb-modal-header {
    padding: 0 16px;
    height: 70px;
    justify-content: center;
  }
  .header-modal-data {
    left: 0% !important;
    font-size: 20px !important;
  }
  .eb-modal-body {
    padding: 35px 16px 8px 16px;
    min-height: fit-content;
    height: 515px;
    overflow-y: auto;
  }
  .eb-modal-body2 {
    padding: 20px 20px 103px 20px !important;
  }
  .btn-modal-data {
    width: 85% !important;
    height: 51px !important;
    margin-bottom: 16px !important;
    box-shadow: none !important;
    font-size: 15px !important;
    padding: 0 !important;
  }
  .btn-modal-data:hover,
  .btn-modal-data:active,
  .btn-modal-data:focus {
    color: #fff;
    background-color: #33e0a0;
    border: solid 1px #33e0a0;
  }

  /* other pages */
  .eb-modal-icon {
    width: 20px;
    height: 20px;
    margin-right: 1px;
  }
  .eb-modal-tit1 {
    margin: 0px 6px 0px 0px;
    font-size: 16px;
    color: #005589;
  }
  .eb-modal-input {
    font-family: "IRANSansWeb";
    width: 100%;
    height: 50px;
    margin: 10px 0 0 0;
    padding: 0 10px;
    border-radius: 6px;
    border: solid 1px rgba(150, 148, 148, 0.6);
    display: block;
    font-size: 14px;
    /* letter-spacing: -0.29px; */
    /* color: #005589; */
    /* font-family: Roboto; */
  }
  .eb-modal-input > div {
    padding: 0 0 0 10px;
  }
  .eb-modal-input:focus-visible {
    outline: none;
  }
  .eb-modal-textarea {
    /* direction: rtl; */
    resize: none;
    width: 100%;
    min-height: 150px;
    height: fit-content;
    /* max-height: 290px; */
    margin: 10px 0 0 0;
    padding: 10px 10px;
    font-size: 14px;
  }

  .eb-modal-textarea:focus-visible {
    outline: none;
  }
  .eb-modal-add1 {
    width: 100%;
    margin-top: 10px;
  }
  .eb-modal-add {
    width: 16px;
    height: 16px;
    border: none;
    float: right;
    border-radius: 6px;
    margin-right: 0px;
    padding: 0px;
  }
  .eb-modal-add-img {
    width: 16px;
    height: 16px;
    display: block;
  }

  .eb-modal-mapbg {
    width: 100%;
    height: 130px;
    margin: 25px 0px 25px 0px;
    opacity: 0.49;
    border-radius: 8px;
    border: solid 1px #707070;
  }
  .eb-modal-mapbtn {
    width: fit-content;
    height: 44px;
    margin: 0px 0px 0 0;
    padding: 0 12px;
    border-radius: 6px;
    font-size: 15px;
    position: absolute;
    right: 24%;
    bottom: calc(24% + 20px);
  }

  /* times */
  .eb-modal-right {
    text-align: right;
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -ms-right;
    /* margin-top: 25px; */
    margin-right: 12px;
  }
  .eb-modal-body2::-webkit-scrollbar {
    float: left;
  }
  .bg-modal {
    background-color: rgb(86, 86, 86, 0.15);
  }

  .eb-modal-bottom {
    position: absolute;
    bottom: 20px;
    width: 100%;
    margin: 4px -20px 0 -20px;
    padding: 0 20px;
  }
  .eb-modal-bottom > hr {
    height: 1px;
    margin: 16px -20px 16px -20px;
  }
  .eb-modal-button {
    width: 100%;
    height: 45px;
    font-size: 18px;
    letter-spacing: -0.2px;
    padding: 0;
  }

  .eb-modal-bottom2 {
    position: unset;
    bottom: 20px;
    /* width: 100%; */
    padding-top: 0.25px;
    margin: 0px -20px -93px -20px;
    padding: 3.25px 20px 0 20px;
  }
  .eb-modal-bottom2 > hr {
    height: 1px;
    margin: 16px -20px 16px -20px;
  }
  .eb-modal-button2 {
    width: 100%;
    height: 45px;
    font-size: 18px;
    letter-spacing: -0.2px;
    padding: 0;
  }
  .eb-modal-backimg {
    width: 19px;
    height: 16.5px;
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
  .eb-modal-closeimg {
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
  .MuiPaper-root.MuiPaper-rounded.MuiPopover-paper {
    margin-top: 8px !important;
  }
  .s5-res-w-res {
    padding-right: 0px !important;
  }

  .eb-modal-lan {
    font-size: 13px;
    font-weight: 300;
    text-align: right;
    color: #005589;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  /*  */
  .eb-modal-body-map {
    padding: 24px 12px;
  }
  .eb-modal-map-text {
    font-size: 13px;
    text-align: right;
    color: #005589;
    margin: 22px 0 0px;
  }
  .eb-modal-map-text > img {
    width: 26px;
    height: 26px;
    margin-left: 8px;
  }

  .eb-modal-body-map-close {
    width: fit-content;
    height: fit-content;
    position: absolute;
    z-index: 1000;
    top: 34px;
    left: 24px;
    cursor: pointer;
  }
  .eb-modal-body-map-close img {
    width: 14px;
    height: 14px;
  }
  .auto10.auto10-new > div > div > div > .MuiAutocomplete-inputRoot,
  .auto10.auto10-new > div > div > div > .MuiOutlinedInput-root {
    height: 50px;
    padding: 0 15px !important;
  }

  .eb-modal-body-scroll {
    padding: 0px 0px 0px 0px;
  }
  .eb-modal-body-scroll-div {
    height: calc(515px - 81.25px);
    /* overflow: auto; */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px !important;
  }
  .eb-modal-bottom-scroll {
    position: relative;
    bottom: 20px;
    padding: 3.25px 20px 0;
  }
  .eb-modal-bottom-scroll hr {
    /* margin: 0 -20px; */
    height: 1px;
    margin: 16px -20px 16px -20px;
    background-color: #c9c9c9;
  }
}

@media only screen and (max-width: 767.5px) {
  .rb-s5-group2 {
    width: 100%;
    display: block;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }
  .rb-s5-group2-btn-on {
    width: 181px;
    height: 42px;
    border-radius: 20px;
    /* border: solid 1px #c9c9c9;
    background-color: #fff !important; */
    font-size: 14px;
    margin-left: 0px;
    margin-top: 12px;
  }
  .rb-s5-group2-btn-off {
    width: 181px;
    height: 42px;
    border-radius: 20px;
    border: solid 1px #c9c9c9;
    background-color: #fff !important;
    font-size: 14px;
    margin-left: 0px;
    margin-top: 12px;
  }

  .rb-s5-group {
    width: 100%;
    justify-content: center;
  }
  .rb-s5-group-btn-off {
    width: 102px;
    height: 39px;
    background-color: #fff !important;
    font-size: 14px;
    margin-left: 12px;
    margin-bottom: 0px;
  }
  .rb-s5-group-btn-on {
    width: 102px;
    height: 39px;
    font-size: 14px;
    margin-left: 12px;
    margin-bottom: 0px;
  }
}
