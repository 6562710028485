.alert-error-snackbar {
  top: 25px !important;
}
.alert-error-alert {
  border-radius: 6px !important;
  font-family: IRANSansWeb !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 10px 12px 10px 11px !important;
  font-weight: normal !important;
  width: fit-content !important;
}
.alert-error-alert .MuiAlert-icon {
  margin: 0px 0 0 8px;
  padding: 0px;
  position: relative;
  animation: alertAnime;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 10000;
}
@keyframes alertAnime {
  0% {
    left: 0px;
    top: 0px;
  }
  45% {
    left: 0px;
    top: -4px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
.alert-error-alert .MuiAlert-message {
  padding: 1px;
  overflow: hidden !important;
}
