@media only screen and (min-width: 766.5px) {
  .MuiAutocomplete-hasPopupIcon.css-1drhif0-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 20px !important;
    height: 3rem !important;
  }

  /* wwwwww */
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: 0px !important;
    border: none !important;
  }

  /* wwwwww */
  .auto1 > div > div > .MuiFormControl-root,
  .MuiTextField-root,
  .css-i44wyl {
    width: 100% !important;
  }
  /*  */
  /* for ^ icons and x */
  /* .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.css-ptiqhd-MuiSvgIcon-root {
 display: none !important; 
}
.css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
display: none !important;
} */
  .css-b7k0tb-MuiAutocomplete-listbox
    .auto1
    > div
    > div
    > ul
    > .MuiAutocomplete-option.Mui-focused {
    background-color: #dcf8ed !important;
  }
  .auto1 > div > div > ul > .MuiAutocomplete-option:hover {
    background-color: #dcf8ed !important;
  }

  .auto1 > .MuiAutocomplete-popper {
    top: 0px !important;
    transform: translate3d(0px, 50px, 0px) !important;
  }

  .auto1 > div > .MuiAutocomplete-paper {
    border-radius: 0 0 6px 6px !important;
    position: fixed;
    width: 100%;
  }
  .auto1 > div {
    width: 100%;
  }
  .auto1 svg {
    display: none;
  }
  .autocomplete-search-lap {
    width: 18px;
    height: 18px;
    margin-left: 10px !important;
    margin-right: 2px;
  }
  .autocomplete-search-lap-bizphoto {
    width: 25px;
    height: 25px;
    margin: 0px 0 0px 12px !important;
    border-radius: 6px;
    object-fit: cover;
  }
  .auto-g .MuiAutocomplete-groupLabel {
    /* font-weight: bold; */
    font-family: inherit;
    font-size: 15px;
    max-height: 30px;
    display: flex;
    padding-top: 8px;
    align-items: center;
    color: #005589;
  }
  .auto-g .MuiAutocomplete-option {
    font-size: 13px;
    border-bottom: 1px solid #dfdfdf;
    min-height: fit-content !important;
    padding: 4px 16px !important;
    color: #005589;
  }
  .auto-g .MuiAutocomplete-option:last-child {
    border-bottom: 1px solid #969494;
    padding-bottom: px;
  }
  .last1-auto {
    position: relative;
    top: -6px;
  }
  .last2-auto {
    position: absolute;
    right: 38px;
    font-size: 12px;
    color: #67808f;
    top: 18px;
  }
  .auto-g .MuiAutocomplete-listbox {
    max-height: 479px;
    /* overflow-y: hidden; */
    padding: 0;
    /* max-height: unset; */
  }
  .div-auto-g1 {
    height: 36px;
    align-items: center;
  }
  .div-auto-g2 {
    height: 30px;
    align-items: center;
  }
  /* boeewwwwww */
  /* .MuiFormControl-root,
  .MuiTextField-root,
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  } */
  /* /wwwwww */
  /* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1drhif0-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    height: 1rem !important;
  } */
  /* دسته بندی */
  /* .auto1 > div > div >  */
  .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 6px;
    background-color: #f1f1f1;
  }

  /* .auto1 > div > div >  */
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    border-radius: 6px !important;
    background-color: #babac0;
    border: 0px solid #fff;
  }
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
    /* background: #59e6a3; */
  }
  /* .auto1 > div > div >  */
  .css-b7k0tb-MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 6px;
    background-color: #f1f1f1;
  }
  /* .auto1
    > div
    > div
    >  */
  .css-b7k0tb-MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    border-radius: 6px !important;
    background-color: #babac0;
    border: 0px solid #fff;
  }
  .css-b7k0tb-MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
    /* background: #59e6a3; */
  }
  /* .MuiFormControl-root,
  .MuiTextField-root,
  .css-i44wyl {
    width: 100% !important;
  } */
  .MuiAutocomplete-hasPopupIcon.css-5j2f68 .MuiOutlinedInput-root {
    padding-right: 20px !important;
  }
  /* .css-5j2f68 .MuiOutlinedInput-root .MuiAutocomplete-input { */
  /* padding: 7.5px 38px 7.5px 6px !important; */
  /* } */
  /* .MuiAutocomplete-endAdornment .css-2iz2x6 {
    top: calc(50% - 11px) !important;
    left: 0px !important;
    padding-right: 34px !important;
  } */
  /* for img */
  .css-2iz2x6 {
    top: calc(50% - 11px) !important;
    left: 0px !important;
    padding-right: 34px !important;
  }
  .css-1q60rmi-MuiAutocomplete-endAdornment {
    top: calc(50% - 11px) !important;
    left: 0px !important;
    padding-right: 21px !important;
  }
  .css-5j2f68 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 0px !important;
    /* display: none !important; */
  }
  /* .MuiAutocomplete-hasPopupIcon.css-5j2f68 .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-5j2f68 .MuiOutlinedInput-root {
    padding-right: 38px !important; */
  /* height: 3rem !important; */

  .css-igs3ac {
    border: 0px !important;
  }
}

/* for all autocomplete */
.css-1v4ccyo,
.css-segi59,
.css-hdw1oc,
.css-t4hinj {
  font-family: IRANSansWeb !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: inherit !important;
}
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: IRANSansWeb !important;
  color: #005589;
  text-align: right;
  /* justify-content: left !important; */
}
.auto3 .css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option,
.auto3 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: IRANSansWeb !important;
  color: #005589;
  text-align: left;
  justify-content: left !important;
}
/* for top border of text feild */
.css-hdw1oc {
  display: contents;
}
/* new 2/9 */
.select_login_sucess {
  width: 75px;
  height: 43px;
  padding: 7px 13px 6px 12px;
  /* opacity: 0.3; */
  border-radius: 6px;
  border: solid 1px #70707000;
  /* background-color: #ededed; */
  cursor: pointer;
}
.select_login_sucess:hover {
  border: solid 0px #707070;
  /* background-color: #ededed; */
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 20%) !important;
  /* background-color: #ededed; */
}
.select_login_sucess_color {
  border: solid 0px #707070;
  /* background-color: #ededed; */
}
.select_login_sucess_color2 {
  /* background-color: #ededed !important; */
}
.select_login_sucess2 {
  border: solid 0px #707070;
  background-color: #fff;
}
.select_login_sucess2:hover {
  border: solid 0px #707070;
}
/* .select_login_sucess:hover {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 20%) !important;
} */
.imageLogin_success_header {
  width: 30px;
  height: 30px;
  float: left;
  pointer-events: none;
}
.nextLogin {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  margin-right: -3px;
  pointer-events: none;

  /* rotate: 0deg; */
}
.nextLogin-rotate {
  /* rotate: 90deg; */
  transform: rotate(90deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.nextLogin-rotateback {
  /* rotate: 90deg; */
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.box_show_info_userlogin {
  min-width: max-content;
  width: max-content;
  /* width: 143px; */
  /* height: 114px; */
  padding: 7px 15px !important;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute !important;
  top: 43px;
  left: 0px;
  z-index: 1000;
}
.box_show_info_userlogin div {
  height: 37.33px;
  padding: 5px;
  font-size: 13px;
  color: #005589;
}
.box_show_info_userlogin a {
  color: #005589;
}
.img20x20 {
  width: 18px;
  height: 18px;
  margin-left: 7px;
}
.img12x12 {
  width: 12px;
  height: 12px;
  margin-left: 7px;
}
.auto1 > div {
  width: 100%;
}

#auto-koja-listbox > li {
  border-bottom: 1px solid #dfdfdf;
}

#auto7-listbox > li {
  border-bottom: 1px solid #dfdfdf;
}
.auto-paper-space > div {
  top: 59px !important;
}
/* header cat hover */
.header-icons-hov {
  /* width: 34px;
  height: 34px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.header-dropdown-content3 {
  bottom: unset;
  top: 78px;
  left: unset;
  right: 24px;
  /* width: 250px; */
  padding: 8px 8px 4px;
  border-radius: 8px;
}
/* .header-dropdown-content3 > .dropdown-row{
  text-align: right;
    color: black;
    cursor: pointer;
    padding: 3.5px 4px;
    font-size: 14px;
    border-bottom: 1px solid #c9c9c9;
    text-decoration: none;
    display: block;
    color: #005589;
}
.header-dropdown-content3 > .dropdown-row:hover {
  background-color: unset;
} */
.header-dropdown-content3 div > div > a {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: right;
  border-radius: 6px;
  color: #005589;
  border-bottom: 0px;
}
.header-dropdown-content3 div > div > a > img {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}
.header-dropdown-content3 div > div > a:hover {
  background-color: #f7f7f7;
}
/* @media only screen and (max-width: 767.5px) {
  .header-icons-hov {
    width: 34px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 8px;
  }

  .header-icons-hov:hover {
    background-color: #e7e7e7;
  }
} */

.img-header-subcat {
  width: 20px;
  margin-left: 8px;
  height: 20px;
}
.box-subcat-header {
  display: flex;
  background-color: #fff;
  z-index: 1;
  width: max-content;
  bottom: unset;
  top: 80px;
  left: unset;
  right: 24px;
}
.a-header-subcat {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: right;
  border-radius: 6px;
  color: #005589;
  border-bottom: 0px;
  padding: 3.5px 4px;
  margin: 0 2px 2px;
  font-size: 13px;
}
.a-header-subcat:hover {
  background-color: #f7f7f7;
  color: #005589;
}

.auto-where-option {
  border-bottom: 1px solid #dfdfdf;
  padding: 0 14px;
}
.auto-where-option:hover {
  background-color: #33e0a0;
}

/* header2 auto where */
ul#auto7-listbox {
  padding: 0px;
}

/* biz selectbox */
.select_login_head_biz {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-biz {
  width: 16px;
  height: 16px;
  margin-right: -3px;
  /* for obj */
  pointer-events: none;

  /* rotate: 0deg; */
}

.nextLogin-rotateback-biz {
  /* rotate: 90deg; */
  transform: rotate(90deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.nextLogin-rotate-biz {
  /* rotate: 90deg; */
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.box_show_info_userlogin_biz {
  min-width: max-content;
  width: max-content;
  /* width: 143px; */
  /* height: 114px; */
  padding: 7px 15px !important;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute !important;
  top: 37px;
  left: 38px;
  z-index: 1000;
}
.box_show_info_userlogin_biz div {
  height: 37.33px;
  padding: 5px;
  font-size: 13px;
  color: #005589;
  display: flex;
  align-items: center;
}
.box_show_info_userlogin_biz a {
  color: #005589;
}
.box_show_info_biz_img {
  pointer-events: none;
  width: 15px;
  height: 15px;
  margin-left: 7px;
}

.box_show_info_userlogin_biz hr,
.box_show_info_userlogin hr {
  color: #eaeaea;
}

.header-chip-div {
  display: flex;
  align-items: center;
  justify-content: right;
  /* justify-content: center; */
  position: absolute;
  right: 15px;
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}
.choose-city-chip {
  border-right: 1px solid #babac0;
  padding-right: 10px;
  font-size: 12px;
  color: #babac0;
}
.MuiAutocomplete-paper {
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.farsi-error-input {
  border: 1px solid #f82020 !important;
  height: 54px;
}
.farsi-error-input2 {
  border: 1px solid #f82020 !important;
  height: 50px;
  border-radius: 6px;
}
.farsi-error-input ::placeholder,
.farsi-error-input2 ::placeholder {
  color: #f82020 !important;
  opacity: 1 !important;
}
.chamge-lan-btn {
  display: block;
  width: 34px;
  min-width: 34px;
  height: 32px;
  margin: 0 30px 0px 14px;
  padding: 0px;
  border-radius: 8px;
  background-color: #a0c1d5;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
  /* font-size: 16px;
  line-height: 2.3;
  font-weight: bold; */
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-family: Roboto;
  text-transform: none;
  color: #fff;
}
.chamge-lan-btn:hover,
.chamge-lan-btn:active,
.chamge-lan-btn:focus {
  background-color: #a0c1d5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
}
