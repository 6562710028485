/*  */
/* panel1d-rtl-content */

#panel1d-rtl-content > div > p .moremodal-span {
  font-size: 13px;
}
/* #panel1d-rtl-header > div > p .moremodal-header{
  font-weight: bold;
} */

.moremodal-border:first-child > div {
  border-top: 0px solid !important;
  font-weight: bold;
}
.moremodal-header-link {
  color: #005589;
}
.moremodal-header-link:hover {
  color: #33e0a0;
}
