.filter-dropdown {
  border-radius: 19px;
  border: solid 1px #6f98b1;
  background-color: #fff;
  /* min-width: 116px; */
  min-width: max-content;
  width: max-content;
  height: 34px;
  margin: 0 8px;
  padding: 0 12px 0 7px;
  font-size: 16px;
  color: #005589;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.box_show_filter-dropdown {
  font-size: 14px;
  min-width: 324px;
  width: max-content;
  /* width: 143px; */
  /* height: 114px; */
  padding: 0px !important;
  border-radius: 6px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute !important;
  top: 34px;
  right: 8px;
  z-index: 1;
  max-height: 455px;
  height: fit-content;
  overflow-y: auto;
}
.box_show_filter-dropdown .result-filter-item:hover {
  background-color: #33e0a02e !important;
}
.box_show_filter-dropdown-payment {
  height: 163px;
  overflow-y: auto;
}

.nextFilter-biz {
  width: 15.5px;
  height: 18.6px;
  margin-right: 4px;
}
.nextFilter-biz-rotateback {
  /* rotate: 90deg; */
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nextFilter-biz-rotate {
  /* rotate: 90deg; */
  transform: rotate(-90deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* true / false btn */
.filter-dropdown-off {
  border-radius: 19px;
  border: solid 1px #6f98b1;
  background-color: #fff;
  /* min-width: 116px; */
  min-width: max-content;
  width: max-content;
  height: 34px;
  margin: 0 8px;
  padding: 0 12px 0 12px;
  font-size: 16px;
  color: #005589;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filter-dropdown-on {
  border-radius: 19px;
  border: solid 1px #005589;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #005589;
  /* min-width: 116px; */
  min-width: max-content;
  width: max-content;
  height: 34px;
  margin: 0 8px;
  padding: 0 12px 0 12px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 940.5px) {
  .filter-dropdown {
    margin: 0 5px;
    padding: 0 14px 0 8px;
    font-size: 14px;
  }
  .filter-dropdown-off {
    margin: 0 5px;
    padding: 0 14px 0 14px;
    font-size: 14px;
  }

  .filter-dropdown-on {
    margin: 0 5px;
    padding: 0 14px 0 14px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767.5px) {
  .filter-dropdown {
    border-radius: 19px;
    border: solid 1px #6f98b1;
    background-color: #fff;
    /* min-width: 116px; */
    width: max-content;
    height: 24px;
    margin: 0 6px;
    padding: 0 10px 0 5px;
    font-size: 12px;
  }
  .box_show_filter-dropdown {
    font-size: 14px;
    min-width: 324px;
    min-width: max-content;
    width: max-content;
    /* width: 143px; */
    /* height: 114px; */
    padding: 0px !important;
    border-radius: 6px;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    position: absolute !important;
    top: 40px;
    right: 8px;
    z-index: 1;
    height: 455px;
    overflow-y: scroll;
  }
  .box_show_filter-dropdown .result-filter-item:hover {
    background-color: #33e0a02e !important;
  }

  .nextFilter-biz {
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }

  /*  */
  .filter-dropdown-off {
    border-radius: 19px;
    border: solid 1px #6f98b1;
    background-color: #fff;
    /* min-width: 116px; */
    width: max-content;
    height: 24px;
    margin: 0 4px;
    padding: 0 10px;
    font-size: 12px;
  }
  .filter-dropdown-on {
    border-radius: 19px;
    border: solid 1px #6f98b1;
    /* min-width: 116px; */
    width: max-content;
    height: 24px;
    margin: 0 4px;
    padding: 0 10px;
    font-size: 12px;
  }
}
