.biz-map-modal-dialog {
  max-width: 1087px;
  width: 70%;
}

.biz-map-modal-div {
  height: 90vh;
  border-radius: 6px;
  border: solid 1px #707070;
}

.biz-map-modal-close {
  position: absolute;
  left: 0px;
  padding: 0px;
  display: block;
  width: 21px;
  height: 21px;
  margin: 15px;
  background-size: 17px;
  z-index: 900;
}
.biz-map-modal-leaflet {
  width: 100%;
  min-height: 250px;
  height: 100%;
  border-radius: 6px;
}

@media only screen and (max-width: 767.5px) {
  .biz-map-modal-header {
    border-bottom: solid 1px #c9c9c9;
    height: 53px;
    font-size: 12px;
    color: #005589;
  }
  .biz-map-modal-direction {
    width: 127px;
    height: 44px;
    padding: 10px;
    /* margin: 8.5px 0 623px 247.5px; */
    /* padding: 8px 11px 9px 22px; */
    border-top-left-radius: 36px;
    border-bottom-left-radius: 36px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    font-size: 14px;
    color: #005589;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
    top: 8px;
  }
  .biz-map-modal-direction:hover,
  .biz-map-modal-direction:active,
  .biz-map-modal-direction:focus {
    background-color: #fff;
    color: #005589;
  }

  .biz-map-modal-leaflet {
    width: 100%;
    min-height: 250px;
    height: calc(100vh - 54px);
    border-radius: 0px;
  }
  /* .biz-map-modal-btn-close {
    width: 18px;
    height: 18px;
    pointer-events: none;
    rotate: 90deg;
    background: transparent url('../../images//business/nextblue.svg') center/1em auto no-repeat;
  } */
  .biz-map-modal-btn-dir {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    pointer-events: none;
  }
}

.map-hh {
  height: 300px;

  width: 400px;
}
.leaflet-control-attribution {
  display: none;
}
.leaflet-pane {
  z-index: 1 !important;
}
