.container.sidedashbord-side {
  border-radius: 6px;
  background-color: #fefefe;
  /* text-align-last: center; */
  /* height: 100vh; */
  height: auto;
}
.img-sidbar-logo {
  width: 130px;
  /* height: 38px; */
  margin-top: 42px;
}
.mt-120 {
  margin-top: 65px;
  /* change it , check it */
}
.ml10 {
  margin-left: 10px;
}
.click {
  cursor: pointer;
}
.click:hover {
  background-color: #f7f7f7 !important;
  border-radius: 10px !important;
}
.chooseitem {
  border-radius: 10px !important;
  background-color: #e2fff1 !important;
  color: #33e0a0;
}
.text-align-last-center {
  text-align-last: center;
}
.img_slider_true {
  width: 20px;
  margin-left: 10px;
}
.img_slider_false {
  width: 20px;
  margin-left: 10px;
}

.eu-mx-12px {
  margin: 8px 12px;
}
.click.eu-mx-12px{
  color: #005589 ;
}