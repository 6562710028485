.bottom-drawer-open {
  /* position: absolute !important;
  top: 10px;
  z-index: 10000; */
}
.bottom-drawer-inside {
  padding: 0 28px 0 28px !important;
}
.bottom-drawer-inside::-webkit-scrollbar {
  display: none;
}
.bottom-drawer-inside .rec-card {
  max-width: 100%;
  overflow: hidden;
}
.PrivateSwipeArea-root {
  z-index: 0 !important;
}
.bottom-drawer-open-icon {
  width: 30px;
  height: 25px;
  margin: 0 6px;
  rotate: 180deg !important;
}
.bottom-drawer-close-icon {
  width: 30px;
  height: 25px;
  margin: 0 6px;
}

.first-open-icon-drawer {
  position: absolute;
  bottom: 12px;
  z-index: 1210;
  left: calc(50% - 15px);
}
.first-open-icon-drawer > img {
  margin: 0px !important;
}
/* for test */
.MuiDrawer-root.result-filter-mob-drawer {
  z-index: 1300 !important;
}
