#panel3d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg,
#panel2d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg,
#panel1d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg {
  background-image: url("../../images/next-left.png");
  background-repeat: no-repeat, repeat;
  background-position: left 0em top 50%, 0 0;
  margin-left: -4px;
  margin-top: -0px;
  background-size: 15px 17px, 100%;
}
#panel3d-rtl-header > .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
#panel2d-rtl-header > .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
#panel1d-rtl-header > .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(-90deg);
  margin-top: 0px;
  background-size: 15px 17px, 100%;
}
#panel3d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg > path,
#panel2d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg > path,
#panel1d-rtl-header > .MuiAccordionSummary-expandIconWrapper > svg > path {
  display: none;
}
#panel3d-rtl-content > div > p,
#panel2d-rtl-content > div > p,
#panel1d-rtl-content > div > p,
#panel3d-rtl-header > div > p,
#panel2d-rtl-header > div > p,
#panel1d-rtl-header > div > p {
  font-family: IRANSansWeb !important;
  font-size: 14px;
  color: #005589;
}
#panel3d-rtl-header > div,
#panel2d-rtl-header > div,
#panel1d-rtl-header > div {
  margin: 0;
}
.result-filter-item > label > .checkmark-s2 {
  border: 1px solid #c9c9c9;
  width: 13px;
  height: 13px;
}
.result-filter-item:first-child {
  margin-top: -10px;
}
.result-filter-item:last-child {
  margin-bottom: -10px;
}
.result-filter-item {
  padding: 10px 0 5px;
}
.result-filter-item:not(:last-child) {
  border-bottom: 1px solid #8ef0c0;
}
.checkmark-s2.back-white {
  background-color: #fff;
}
@media only screen and (max-width: 767.5px) {
  #panel3d-rtl-header > div > p,
  #panel2d-rtl-header > div > p,
  #panel1d-rtl-header > div > p {
    margin-right: 13px;
  }
  #panel3d-rtl-header,
  #panel2d-rtl-header,
  #panel1d-rtl-header {
    padding: 10px 13px 10px 35px;
    height: 100%;
  }
  #panel3d-rtl-content > div,
  #panel2d-rtl-content > div,
  #panel1d-rtl-content > div {
    margin: 0px 38px;
  }
  .result-filter-item {
    padding: 0px 0 1px;
    display: block;
  }
  .result-filter-item-res {
    padding: 6px 0;
    display: block;
    font-size: 14px !important;
  }
}
.zzz {
  width: 10px;
  display: block;
  height: 10px;
}
