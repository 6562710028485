@media only screen and (max-width: 767.5px) {
  .list-rec {
    width: 100%;
    /* height: 200px; */
    background-color: #f7f7f7;
    margin-bottom: 20px;
    padding: 9px 0px 17px 0px;
    /* padding: 27px 0px 17px 0px; */
  }
  .img-listmobile {
    width: 39px;
    height: 39px;
  }
  .a-listmobile {
    font-size: 13px !important;
    color: #005589;
  }
  .a-listmobile:hover {
    color: #005589;
  }
  div.listmobile-center {
    text-align: center !important;
  }
  .list2 {
    margin-top: 18px;
  }
  .mb-listmobile-25 {
    margin-bottom: 23px;
    width: 39px;
  }
  .w-285 {
    width: 76%;
    max-width: 285px;
  }
}
