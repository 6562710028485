@media only screen and (max-width: 767.5px) {
  .css-1m6e7ft-MuiPaper-root-MuiAlert-root,
  .css-ejdn3 {
    box-shadow: none !important;
    font-family: IRANSansWeb !important;
    background-color: #b294f2 !important;
    border-radius: 0px !important;
    padding: 0px 0px 0px 0px !important;
    width: 100% !important;
    min-height: 90px;
    height: fit-content;
  }
  .css-ki1hdl-MuiAlert-action,
  .css-1mzcepu {
    margin: 0px !important;
    padding: 0px !important;
  }
  /* x icon size */
  .css-ptiqhd-MuiSvgIcon-root,
  .css-1k33q06 {
    width: 18px !important;
    height: 18px !important;
    margin-top: 4px;
    margin-right: 4px;
  }
  /* div with x icon  */
  .css-1e0d89p-MuiButtonBase-root-MuiIconButton-root,
  .css-q28n79 {
    padding: 0px !important;
  }
  .css-1pxa9xg-MuiAlert-message,
  .css-1xsto0d {
    align-self: center;
    width: 100% !important;
  }
  .css-1t20nyk-MuiSnackbar-root {
    bottom: 0px !important;
    width: 100%;
  }
  /* host */
  .css-1ozswge {
    bottom: 0px !important;
    width: 100%;
  }

  /*  */
  .alert-m-body {
    margin: 0px 7px 0px 20px;
  }
  .alert-m-text {
    font-size: 12px;
    width: 62%;
    letter-spacing: -0.18px;
    padding-left: 10px;
    line-height: 1.6;
  }
  .alert-m-text13 {
    font-size: 13px;
  }
  .alert-m-btn {
    width: 117px;
    min-height: 30px;
    height: fit-content;
    font-size: 12px;
    letter-spacing: -0.4px !important;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(99, 99, 99, 0.16);
    border-radius: 6px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert-m-btn13 {
    font-size: 13px;
  }
  .alert-m-btn:hover,
  .alert-m-btn:active,
  .alert-m-btn:focus {
    background-color: #fff;
  }
}
@media only screen and (max-width: 599.5px) {
  .alert-m-snackbar {
    right: unset !important;
    left: unset !important;
  }
}
