.result-filter-mob-drawer > .MuiPaper-root {
  border: solid 1px #707070;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-family: IRANSansWeb;
  /* padding-top: 4px; */
}
.result-filter-mob-drawer > .MuiPaper-root::-webkit-scrollbar {
  display: none;
}
.result-filter-mob-box {
  height: fit-content;
  max-height: calc(100vh - 330px);
}
.result-filter-mob-drawer-tit {
  /* margin: 0px 28px 2px 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #005589; */
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-right: 24px;
  margin-bottom: 4px;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 1;
}
.result-filter-mob-drawer-tit > p {
  margin-top: 10px;
  margin-bottom: 14px;
  /* margin: 0px 28px 2px 10px; */
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #005589;
}
.result-filter-mob-drawer-tit img {
  width: 12px;
  height: 12px;
}

.result-filter-mob-box .color-242b2e,
.result-filter-mob-box .container-s2 {
  padding: 0 24px;
}
