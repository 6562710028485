/* .req-header {
  width: 100%;
  height: 28px;
  height: 7.48vw;
  background-color: #33E0A0;
} */

/*  */
@media only screen and (max-width: 767.5px) {
  .Rectangle-1 {
    width: 100%;
    /* height: 159px; */
    margin: 0 0 5px;
    padding: 0 0 28px 0;
    /* 25px */
    background-color: #f7f7f7;
  }
  .Rectangle-where {
    width: 100%;
    margin: 0 0 5px;
    padding: 0 0 0 0;
    /* background-color: #f7f7f7; */
    /* height: 412px; */
    height: 100vh;
  }
  .pt-18 {
    padding-top: 15px;
  }
  .btn-blog {
    height: 30.5px;
    width: 88px;
    border-radius: 6px;
    background-color: #33e0a0;
    margin-right: 7px;
    font-size: 14px;
    font-stretch: expanded;
    padding: 0px;
    color: #005589;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-blog:focus,
  .btn-blog:hover,
  .btn-login:focus,
  .btn-login:hover {
    background-color: #33e0a0;
    color: #005589;
  }
  .logo {
    width: 83px;
  }
  /* .centerr {
    text-align: center;
  } */
  .leftt {
    text-align: left;
    text-align: -webkit-left;
    text-align: -moz-left;
    text-align: -ms-left;
  }

  .btn-login {
    height: 30.5px;
    width: 84.5px;
    font-size: 14px;
    margin-left: 7px;
    color: #005589;
    padding: 0px;
    border-radius: 6px;
    background-color: #33e0a0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mx-175 {
    margin: 0 5.515px;
  }
  .input-search {
    border: solid 1px #c9c9c9 !important;
    height: 42px;
    margin-top: 23px;
    outline: 0px;
    border: 0px;
    border-radius: 6px !important;
    padding: 0px 15px;
  }
  .input-search::placeholder,
  .input-search2::placeholder,
  .input-search3::placeholder {
    color: #a2a2a2d4;
    font-weight: 500 !important;
    /* font-size: 16px; */
    font-size: 16px;
  }
  .input-search2 {
    height: 42px;
    margin-top: 16px;
    outline: 0px;
    border: 1px solid #d6d6d6;
    border-radius: 6px !important;
    padding: 0px 15px;
  }
  .input-search3 {
    height: 42px;
    /* margin-top: 4px; */
    margin-top: 4px;
    outline: 0px;
    border: 1px solid #d6d6d6;
    border-radius: 6px !important;
    padding: 0px 15px;
  }
  /* .searchspan {
    position: relative;
    top: -30px;
    text-align: left;
  } */

  .search-a:hover {
    color: black;
  }
  .search-icon {
    width: 24px;
    height: 24px;
    margin-top: 16px;
    padding: 0px;
    position: absolute;
    left: 6px;
    color: black;
  }
  .search-icon2 {
    width: 24px;
    height: 24px;
    margin-top: 6.3px;
    padding: 0px;
    position: absolute;
    left: 14px;
    color: black;
  }

  input#combo-box-demo0::placeholder {
    /* font-weight: 00; */
    font-weight: 500 !important;
    color: #a2a2a2d4 !important ;
    /* font-size: 17px; */
    font-size: 17px;
  }
  .css-hdw1oc {
    display: contents;
  }
  .MuiAutocomplete-hasPopupIcon.css-viou3o-MuiAutocomplete-root
    .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-viou3o-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    /* padding-right: 11px !important; */
    padding-right: 11px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 42px;
    /* margin-top: 4px; */
    margin-bottom: -3.4px;
  }
  /* host */
  .MuiAutocomplete-hasPopupIcon.css-1na68kg .MuiOutlinedInput-root {
    padding-right: 11px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 42px;
    /* margin-top: 4px; */
    margin-bottom: -3.4px;
  }
  .css-viou3o-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input,
  .css-1na68kg .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px !important;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px !important;
  }
  .css-viou3o-MuiAutocomplete-root {
    margin-top: 4px !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 6px !important;
  }
  /* host */
  .css-1na68kg {
    margin-top: 4px !important;
    border: 1px solid #d6d6d6 !important;
    border-radius: 6px !important;
  }

  /* with host */
  .css-viou3o-MuiAutocomplete-root,
  .MuiAutocomplete-root {
    width: 100% !important;
    padding: 0px;
  }

  .MuiAutocomplete-popper {
    /* padding: 0px 3px !important; */
    padding: 0px 3px !important;
  }
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: inherit !important;
  }

  .MuiAutocomplete-listbox {
    padding: 0px !important;
    /* padding-top: 10px !important; */
  }
  .auto2 > div > div > .css-b7k0tb-MuiAutocomplete-listbox {
    /* height: 53vw; */
    max-height: unset !important;
  }
  /* host */
  .auto2 > div > div > .css-1xs8iq0 {
    max-height: unset !important;
  }
  .auto2
    > div
    > div
    > ul
    > .css-b7k0tb-MuiAutocomplete-listbox
    .auto2
    > div
    > div
    > ul
    > .MuiAutocomplete-option {
    padding: 0vw 11px !important;
    /* min-height: 39px !important; */
    font-size: 16px;
    height: 40px !important;
    border-bottom: 1px solid #dfdfdf;
    height: 42px;
  }
  /* host */
  .auto2 > div > div > ul > .MuiAutocomplete-option {
    font-size: 16px;
    padding: 0px 11px !important;
    min-height: 40px !important;
    border-bottom: 1px solid #dfdfdf;
    height: 42px;
  }
  .css-igs3ac {
    border: none !important;
  }
  .auto2 > div > .css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
      0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgba(0, 0, 0, 0) !important;
  }
  /* host */
  .auto2 > div > .MuiAutocomplete-paper {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
      0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgba(0, 0, 0, 0) !important;
  }
  /*  modal */
  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 0 !important;
  }
  /* host */
  .css-919eu4 {
    opacity: 0 !important;
  }

  .css-1nvnyqx-MuiPaper-root-MuiDrawer-paper {
    box-shadow: 0px 5px 6px -5px rgb(0 0 0 / 30%),
      0px 16px 24px 2px rgb(0 0 0 / 0%), 0px 6px 30px 5px rgb(0 0 0 / 0%) !important;
  }
  .css-oqbj7d {
    box-shadow: 0px 5px 6px -5px rgb(0 0 0 / 30%),
      0px 16px 24px 2px rgb(0 0 0 / 0%), 0px 6px 30px 5px rgb(0 0 0 / 0%) !important;
  }
  .fs-4vw {
    font-size: 16px;
  }

  .autocomplete-search {
    width: 18px;
    height: 18px;
  }
  .sabt-mobile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 199px;
    height: 30px;
    padding: 0px;
    border-radius: 6px;
    font-size: 14px;
    background-color: #005589;
    color: #fff;
  }
  .sabt-btn-a,
  .sabt-btn-a:focus,
  .sabt-btn-a:hover {
    color: #fff;
  }
  .sabt-mobile-btn:focus,
  .sabt-mobile-btn:hover {
    background-color: #005589;
    color: #fff;
  }
  .mb-sabt-mobile {
    margin-bottom: 25px !important;
  }

  .header-close-img {
    width: 13px;
    height: 13px;
    margin-right: 12px;
    margin-top: 12px;
  }
  input#combo-box-demo0::placeholder {
    font-size: 17px;
    color: #565353 !important;
  }

  .auto-where-paper > div > div {
    margin-top: 2px;
  }
  .auto-where-paper > div {
    padding: 0px !important;
  }

  .auto-g .MuiAutocomplete-groupLabel {
    /* font-weight: bold; */
    font-family: inherit;
    font-size: 15px;
    max-height: 30px;
    display: flex;
    padding-top: 8px;
    align-items: center;
    color: #005589;
  }
  .auto-g .MuiAutocomplete-option {
    font-size: 13px;
    border-bottom: 1px solid #dfdfdf;
    min-height: fit-content !important;
    padding: 4px 16px !important;
  }
  .auto-g .MuiAutocomplete-option:last-child {
    border-bottom: 1px solid #969494;
    padding-bottom: px;
  }
  .last1-auto {
    position: relative;
    top: -6px;
  }
  .last2-auto {
    position: absolute;
    right: 38px;
    font-size: 12px;
    color: #67808f;
    top: 18px;
  }
  .auto-g .MuiAutocomplete-listbox {
    max-height: 479px;
    /* overflow-y: hidden; */
    padding: 0;
    /* max-height: unset; */
  }
  .div-auto-g1 {
    height: 36px;
    align-items: center;
  }
  .div-auto-g2 {
    height: 30px;
    align-items: center;
  }
  .autocomplete-search-lap {
    width: 18px;
    height: 18px;
    margin-left: 10px !important;
    margin-right: 2px;
  }
  .auto-res-paper-space > div {
    top: 48px !important;
  }
  .header-chip-div {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    right: 0px;
    height: 40px;
    margin-top: 24px;
  }
  .autocomplete-search-lap-bizphoto {
    width: 25px;
    height: 25px;
    margin: 0px 0 0px 12px !important;
    border-radius: 6px;
    object-fit: cover;
  }
  .farsi-error-input3 {
    border: 1px solid #f82020 !important;
    height: 44px;
    margin-top: -2px !important;
    border-radius: 6px;
  }
  .farsi-error-input3 input#combo-box-demo0::placeholder {
    color: #f82020 !important;
    opacity: 1 !important;
  }

  .chamge-lan-btn {
    width: 29.5px !important;
    min-width: 29.5px !important;
    height: 27px !important;
    margin: 0 15px 0px 2px !important;
    font-size: 15px !important;
    /* line-height: 2 !important; */
    font-weight: normal !important;
  }
}

.fixed-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  /* z-index: 100000; */
}
/* .headermobile-hr{
  height: 1.35vw !important;
  margin: 0;
  background-color: #fff;
  opacity: 1;
} */
.box-under-header-res {
  height: 144px;
}
.green_login {
  /* background-color: #33e0a0; */
  color: black;
  height: 30.5px;
  width: 65px;
  padding: 7px 3px 6px 12px;
  margin-left: 7px;
  border-radius: 6px;
  border: solid 1px #70707000;
  cursor: pointer;
}
.imageLogin_success_header_mobile {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: -2px;
  margin-left: -9px;
  pointer-events: none;
}
.nextLogin_mobile {
  width: 7px;
  height: 7px;
  margin-top: -9px;
  margin-bottom: 4px;
  margin-right: -2px;
  margin-left: 9px;
  pointer-events: none;
}
.fix_header_mobile_login {
  top: 46px;
  left: 17px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: -1px;
}
/* .zindex-100000{
  z-index: 1000000 !important;
} */
