@font-face {
  font-family: IRANSansWeb;
  src: url(../font/IRANSansWeb.eot) format("eot");
  src: url(../font/IRANSansWeb.ttf) format("ttf");
  src: url(../font/IRANSansWeb.woff) format("woff");
  /* numbers */
  src: url("../font/eot/IRANSansWeb(FaNum).eot");
  src: url("../font/eot/IRANSansWeb(FaNum).eot?#iefix")
      format("embedded-opentype"),
    url("../font/woff2/IRANSansWeb(FaNum).woff2") format("woff2"),
    url("../font/woff/IRANSansWeb(FaNum).woff") format("woff"),
    url("../font/ttf/IRANSansWeb(FaNum).ttf") format("truetype");
}
@font-face {
  font-family: IRANSansWeb2;
  src: url(../font/IRANSansWeb.eot) format("eot");
  src: url(../font/IRANSansWeb.ttf) format("ttf");
  src: url(../font/IRANSansWeb.woff) format("woff");
}
/* @font-face {
  font-family: "MyFont";
  src: url(../font/IRANSansWeb.eot) format("eot");
  src: url(../font/IRANSansWeb.ttf) format("ttf");
  src: url(../font/IRANSansWeb.woff) format("woff");
  src: url("../font/eot/IRANSansWeb(FaNum).eot");
  src: url("../font/eot/IRANSansWeb(FaNum).eot?#iefix")
      format("embedded-opentype"),
    url("../font/woff2/IRANSansWeb(FaNum).woff2") format("woff2"),
    url("../font/woff/IRANSansWeb(FaNum).woff") format("woff"),
    url("../font/ttf/IRANSansWeb(FaNum).ttf") format("truetype");
  unicode-range: U+0600-06FF;
} */

/* @font-face {
  font-family: "MyFont";
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc4.woff2);
  unicode-range: U+0020-007F;
} */
body {
  /* font-family: Roboto; */
  font-family: IRANSansWeb !important;
}
.font-family-en {
  font-family: IRANSansWeb2 !important;
}
.font-family-en input {
  font-family: IRANSansWeb2 !important;
}
@supports (-webkit-touch-callout: none) {
  /* body{
   letter-spacing: -.3px !important;
    word-spacing: -1px !important;
  }
  .color-tit2 {
    letter-spacing: -.9px !important;
  }
  .text-bottom {
    letter-spacing: -0.56px !important;
  }
  .moarefi-btn {
    letter-spacing: -1.02px !important;
    word-spacing: -5px !important;
  }
  .moarefi {
    letter-spacing: -81px !important;
  }
  @media only screen and (max-width: 575.5px) {

    .text-bottom {
      letter-spacing: -0.58px !important;

    }
  } */
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: 1112px;
}

.main-font-color {
  color: #33e0a0 !important;
}

.bg-5ae6a2 {
  background-color: #33e0a0 !important;
}

.shadow-off {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.hov-shadow:hover {
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 8%), 0 9px 22px -1px rgb(0 0 0 / 5%) !important;
}

.hov-shadow2:hover {
  box-shadow: 0 2px 7px -1px rgb(0 0 0 / 20%), 0 6px 21px -1px rgb(0 0 0 / 12%) !important;
}

.hov-shadow-btn:hover,
.hov-shadow-btn:active,
.hov-shadow-btn:focus {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 20%), 0 6px 18px -1px rgb(0 0 0 / 6.5%) !important;
}

.hov-shadow-btn-dark:hover,
.hov-shadow-btn-dark:active,
.hov-shadow-btn-dark:focus {
  /* box-shadow: 0 2px 6px -1px rgb(0 0 0 / 20%), 0 6px 18px -1px rgb(0 0 0 / 6.5%) !important; */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%), 0 2px 10px 0 rgb(0 0 0 / 10%) !important;
}

@media only screen and (min-width: 575.5px) {
  .hov-trans,
  .hov-trans2 {
    -webkit-transition: 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transition: 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    position: relative;
    top: 0px;
  }

  .hov-trans:hover {
    top: -8px;
  }

  .hov-trans2:hover {
    top: -4px;
  }

  .fw-sm-bold {
    font-weight: 700 !important;
  }
}

.hover-5ae6a2:hover .hover-5ae6a2 {
  color: #33e0a0 !important;
}

/* Footer */
.bg-282828 {
  background-color: #282828 !important;
}

.bg-footer {
  background-color: #02314e !important;
}
.color-242B2E {
  color: #005589;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-8 {
  font-size: 8px !important;
}

@media only screen and (max-width: 767.5px) {
  .fs-xs-22 {
    font-size: 22px !important;
  }

  .fs-xs-16 {
    font-size: 16px !important;
  }

  .fs-xs-15 {
    font-size: 15px !important;
  }

  .fs-xs-14 {
    font-size: 14px !important;
  }

  .fs-xs-13 {
    font-size: 13px !important;
  }

  .fs-xs-12 {
    font-size: 12px !important;
  }

  .fs-xs-11 {
    font-size: 11px !important;
  }

  .fs-xs-10 {
    font-size: 10px !important;
  }

  .fs-xs-9 {
    font-size: 9px !important;
  }

  .fs-xs-8 {
    font-size: 8px !important;
  }

  .fs-xs-7 {
    font-size: 7px !important;
  }

  .mb-xs-10 {
    margin-bottom: 10px;
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.select-footer:focus-visible {
  border: none !important;
  outline: none !important;
}

.select-footer {
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  padding-left: 20px;
  padding-right: 1px;
  background-image: url("../../images/next\ white.png");
  background-repeat: no-repeat, repeat;
  background-position: left 0em top 50%, 0 0;
  background-size: 16px auto, 100%;
}

#select_country_custom div {
  padding-right: 39px;
  padding-left: 13px;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.xxxxx.select_country_language.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  background-color: #33e0a0 !important;
  color: white;
}

/* 0 */
.height-91 {
  height: 91.8px;
}

.footer-logo {
  width: 90px;
  height: unset;
}

@media only screen and (max-width: 767.5px) {
  .height-91 {
    height: 79px;
  }

  .footer-icon {
    width: 22px !important;
    height: 22px !important;
  }

  .footer-logo {
    max-width: 85px;
    width: 100%;
    height: unset;
  }
}

.footer-icon {
  width: 25px;
  height: 25px;
}

.footer-icon:hover {
  /* background-color: #33e0a0;
  border-radius: 50%; */
  filter: brightness(0) invert(1);
}

@media only screen and (min-width: 767.5px) {
  .height-287 {
    height: 287px !important;
  }
  .mr-md-30 {
    margin-right: 32px !important;
  }
}

@media only screen and (min-width: 991.5px) {
  .height-287-2 {
    height: 287px !important;
  }
}
.mx-n12 {
  margin: 0 -12px;
}

.foot-hover {
  color: #fff !important;
}

.foot-hover:hover {
  color: #c1bfbf !important;
}

/* Header */
/*  */
.mb-30 {
  margin-bottom: 30px;
}

.pt-34 {
  padding-top: 24px;
  padding-bottom: 27px;
}

.head-bg {
  /* background-image: url("../../images/header/0.png"); */

  background-size: cover;
  width: 100%;
  height: 325px;
  margin-bottom: 45px !important;
  /* opacity: 0.67; */
}

.header-color {
  /* background: rgba(0, 0, 0, 0.5); */
  background: rgba(112, 112, 112, 0.25);
  height: inherit;
}
.header-nav {
  background-color: #ffffff;
}

.text-align-center {
  text-align: center !important;
}

.color-5ae6a2 {
  color: #33e0a0;
}

.color-5ae6a2:hover {
  color: #33e0a0;
}

.sabt-hov:hover {
  color: #323a3de2;
}

.width-53px {
  height: 26px !important;
  width: fit-content !important;
}

.bg-head-btn {
  width: 107px;
  padding: 0px;
  height: 45px;
  font-weight: 300 !important;
  border-radius: 6px;
  /* background-color: rgba(255, 255, 255, 0.2); */
  border: solid 1px #005589;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0px rgb(0 0 0 / 0%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005589;
}

.bg-head-btn:hover,
.bg-head-btn:focus,
.bg-head-btn:active {
  /* letter-spacing: -0.8px; */
  /* box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 10%) !important; */
  background-color: #00548916;
  border: solid 1px #005589;
  color: #005589;
}

/* header inputs */
.Searchinput {
  background-color: #fff;
  border-radius: 6px;
  direction: rtl;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.first-input-what-container {
  height: 54px;
  border: 1px solid #c5c5c5;
  border-left: none;
  border-radius: 0 6px 6px 0;
  width: 47.5%;
  /* padding: 0 7px; */
  display: flex;
  align-items: center;
  background-color: white;
}

.first-input-what {
  width: 100%;
  border: 10px solid red;
}

.second-input-where-container {
  height: 54px;
  border: 1px solid #c5c5c5;
  border-left: none;
  border-radius: 0px;
  width: 36%;
  padding: 0 7px;
  display: flex;
  align-items: center;
}

.relative {
  position: relative;
}

.icon-inputs {
  /* left: 0px !important; */
  width: 22px;
  height: 22px;
  color: #33e0a0;
  margin: 0 0px 0 0;
  align-self: center;
  float: right;
  align-items: center;
  display: flex;
  flex-direction: row;
  /* height: 48px; */
}

.search-submit-btn {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 10%);
  padding: 0px;
  background-color: #33e0a0;
  border: none;
  height: 54px;
  border-radius: 6px 0 0 6px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  outline: none;
  width: 62px;
  /* font-size: 1.5rem; */
  align-items: center;
  display: flex;
}

.search-submit-btn:disabled {
  background-color: #cccccc;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 10%);
}

.search-submit-btn:active,
.search-submit-btn:focus,
.search-submit-btn:hover {
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%) !important;
  background-color: #33e0a0;
}

.search-submit-btn > a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-img {
  width: 25px;
  height: 25px;
}

.category-input-container {
  height: 54px;
  border: 1px solid #c5c5c5;
  border-left: none;
  border-radius: 0px;
  padding: 0 0px 0 0px;
  display: flex;
  align-items: center;
  width: 47%;
}

.category-input-container:focus {
  border: 1px solid #33e0a0;
}

/*  */

/* header list */
.list-group-item {
  background-color: #fff0 !important;
  border: 0px !important;
}

.a-list {
  color: #005589;
}

.a-list:hover {
  color: #33e0a0;
}

.img-list {
  /* filter: brightness(0) invert(1); */
  width: 50px;
  height: 50px;

  /* filter: brightness(0) invert(1); */
}

/* category */
.color-858585 {
  color: #6f98b1;
}

.color-101010 {
  color: #005589;
}

.fs-25 {
  font-size: 25px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.centerr {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
}

.category-items {
  overflow: auto;
  display: flex;
  /* max-width: 1088px; */
}

.cat-item {
  margin-left: 16px;
  margin-bottom: 50px;
  width: 203px !important;
  /* height: 151px !important; */
  flex: none;
}

.cat-item2 {
  margin-left: 0px;
  margin-bottom: 0px;
  width: 198px;
  flex: none;
}

@media only screen and (max-width: 767.5px) {
  .p-xs-0 {
    padding: 0px;
  }

  /* .category-items{
    margin-right: 8px ;
  } */
  .mb-xs-40 {
    margin-bottom: 40px !important;
  }

  .mb-xs-0 {
    margin-bottom: 0px !important;
  }

  .pt-xs-40 {
    padding-top: 40px !important;
  }

  .mb-xs-60 {
    margin-bottom: 60px !important;
  }

  .cat-card {
    max-width: 104px !important;
    max-height: 88px !important;
  }

  .cat-item {
    margin-left: 9px !important;
    margin-bottom: 14px;
    max-width: 104px !important;
  }

  .img-cat {
    margin-bottom: 8px !important;
    margin-top: 12px !important;
    align-self: center;
    max-width: 31px !important;
    max-height: 31px !important;
  }

  .text-cat {
    font-size: 10px !important;
    padding-top: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    height: 19px !important;
  }

  .category-items2::-webkit-scrollbar {
    display: none;
  }

  .category-items::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    max-width: 26.66vw;
    height: 5px !important;
    border-radius: 6px !important;
    background-color: #f1f1f1 !important;
    margin: 5px;
  }

  .category-items::-webkit-scrollbar-track {
    /* display: none; */
    background-color: #f1f1f1 !important;
    border-radius: 6px;
    border-radius: 6px !important;
    background-clip: padding-box;
    margin: 5px;
  }

  .category-items::-webkit-scrollbar-thumb {
    height: 5px !important;
    border-radius: 6px !important;
    background-clip: padding-box;
    /* color: #babac0 !important; */
    /* border: 0.5px solid #fff !important; */
    /* margin: 15px; */
  }

  .fs-xs-16 {
    font-size: 16px !important;
  }
}

.cat-card {
  width: 203px !important;
  height: 151px !important;
  border: 1px solid rgba(112, 112, 112, 0.11);
  border-radius: 6px;
}

.category-items::-webkit-scrollbar-track {
  height: 9px;
  border-radius: 6px;
  background-clip: padding-box;
  background-color: #f1f1f1 !important;
}

.category-items::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 9px;
}

.category-items::-webkit-scrollbar-thumb {
  height: 9px;
  border-radius: 6px;
  background-clip: padding-box;
  /* color: #33E0A0 !important; */
  color: #babac0;
  /* border: 1px solid #fff; */
}

.category-items::-webkit-scrollbar-thumb:hover {
  color: #a3a3a3 !important;
  /* background: #59e6a3; */
}

.category-items::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  max-width: 400px;
  height: 9px;
  border-radius: 6px;
  background-clip: padding-box;
  background-color: #f1f1f1 !important;
}

/* ;;;;;;; */
/* .category-items::-moz-scrollbar-thumb {
  box-shadow: inset 0 0 0 9px;
}
.category-items::-moz-scrollbar-thumb{
  height: 9px;
  border-radius: 4.5px;
  background-clip: padding-box;
  color: #33E0A0;
} */
/* .category-items::-moz-pr {
  max-width: 400px;
  height: 9px;
  border-radius: 4.5px;
  background-clip: padding-box;
  background-color: #f02020;
} */

/*  */
.img-cat {
  width: 60px !important;
  height: 60px !important;
  margin-top: 26px;
  margin-bottom: 16px;
  align-self: center;
}

.text-cat {
  font-size: 15px;
  padding-top: 12px;
  height: 42px !important;
  border-top: 1px solid rgba(112, 112, 112, 0.22);
}

/* application */

.line-height19 {
  line-height: 1.9;
}

.pb-60 {
  padding-bottom: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.my-33 {
  margin-top: 33px;
  margin-bottom: 33px;
}

.mb-45 {
  margin-bottom: 45px;
}

/* for responsive */
.app-img {
  width: 135px;
  border-radius: 6px;
}

/* for laptop */
@media only screen and (min-width: 767.5px) {
  .app-img {
    height: 61px;
    width: 200px;
    border-radius: 14px;
  }
}

.mx-10px {
  margin-left: 11px;
  margin-right: 11px;
}

.fs-20 {
  font-size: 20px;
}

.color-434343 {
  color: #005589;
}

.fs-18 {
  font-size: 18px;
}

.color-434343 {
  color: #005589;
}
.mt-191 {
  margin-top: 140px;
}

@media only screen and (min-width: 650px) {
  .app-input {
    width: 604px !important;
  }
}

.app-input {
  width: 100%;
  height: 55.1px;
  padding-right: 12px;
  padding-left: 12px;
  border: solid 1px rgba(112, 112, 112, 0.16);
  border-radius: 6px;
  direction: ltr;
}

.app-input::placeholder {
  color: rgba(67, 67, 67, 0.48) !important;
  text-align: right !important;
}

.app-input:focus {
  outline: none;
  border: 1px solid #33e0a0;
}

.mt-27 {
  margin-top: 27px;
}

.app-btn {
  padding: 0px;
  width: 89.5px;
  height: 34.8px;
  border-radius: 6px;
  border: none;
  font-size: 17px;
  background-color: #33e0a0;
  color: #005589;
}

.app-btn:disabled {
  background-color: #767676;
}

.app-btn:hover,
.app-btn:focus,
.app-btn:active {
  background-color: #33e0a0;
  color: #005589;
}
.error-app {
  width: 604px !important;
  margin: 0 auto !important;
  display: inline-block !important;
}
@media only screen and (max-width: 767.5px) {
  .app-input {
    max-width: 86% !important;
    height: 38px;
  }

  .app-input::placeholder {
    font-size: 12px;
  }

  .app-btn {
    width: 41px;
    height: 27px;
    /* font-size: 3.2vw; */
    font-size: 14px;
  }

  .my-xs-3 {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }

  .mb-xs-12 {
    margin-bottom: 16px !important;
  }

  .app-text {
    padding-right: 3px;
    max-width: 86%;
    /* height: 22.4vw; */
    font-stretch: expanded;
  }

  .app-center {
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }
  .error-app {
    width: 100%;
    max-width: 86% !important;
  }
}

@media only screen and (min-width: 767.5px) {
  .app-btn-center {
    text-align: center !important;
  }
}

/* rate */
.rate-pos {
  text-align: left;
  position: absolute;
  left: 9px;
  bottom: 8px;
}

.rate-pos-comment2 {
  width: fit-content;
  position: absolute;
  right: 0px;
  top: 18px;
}

.rate-img {
  width: 16px;
  height: 15px;
}

/* .rate-img-s {
  width: 20px;
  height: 15px;
} */
/* .rate-pos-business {
} */
.rate-business {
  width: 24.2px;
  height: 22.8px;
}

.rate-comment1 {
  width: 20px;
  height: 19px;
}

.rate-comment2 {
  width: 10px;
  height: 9.5px;
}
.res-card-box2 .rate-pos-s {
  margin-top: 0px;
  text-align: right !important;
  position: relative;
  left: unset;
  bottom: unset;
}
.rate-pos-s {
  margin-top: 6px;
  text-align: right !important;
  position: absolute;
  left: 9px;
  bottom: 8px;
}
@media only screen and (max-width: 575.5px) {
  /* .rate-img {
    width: 4.26vw;
    height: 4vw;
  } */
  .rate-pos-s {
    margin-top: 6px;
    text-align: right !important;
    position: absolute;
    /* riight: 9px;  */
    /* bottom: 8px; */
  }

  /* .rate-img-s {
    width: 2.66vw;
    height: 2.5vw;
  } */
}

@media only screen and (max-width: 767.5px) {
  .rate-business {
    width: 18px;
    height: 17px;
  }

  .rate-pos-business {
    position: absolute;
    bottom: 4px;
    left: 0px;
  }

  .rate-comment1 {
    width: 20px;
    height: 19px;
  }

  .rate-comment2 {
    width: 10px;
    height: 9px;
  }

  .rate-pos-comment2 {
    position: relative;
    /* margin-right: 34px; */
    top: -8px;
  }
}

/* rate 2 */
.rate2-done {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 49px;
  right: 107px;
}

.rate2-icon {
  width: 34px;
  height: 32px;
}

.rate2-text {
  font-size: 16px;
  margin-right: 13px;
  font-weight: bold;
  color: #005589;
}

#rate2 > label > .MuiRating-iconHover,
#rate2 > label > .MuiRating-iconHover,
#rate2 > label > .MuiRating-iconHover {
  transition: none;
  transform: none;
}

@media only screen and (max-width: 767.5px) {
  /* rate 2 */
  .rate2-done {
    width: 18px !important;
    height: 18px !important;
    position: absolute !important;
    bottom: 3px !important;
    right: 5px !important;
    margin-left: 20px;
  }

  .rate2-icon {
    width: 25.2px;
    height: 23.7px;
  }

  .rate2-text {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: -2px;
    font-weight: normal;
  }

  /* .rate2-responsive-pad{
    padding-right: 2.6vw;
  } */
  .rate2-responsive-pad > div {
    margin-right: 29px;
  }
}

/* rec card */
.img-r-card {
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-left: solid 1px rgba(139, 125, 125, 0.12);
}

.card-border {
  border-radius: 6px;
  border: solid 1px rgba(139, 125, 125, 0.12);
}

@media only screen and (min-width: 575.5px) and (max-width: 767.5px) {
  .rec-md {
    width: 80%;
    margin-bottom: 8px;
    justify-content: center;
  }
}

.rec-card,
.squ-card,
.rec2-card,
.res-card {
  /* box-shadow: 0 2px 8px -1px rgb(0 0 0 / 6%), 0 6px 18px -1px rgb(0 0 0 / 4%) !important; */
  box-shadow: 0 1px 6px -1px rgb(0 0 0 / 6%), 0 6px 17px -1px rgb(0 0 0 / 4%) !important;
}
.cat-card,
.currency-card {
  box-shadow: 0 1px 6px -1px rgb(0 0 0 / 6%), 0 6px 17px -1px rgb(0 0 0 / 4%) !important;
}

@media only screen and (min-width: 575.5px) {
  .rec-card {
    height: 145px;
  }

  .img-r-card {
    height: 143.9px;
  }
}

@media only screen and (max-width: 575.5px) {
  .rec-card {
    max-width: 84%;
    text-align: right !important;
    height: 128px !important;
  }

  .img-r-card {
    width: 100% !important;
    height: 127px;
    object-fit: cover;
    /* height: -webkit-fill-available !important; */
  }

  .rec-center {
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }

  .mb-xs-31 {
    margin-bottom: 31px !important;
  }

  .mt-xs-13 {
    margin-top: 13px !important;
  }

  .mb-xs-14 {
    margin-bottom: 14px !important;
  }

  .mb-xs-12 {
    margin-bottom: 12px !important;
  }
}

/* title titr */

.tit-img {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  margin-right: 0px;
}

.color-7e7e7e {
  color: #6f98b1;
}

.color-tit2 {
  letter-spacing: -0.4px !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.color-tit:hover {
  color: #323a3de2;
}

.mb-65 {
  margin-bottom: 65px;
}

@media only screen and (max-width: 575.5px) {
}

@media only screen and (max-width: 767.5px) {
  .color-sm-101010 {
    color: #101010 !important;
  }

  .titbox-index-res {
    width: 84%;
    margin: 0 auto 10px;
  }

  .tit-img {
    width: 30px;
    height: 30px;
  }

  .tit-img1 {
    width: 25px;
    height: 25px;
    margin-right: 0px;
    padding-bottom: 3px;
    /* margin-left: 10px; */
  }

  .mb-xs-28 {
    margin-bottom: 28px !important;
  }

  .pb-xs-29 {
    padding-bottom: 29px !important;
  }

  .mb-xs-20 {
    margin-bottom: 20px !important;
  }

  .pll-8 {
    padding-left: 8%;
  }

  .prr-3 {
    padding-right: 1%;
  }
}

/* rec2 card */
@media only screen and (min-width: 767.5px) {
  .rec2-card {
    height: 128px;
  }

  .img-r2-card {
    height: 127px;
    width: 100%;
  }
}

.img-r2-card {
  object-fit: cover;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-left: solid 1px rgba(139, 125, 125, 0.12);
}

@media only screen and (max-width: 349.5px) {
  .text-bottom {
    bottom: 3px !important;
  }
}

@media only screen and (min-width: 349.5px) and (max-width: 575.5px) {
  .text-bottom {
    bottom: 10px !important;
  }
}

@media only screen and (max-width: 767.5px) {
  .rec2-card {
    max-width: 84%;
    text-align: right;
    min-height: 128px;
    height: fit-content;
  }

  .rec-center {
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }

  .img-r2-card {
    /* height: -webkit-fill-available !important; */
    /* min-height: 91px; */
    height: 127px;
    width: 100%;
  }

  .text-bottom {
    letter-spacing: -0.28px !important;
    line-height: 10px;
  }

  .mb-xs-16 {
    margin-bottom: 16px !important;
  }
}

.box-shadow-none {
  box-shadow: none;
}

.text-bottom {
  position: absolute;
  bottom: 7px;
  letter-spacing: -0.36px;
  line-height: 1.5;
}

.line-height-154 {
  line-height: 1.54;
}

.mb-10px {
  margin-bottom: 10px;
}

/* overlay image card */
.bg-image {
  border-radius: 6px !important;
}

.over-top-card {
  height: 400px;
  object-fit: cover;
  border-radius: 6px;
}

.over-top-img {
  height: 400px;
  border-radius: 6px;
}

.h-100 {
  height: 100%;
  border-radius: 6px;
}

@media only screen and (max-width: 767.5px) {
  .bg-image {
    border-radius: 0px !important;
  }
  .h-100 {
    height: 408px !important;
    border-radius: 0px;
    /* box-shadow: 0 10px 0 0px #101010; */
    box-shadow: 0 5px 6px -1px rgb(0 0 0 / 17%), 0 6px 18px -1px rgb(0 0 0 / 4%);
  }

  .over-top-img {
    border-radius: 0px !important;
  }

  .gradient-darkk {
    border-radius: 0px !important;
  }
}

.over-text {
  position: absolute;
  top: unset;
  left: unset;
  right: 5%;
  bottom: 5%;
  background-color: transparent;
}

.gradient-darkk {
  background: linear-gradient(
    180deg,
    rgba(27, 28, 30, 0) 0%,
    rgba(27, 28, 30, 0.8) 90%
  );
  border-radius: 6px;
}

/* currency-card */
.currency-back {
  background: black;
  padding: 20px 0 30px;
}

.color-whitee {
  color: white;
}

.currency-card {
  justify-content: center;
  height: 45px;
  border-radius: 6px;
  border: solid 1px rgba(112, 112, 112, 0.09);
  background-color: white;
  color: #005589;
}

.curr-img {
  width: 25px;
  height: 25px;
}

@media only screen and (max-width: 767.5px) {
  .currency-card {
    max-width: 84%;
  }
}

.color-ff0505 {
  color: #ff0505;
}

.color-3ca539 {
  color: #3ca539;
}

/* carousel */
.react-multi-carousel-list {
  margin-right: -5px;
}

.react-multi-carousel-track {
  margin-right: 5px !important;
}

.rtl.react-multiple-carousel__arrow--right {
  top: 123px;
  left: 22px !important;
}

.rtl.react-multiple-carousel__arrow--left {
  top: 123px;
  right: 15px !important;
}

/* Square card  */
.squ-card {
  border-radius: 6px;
  border: solid 1px rgba(139, 125, 125, 0.12);
}
.squ-card > a > .card-body {
  min-height: 116.6px;
}

.img-s-card {
  width: 100%;
  height: 247px !important;
  object-fit: cover;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: solid 1px rgba(139, 125, 125, 0.12);
}

@media only screen and (max-width: 767.5px) {
  .img-s-card {
    height: 182px !important;
  }
  .squ-card > a > .card-body {
    min-height: 120px;
  }
}

@media only screen and (min-width: 1063px) {
  .squ-card {
    max-width: 262px;
    border-radius: 6px;
    /* margin-left: 54px; */
  }
}

.color-101010C4 {
  color: #101010c4;
}

.color-101010ab {
  color: #67808f;
}

/* moarefiii
*/

.text-align-centerr {
  text-align: -webkit-center !important;
  text-align: -moz-center;
  text-align: -ms-center;
}

/* amoodi center */
.align-self-center {
  align-self: center !important;
}

.moarefi {
  max-width: 752px;
  height: 92px;
  margin: 44px 0 44px 0;
  padding: 0 16px 0 16px;
  border-radius: 6px;
  background-color: #b294f2;
  color: #fefefe;
  letter-spacing: 0.51px;
}

.color-2b2b2b {
  color: #2b2b2b !important;
}

.moarefi-btn {
  width: 181px;
  height: 36px;
  border-radius: 6px;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 0px 0px 0 rgb(0 0 0 / 10%) !important;
  /* margin: 12px 61px 11px 0; */
  /* padding: 4px 19px 8px; */
  background-color: #fff;
  letter-spacing: -0.72px !important;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moarefi-btn:hover,
.moarefi-btn:focus,
.moarefi-btn:active {
  color: #005589;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 10%) !important;
}

.color-242424 {
  color: #005589;
}

.color-242424:hover,
.color-242424:focus,
.color-242424:active {
  color: #005589;
}

.moarefi-icon {
  width: 55px;
  height: 55px;
}

.color-242b2e {
  color: #005589 !important;
}

@media only screen and (max-width: 767.5px) {
  .moarefi {
    width: 95%;
    height: 74px;
    box-shadow: none !important;
    font-family: IRANSansWeb !important;
    border-radius: 6px;
    padding: 0px 20px 0px 20px;
  }
}

/* add card */
/* @media only screen and (min-width: 575.5px) {
  .add-card {
    height: 145px !important;
  }
  .img-add-card {
    height: 143px !important;
  }
}
.img-add-card {
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 2px !important ;
  border-bottom-right-radius: 2px !important ;
}
@media only screen and (max-width: 575.5px) {
  .add-card {
    max-width: 93%;
    text-align: right;
    height: 27.66vw;
  }
  .img-add-card {
    width: 100%;
    height: 27.13vw;
  }

} */

/* Login */
.S9gUrf-YoZ4jf > iframe {
  display: none;
}
.S9gUrf-YoZ4jf > iframe > html {
  display: none;
}
#signInId > div > div > div {
  width: 384.4px;
  height: 54.7px;
  border: solid 1px #c7c7c7;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 0 0 120px;
  color: #005589;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 11px !important;
}
#signInId > div > div > div > div:last-child,
.qJTHM > div > div > div > div:last-child {
  direction: ltr;
  width: inherit;
  display: flex;
  justify-content: center;
}
.qJTHM {
  display: none !important;
}

.nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: IRANSansWeb !important;
  max-width: fit-content;
  font-weight: bold !important;
  font-size: 15px;
}
.LgbsSe-Bz112c,
.nsm7Bb-HzV7m-LgbsSe-Bz112c {
  width: 33.5px !important;
  height: 33.5px !important;
  margin: 0 9.4px 0 0;
}

/*  */
.K4efff,
.n1UuX-DkfjY,
.fmcmS {
  display: none !important;
}
.color-515151 {
  color: #005589;
}

.color-8d8c8c {
  color: #005589;
}
.submit-login-btn:disabled {
  background-color: rgb(138, 136, 136);
}

@media only screen and (min-width: 767.5px) {
  .login-container {
    width: 510px;
    min-height: 640px;
    height: fit-content;
    box-shadow: 0 3px 6px 0 rgba(179, 179, 179, 0.16);
    border: solid 1px #cacaca;
    border-radius: 6px;
    margin: 30px 0 140px 0;
  }

  .login-container-forget {
    min-height: 419px;
    padding-top: 35px;
    padding-bottom: 40px;
  }

  .logo-login-lap {
    width: 150px;
    height: auto;
  }

  .login-matn1 {
    font-size: 25px;
    /* font-size: 29px; */
    /* padding: 0 22px 8px 22px; */
  }

  .login-matn2 {
    /* letter-spacing: 0.35px; */
    font-size: 15px;
    margin-bottom: 23px !important;
    /* padding: 0 22px 8px 22px; */
  }

  .account-login-btn {
    width: 384.4px;
    height: 54.7px;
    border: solid 1px #c7c7c7;
    border-radius: 6px;
    background-color: #fff;
    /* padding: 0 0 0 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #005589;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 11px !important;
  }

  .account-login-btn:hover,
  .account-login-btn:active,
  .account-login-btn:focus {
    background-color: #fff;
    color: #005589;
    border: solid 1px #c7c7c7;
    box-shadow: 0 2px 6px -1px rgb(0 0 0 / 9%), 0 6px 18px -1px rgb(0 0 0 / 3%) !important;
  }

  .account-login-img {
    width: 33.5px;
    height: 33.5px;
    margin: 0 9.4px 0 0;
  }

  .divider-login {
    width: 383.4px;
    margin: 22px 0 31px 0;
  }

  .divider-login:after,
  .divider-login:before {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #707070;
  }

  .divider-login-text {
    color: #005589;
    margin: 0 16px;
    font-size: 15px;
    letter-spacing: -0.5px;
  }

  .input-login {
    width: 384px;
    height: 47px;
    border: solid 1px #c7c7c7;
    border-radius: 6px;
    align-items: center !important;
    padding: 0 16px 0 16px !important;
  }

  .input-login:focus {
    border: solid 1px #33e0a0;
  }

  .input-login::placeholder {
    color: rgba(67, 67, 67, 0.48) !important;
    font-size: 15px !important;
    /* padding-bottom: 10px ; */
    text-align: right;
  }

  .input-login:focus-visible {
    outline: none;
  }

  .login-matn3 {
    display: block;
    /* margin-right: 49px; */
    font-size: 15px;
    width: fit-content;
    margin-top: 11px;
    /* letter-spacing: -0.5px; */
  }

  .submit-login-btn {
    width: 384px;
    height: 49.4px;
    font-size: 15px !important;
    padding: 0px;
    margin-top: 30px;
    margin-bottom: 15px;
    border-radius: 6px;
    background-color: #33e0a0;
    color: #005589;
  }

  .color-0c0c0c {
    color: #005589;
  }

  .color-242b2e {
    color: #005589;
  }

  .color-0c0c0c:hover,
  .color-0c0c0c:active,
  .color-0c0c0c:focus,
  .submit-login-btn:hover,
  .submit-login-btn:active,
  .submit-login-btn:focus {
    background-color: #33e0a0;
    color: #005589;
  }

  .input-error {
    color: #f82020;
    font-size: 12px;
    letter-spacing: -0.7px;
    text-align: start !important;
    display: block;
    margin-right: 50px;
  }

  .border-error {
    border: solid 1px #f82020 !important;
  }

  .login-close-img {
    width: 19px;
    height: 19px;
  }

  .a-close-img {
    height: 21px;
    width: 21px;
    padding: 0px;
    margin-right: 16px;
    margin-top: 14px;
  }
  .show-pass-btn {
    position: absolute !important;
    top: calc(50% - 10px);
    left: 65px;
  }
  .input-login-pass {
    padding-left: 48px !important;
  }
}
.show-pass-btn svg,
.show-pass-btn-change-pass svg {
  margin: 0px !important;
}

.rightt {
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align: -ms-right;
}

.leftt {
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
  text-align: -ms-left;
}

.logo-login-a {
  width: fit-content;
  height: fit-content;
  margin-top: 35px !important;
  display: block;
}

/* forget pass */
.logo-forget-pass {
  margin-top: 41px !important;
}

.login-matn3-div {
  width: 384.4px;
}
.show-pass-btn-change-pass {
  position: absolute !important;
  top: calc(50% - 17px);
  left: 64px;
}
.show-pass-btn-change-pass-dash {
  position: absolute !important;
  top: calc(50% - 15px);
  left: 9px;
}

@media only screen and (max-width: 767.5px) {
  .logo-login-a {
    margin-top: 15px !important;
  }

  .login-container-forget {
    min-height: 437px;
    padding-top: 0px;
    padding-bottom: 40px;
  }

  .mb-forget-pass {
    /* margin-bottom: 200px !important; */
  }

  .fs-xss-19 {
    font-size: 19px !important;
  }

  .fs-xss-21 {
    font-size: 21px !important;
  }

  .fs-xss-14 {
    font-size: 14px !important;
  }

  .fs-xss-13 {
    font-size: 13px !important;
  }

  .fs-xss-12 {
    font-size: 12px !important;
  }

  .fs-xss-11 {
    font-size: 11px !important;
  }

  .padding-xss-32 {
    padding: 0 32px;
    width: 100%;
    max-width: 375px;
    margin: auto;
  }

  .logo-login {
    width: 82px;
    height: auto;
    margin-top: 15px;
  }

  .login-matn2 {
    letter-spacing: 0.35px;
    padding: 0 22px 8px 22px;
  }

  .account-login-btn {
    max-width: 311px;
    width: 100%;
    height: 43px;
    border: solid 1px #c7c7c7;
    border-radius: 6px;
    background-color: #fff;
    /* padding: 0 0 0 95px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #005589;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 8px !important;
  }

  .account-login-btn:hover,
  .account-login-btn:active,
  .account-login-btn:focus {
    background-color: #fff;
    color: #005589;
    border: solid 1px #c7c7c7;
    box-shadow: 0 2px 6px -1px rgb(0 0 0 / 9%), 0 6px 18px -1px rgb(0 0 0 / 3%) !important;
  }

  .account-login-img {
    width: 28px;
    height: 28px;
    margin: 0px 6px 0px 0px;
  }

  .divider-login {
    max-width: 311px;
    width: 100%;
    margin: 29px 0 26px 0;
  }

  .divider-login:after,
  .divider-login:before {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #707070;
  }

  .divider-login-text {
    color: #005589;
    margin: 0 2.53vw;
    letter-spacing: -0.3px;
  }

  .input-login {
    max-width: 311px;
    width: 100%;
    height: 36px;
    border: solid 1px #c7c7c7;
    border-radius: 6px;
    align-items: center !important;
    padding: 0 13px 0 13px !important;
  }

  .input-login:focus {
    border: solid 1px #33e0a0;
  }

  .input-login::placeholder {
    color: rgba(67, 67, 67, 0.48) !important;
    font-size: 12px !important;
    text-align: right;
  }

  .input-login:focus-visible {
    outline: none;
  }

  .login-matn3-div {
    max-width: 311px;
    width: 100%;
    margin: 0 auto;
  }

  .login-matn3 {
    display: block;
    width: fit-content;
    text-align: end;
    font-size: 14px;
    font-stretch: expanded;
    letter-spacing: -0.5px;
    margin-top: 5px;
  }

  .submit-login-btn {
    font-size: 16px !important;
    padding: 0px;
    margin-top: 35px;
    margin-bottom: 12px;
    max-width: 311px;
    width: 100%;
    height: 38px;
    border-radius: 6px;
    background-color: #33e0a0;
    color: #005589;
  }

  .color-0c0c0c {
    color: #005589;
  }

  .color-242b2e {
    color: #005589;
  }

  .color-0c0c0c:hover,
  .color-0c0c0c:active,
  .color-0c0c0c:focus,
  .submit-login-btn:hover,
  .submit-login-btn:active,
  .submit-login-btn:focus {
    background-color: #33e0a0;
    color: #005589;
  }

  .input-error {
    color: #f82020;
    font-size: 12px;
    letter-spacing: -0.7px;
    display: block;
    text-align: start !important;
  }

  .border-error {
    border: solid 1px #f82020 !important;
  }

  .login-close-img {
    width: 13px;
    height: 13px;
    margin-right: 12px;
    margin-top: 12px;
  }

  .a-close-img {
    width: 3.47vw;
  }
  .show-pass-btn {
    position: absolute !important;
    top: calc(50% - 10px);
    left: 10px;
  }
  .input-login-pass {
    padding-left: 44px !important;
  }
  .show-pass-btn-change-pass {
    position: absolute !important;
    top: calc(50% - 14px);
    left: 8px;
  }
  .show-pass-btn-change-pass-dash {
    position: absolute !important;
    top: calc(50% - 15px);
    left: 8px;
  }
}

/* Introduce Business Page */
.introduce-text1 {
  font-size: 32px;
  font-weight: bold;
  color: #005589;
  letter-spacing: -1.25px;
  margin-top: 40px;
  margin-bottom: 0px;
}

.introduce-text2 {
  font-size: 24px;
  color: #005589;
  word-spacing: 0.5px;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
}

.introduce-text3 {
  font-size: 20px;
  /* letter-spacing: -0.88px; */
  /* margin-top: 59px; */
  color: #005589;
  margin-top: 60px;
}

.introduce-text4 {
  font-size: 24px;
  /* letter-spacing: -1.35px; */
  color: #1d1d1d;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 10px;
}

.introduce-text5 {
  font-size: 19px;
  line-height: 1.41;
  text-align: center;
  color: #005589;
  margin-top: 40px;
  margin-bottom: 2px;
}

.introduce-text6 {
  font-size: 19px;
  line-height: 1.41;
  text-align: center;
  color: #005589;
  margin-bottom: 8px;
}

.introduce-input1 {
  width: 530px;
  display: block;
  height: 54px;
  font-size: 16px;
  padding: 0 19px 0 19px;
  border-radius: 6px;
  border: solid 1px #dadada;
  background-color: #fff;
  margin-top: 12px;
}

.introduce-input1:focus {
  border: solid 1px #33e0a0;
}

input.introduce-placeholder::placeholder {
  color: #cacaca;
  direction: rtl;
  text-align: right;
}
input.introduce-placeholder.font-family-en::placeholder {
  color: #cacaca;
  direction: ltr;
  text-align: left;
}
input.introduce-placeholder.font-family-en.text-right::placeholder {
  text-align: right;
}

.introduce-input1:focus,
.introduce-input1:focus-visible {
  outline: none !important;
}

.introduce-add1 {
  width: 530px;
  margin-top: 12px;
}

.introduce-add {
  display: block;
  width: 21px;
  height: 21px;
  border: none;
  float: left;
  border-radius: 6px;
  background-color: #33e0a0;
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 0px;
}

.introduce-add-img {
  width: 21px;
  height: 21px;
  display: block;
}

.introduce-sabt-btn {
  width: 118px;
  height: 43.5px;
  border: none;
  border-radius: 6px;
  padding: 0px;
  font-size: 21px;
  background-color: #33e0a0;
  color: #005589;
  margin: 40px 0px 145px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* display: block; */
}

.introduce-sabt-btn:hover,
.introduce-sabt-btn:focus,
.introduce-sabt-btn:active {
  background-color: #33e0a0;
  color: #005589;
}

.introduce-group {
  width: 530px;
  box-shadow: none;
  justify-content: space-between;
  display: flex;
}

.introduce-group:hover,
.introduce-group:focus,
.introduce-group:active {
  box-shadow: none;
}

.introduce-group-btn label {
  width: 122px;
  height: 47px;
  box-shadow: none;
  border-radius: 6px;
  border: solid 1px #cbcbcb !important;
  background-color: #fff !important;
  padding: 0px;
  font-size: 16px;
  color: #005589 !important;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introduce-group-btn > label > div > .digar-img {
  width: 16px;
  height: 15px;
  background-image: url("../../images/green-add.png");
  background-size: 14px;
  margin-right: 3px;
  background-repeat: no-repeat;
}

.btn-check:checked + .btn-primary div > .digar-img {
  width: 16px;
  height: 15px;
  background-image: url("../../images/black-add.png");
  background-size: 14px;
  margin-right: 3px;
  background-repeat: no-repeat;
}

.introduce-group-btn:hover label,
.introduce-group-btn:focus label,
.introduce-group-btn:focus-visible label {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 7%), 0 6px 18px -1px rgb(0 0 0 / 4%) !important;

  color: #005589;
}

.btn-check:checked + .btn-primary {
  box-shadow: none;
  background-color: #c9b2f8 !important;
  border: solid 0px #cbcbcb !important;
  color: #005589;
}

/* .introduce-group-btn label img {
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px;
} */
input.introduce-placeholder::placeholder {
  color: #dadada;
}

.ib-input-error {
  width: 530px;
  color: #f82020;
  font-size: 14px;
  letter-spacing: -0.7px;
  text-align: start !important;
  display: block;
}

.ib-3input {
  width: 530px;
  margin-top: 12px;
}

@media only screen and (max-width: 767.5px) {
  .ib-input-error {
    color: #f82020;
    font-size: 12px;
    width: 86%;
    letter-spacing: -0.7px;
    display: block;
    text-align: start !important;
  }

  .introduce-group-btn > label > div > .digar-img {
    width: 3.19vw !important;
    height: 3.1vw !important;
    background-size: 3.19vw;
    margin-right: 5px;
  }

  .btn-check:checked + .btn-primary div > .digar-img {
    width: 3.19vw !important;
    height: 3.1vw !important;
    background-size: 3.19vw;
    margin-right: 5px;
  }

  .introduce-add-img {
    width: 16px;
    height: 16px;
  }

  .introduce-text1 {
    font-size: 21px;
    letter-spacing: 0px;
    margin-top: 21px;
  }

  .introduce-text2 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .introduce-text3 {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 62px;
  }

  .introduce-text4 {
    font-size: 17px;
    margin-top: 49px;
    margin-bottom: 16px;
  }

  .introduce-text5 {
    font-size: 16px;
    font-weight: bold;
    /* line-height: 1.41; */
    margin-top: 27px;
    margin-bottom: 4px;
  }

  .introduce-text6 {
    font-size: 14px;
    color: #535353;
    margin-bottom: 10px;
  }

  .introduce-input1 {
    width: 86%;
    height: 42px;
    padding: 0 15px 0 15px;
    margin-top: 8px;
  }

  input.introduce-placeholder::placeholder {
    font-size: 12px;
  }

  .introduce-add1 {
    width: 86%;
    margin-top: 8px;
  }

  .introduce-add {
    display: block;
    width: 16px;
    height: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .introduce-sabt-btn {
    width: 78px;
    height: 30px;
    font-size: 14px;
    margin: 29px 0px 39px 0px;
  }

  .introduce-group {
    width: 80.7vw;
  }

  .introduce-group-btn label {
    width: 18.64vw;
    height: 7.99vw;
    font-size: 3.46vw;
    margin-top: 7px;
  }

  .ib-3input {
    width: 86% !important;
    margin-top: 8px;
  }
}

/* .aaa#a.b.c{
  background-color: red;
} */
/* input auto 6 */

.auto6 > .MuiAutocomplete-root {
  width: 530px;
  /* height: 58px; */
}

.auto6 > div > div > div > div > .MuiAutocomplete-endAdornment {
  position: initial;
}

/* .auto6 > div > div > div > div > .MuiOutlinedInput-notchedOutline, */
.auto6 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  width: 530px;
  height: 54px;
  border-radius: 6px;
  border: solid 1px #dadada !important;
  position: absolute;
  top: 0px;
}

.auto3 > div > div > div > div.Mui-focused > fieldset,
.auto6 > div > div > div > div.Mui-focused > fieldset,
.auto8 > div > div > div > div.Mui-focused > fieldset,
.custom-text-field > div.Mui-focused > fieldset {
  border: solid 1px #33e0a0 !important;
}

.auto6,
.auto3 {
  width: fit-content;
}

.auto3 > .MuiAutocomplete-popper,
.auto6 > .MuiAutocomplete-popper {
  /* transform: translate3d(0px, 50px, 0px) !important; */
  /* transform:translate3d(0px, 990.5px, 0px) !important; ; */
  /* display: contents; */
  /* width: 569px; */
  /* left: 0px; */
  /* margin: 0px; */
  /* transform: translate3d(458.5px, 990.5px, 0px); */
  /* inset: 0px 0px 0px 0px; */
  padding: 0px 0px !important;
}

.auto3 > div > .MuiAutocomplete-paper,
.auto6 > div > .MuiAutocomplete-paper {
  width: inherit;
  /* position: relative; */
  position: fixed;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #dcf8ed !important;
}

.MuiAutocomplete-option:hover {
  background-color: #dcf8ed !important;
}

.auto6>div>div>div>.MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto6>div>div>div>.MuiAutocomplete-inputRoot {
  height: 54px;
  padding: 0 15px 0 5px !important;
}

.auto6
  > div
  > div
  > div
  > div
  > div
  > .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.auto6 > div > div > div > div > div > .css-1agnrrp {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg) !important;
  -ms-transform: rotate(270deg) !important;
  transform: rotate(270deg) !important;
}

/* .auto6 > div > div > div > div > .MuiAutocomplete-input::placeholder,
.auto6 > div > div > div > div > .MuiAutocomplete-input {
   font-size: 16px !important ;
} */
/* .auto6 > div > div > div > div > .MuiAutocomplete-input::placeholder {
  color: #cacaca;
} */

.icon-ib {
  width: 22px;
  height: 22px;
}
/* auto options */
#auto3-listbox > .MuiAutocomplete-option,
#auto4-listbox > .MuiAutocomplete-option,
#auto5-listbox > .MuiAutocomplete-option {
  text-align: left;
}
ul#auto3-listbox,
ul#auto4-listbox,
ul#auto5-listbox,
ul#auto6-listbox,
ul#auto-koja-listbox {
  max-height: 216px;
  padding: 0;
}

@media only screen and (max-width: 767.5px) {
  ul#auto3-listbox,
  ul#auto4-listbox,
  ul#auto5-listbox,
  ul#auto6-listbox,
  ul#auto-koja-listbox {
    max-height: 214px;
  }

  .auto6 {
    width: 86%;
  }

  .auto6 div > div > ul > .MuiAutocomplete-option {
    padding: 8.5px 4px;
    font-size: 12px;
    border-bottom: 0.35px solid #dfdfdf;
    min-height: unset;
  }

  .auto6 > .MuiAutocomplete-root {
    width: 100% !important;
    height: 42px;
  }

  .auto6 > div > div > div > div > .MuiAutocomplete-endAdornment {
    position: initial;
  }

  .auto6 > div > div > div > div > .MuiOutlinedInput-notchedOutline,
  .auto6 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
    width: 100%;
    height: 42px;
    border-radius: 6px;
    border: solid 1px #dadada !important;
    position: absolute;
    top: 0px;
  }

  .auto6>div>div>div>.MuiOutlinedInput-root,
  /* .MuiInputBase-root, */
  .auto6>div>div>div>.MuiAutocomplete-inputRoot {
    /* padding: 0px; */
    height: 42px;
    width: 100%;
    padding: 0 15px 0 5px !important;
  }

  .icon-ib {
    width: 15px;
    height: 15px;
  }
}

.auto3 > .MuiAutocomplete-root {
  width: 172px;
  /* height: 58px; */
}

.auto3 > div > div > div > div > .MuiAutocomplete-endAdornment {
  position: initial;
}

.auto3 > div > div > div > div > .MuiOutlinedInput-notchedOutline,
.auto3 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  width: 172px;
  height: 54px;
  border-radius: 6px;
  /* border: solid 1px #dadada !important ; */
  position: absolute;
  top: 0px;
}

.auto-error > div > .MuiOutlinedInput-notchedOutline {
  border: solid 1px red !important;
}

.auto-bord > div > .MuiOutlinedInput-notchedOutline {
  border: solid 1px #dadada !important;
}

.auto3>div>div>div>.MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto3>div>div>div>.MuiAutocomplete-inputRoot {
  height: 54px;
  padding: 0 15px 0 5px !important;
}

.auto3
  > div
  > div
  > div
  > div
  > div
  > .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.auto3 > div > div > div > div > div > .css-1agnrrp {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg) !important;
  -ms-transform: rotate(270deg) !important;
  transform: rotate(270deg) !important;
}

/* .auto3 > div > div > div > div > .MuiAutocomplete-input::placeholder,
.auto3 > div > div > div > div > .MuiAutocomplete-input {
  font-size: 16px !important ;
} */
/* .auto3 > div > div > div > div > .MuiAutocomplete-input::placeholder {
  color: #dadada;
} */

@media only screen and (max-width: 767.5px) {
  .auto3 div > div > div > div > input::placeholder {
    opacity: 0.3;
    font-size: 13px;
  }

  .auto3 div > div > ul > .MuiAutocomplete-option {
    padding: 8.5px 4px;
    font-size: 12px;
    border-bottom: 0.35px solid #dfdfdf;
    min-height: unset;
  }

  .auto3 > .MuiAutocomplete-root {
    width: 25.6vw !important;
    height: 42px;
    /* height: 58px; */
  }

  .auto3 > div > div > div > div > .MuiAutocomplete-endAdornment {
    position: initial;
  }

  .auto3 > div > div > div > div > .MuiOutlinedInput-notchedOutline,
  .auto3 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
    width: 25.6vw;
    height: 42px;
    border-radius: 6px;
    /* border: solid 1px #dadada !important ; */
    position: absolute;
    top: 0px;
  }

  .auto-error > div > .MuiOutlinedInput-notchedOutline {
    border: solid 1px red !important;
  }

  .auto-bord > div > .MuiOutlinedInput-notchedOutline {
    border: solid 1px #dadada !important;
  }

  .auto3>div>div>div>.MuiOutlinedInput-root,
  /* .MuiInputBase-root, */
  .auto3>div>div>div>.MuiAutocomplete-inputRoot {
    height: 42px;
    width: 25.6vw;
    padding: 0 15px 0 5px !important;
  }

  .auto6 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar,
  .auto3 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 8px;
    background-color: #dcdcdc;
  }
}

/* Introduce Business Confirm */
.ib-confirm-box {
  width: 586px;
  height: fit-content;
  margin: 85px 0px 145px 0px;
  border-radius: 6px;
  background-color: #f3f3f3;
}

.ib-confirm-box2 {
  padding: 58px 51px 37px 51px;
  font-size: 19px;
  color: #005589;
}

.ib-confirm-tit {
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  color: #005589;
  margin-bottom: 50px;
}

.ib-confirm-ename {
  font-size: 25px;
}

.ib-confirm-pname {
  font-size: 21px;
}

.ib-sabt-a2 {
  width: 171px;
  height: 43px;
  margin-top: 123px;
  border: solid 1px #e8e3e3;
  border-radius: 6px;
  background-color: #33e0a0;
  font-size: 17px;
  text-align: right;
  color: #005589;
  text-align: center;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ib-sabt-a2:hover,
.ib-sabt-a2:active,
.ib-sabt-a2:focus {
  border: solid 1px #e8e3e3;
  background-color: #33e0a0;
  color: #005589;
}

.ib-sabt-a1 {
  width: 108px;
  height: 43px;
  margin-top: 123px;
  border: solid 1px #e8e3e3;
  border-radius: 6px;
  background-color: #fff;
  font-size: 17px;
  text-align: right;
  color: #005589;
  text-align: center;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ib-sabt-a1:hover,
.ib-sabt-a1:active,
.ib-sabt-a1:focus {
  border: solid 1px #e8e3e3;
  background-color: #fff;
  color: #005589;
}

/* .sabtnam-bottom{
  position: relative;
  bottom: -88px;
} */
@media only screen and (max-width: 767.5px) {
  .ib-confirm-box {
    width: 80.53vw;
    /* min-height: 91.86vw; */
    height: fit-content;
    margin: 50px 0px 50px 0px;
  }

  .ib-confirm-box2 {
    padding: 42px 33px 0px 33px;
    font-size: 10px;
    height: fit-content;
  }

  .ib-confirm-tit {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 22px;
  }

  .ib-confirm-ename {
    font-size: 13px;
  }

  .ib-confirm-pname {
    font-size: 11px;
  }

  .ib-sabt-a2 {
    width: 102px;
    height: 26px;
    font-size: 11px;
    margin-top: 61px;
    margin-bottom: 37px;
  }

  .ib-sabt-a1 {
    width: 69px;
    height: 26px;
    font-size: 11px;
    margin-top: 61px;
    margin-bottom: 37px;
  }
}

/*  INTRODUCE BUSINESS THANKS PAGE */
.ibt-logo {
  width: 140px;
}

.ibt-logo-a {
  margin-top: 90px;
  display: block;
}

.ibt-box {
  width: 562px;
  /* height: 435px; */
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #c9b2f8;
  margin: 40px 0 0 0;
  padding: 0 0 40px 0;
}

.ibt-sabt-at {
  width: 425px;
  height: 68px;
  margin: 40px 0px 140px 0px;
  border-radius: 6px;
  border: solid 1px #33e0a0;
  background-color: #fff;
  font-size: 22px;
  text-align: center;
  color: #005589;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ibt-sabt-at:hover {
  color: #005589;
}

.ibt-close-img {
  width: 21px;
  margin-top: 20px;
  margin-right: 20px !important;
  height: 21px;
}

.ibt-text1 {
  font-size: 35px;
  font-weight: bold;
  color: #005589;
  margin-top: 24px;
  margin-bottom: 53px;
}

.ibt-text2 {
  font-size: 25px;
  color: #005589;
  margin: 0 34px 20px 34px;
}

@media only screen and (max-width: 767.5px) {
  .ibt-logo {
    width: 80px;
  }

  .ibt-close-img {
    width: 13px;
    height: 13px;
    margin: 11px 11px 0 0 !important;
  }

  .ibt-box {
    width: 100%;
    max-width: 311px;
    min-height: 224px;
    height: fit-content;
    margin-top: 50px;
  }

  .ibt-sabt-at {
    max-width: 311px;
    width: 100%;
    height: 44px;
    margin: 28px 0px 140px 0px;
    border: solid 1px #d2d1d1;
    font-size: 15px;
  }

  .ibt-text1 {
    font-size: 18px;
    margin-top: 19px;
    margin-bottom: 15px;
  }

  .ibt-text2 {
    font-size: 14px;
    margin: 0 24px 10px 24px;
  }
}

/* Header2 */

.header2 {
  /* height: 149px; */
  height: fit-content;
  box-shadow: 0 2px 3px 0 rgba(129, 129, 129, 0.16);
  /* background-color: #dedbdb; */
  /* margin-bottom: 16px; */
}

.header2-container {
  padding: 32px 7px 16px 48px;
}

#first-input-what-header2::placeholder {
  font-size: 14px;
}

.header2-list {
  margin-top: 21px;
  /* width: 266px; */
  margin-right: 228px;
}

.header2-list-a {
  margin-left: 32px;
  font-size: 16px;
  color: #005589;
}

.header2-list-a:hover {
  color: #33e0a0;
}

.bg-head2-btn {
  color: #005589 !important;
  background-color: #ededed;
  border: none;
}

.bg-head2-btn:hover,
.bg-head2-btn:active,
.bg-head2-btn:focus {
  background-color: #ededed;
  box-shadow: 0 4px 7px 0 rgb(0 0 0 / 4%), 0 4px 15px 0 rgb(0 0 0 / 3%) !important;
  color: #005589 !important;
  border: none;
}

.color-text1-header2 {
  color: #33e0a0 !important;
  font-size: 20px;
}

.color-text1-header2:hover,
.color-text1-header2:active,
.color-text1-header2:focus {
  color: #00000069 !important;
}

.color-text2-header2 {
  color: #33e0a0 !important;
}

.color-text2-header2:hover,
.color-text2-header2:active,
.color-text2-header2:focus {
  color: #00000069 !important;
}

.logo-header2-a {
  width: fit-content;
  margin-right: 48px;
}

.logo-header2-lap {
  width: 150px;
  height: auto;
  /* margin-top: 135px; */
}

.first-input-header2 {
  width: 100% !important;
  height: 50px !important;
  padding: 0px;
}

.search-img-header2 {
  width: 27px;
  height: 27px;
  margin: 0px 2px;
}

.search-submit-btn-head2 {
  width: 56px;
  height: 50.2px;
}
.auto-what-head2 > .MuiAutocomplete-root {
  width: 100% !important;
}
.auto-what-head2.auto-g > div > .MuiAutocomplete-paper {
  width: 40.5vw !important;
}
/* .auto-what-head2 .auto-g > div > .MuiAutocomplete-paper */

.width-533px {
  margin: -27px 40px;
  width: fit-content !important;
}

.searchinput-header2 {
  border-radius: 6px;
  direction: rtl;
  width: 40.5vw;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 22px;
  /* z-index: 2; */
  /* overflow: hidden; */
}

.z2 {
  z-index: 3;
  position: relative;
}

.z3 {
  z-index: 4;
  position: absolute;
  right: 22px;
}

.header2-box {
  background-color: #fff;
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  /* box-shadow: 0 4px 4px rgb(0 0 0 / 25%); */
  margin: 0;
  max-width: 40.5vw;
  width: 40.5vw;
}

/* whole background */
.business-bg-color {
  background: #fff;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  /* transition: opacity .15s ease; */
  opacity: 0.9;
  pointer-events: auto;
  z-index: 2;
}

.where-input-header2 {
  height: 50px;
  border: 1px solid #c5c5c5;
  border-radius: 0 6px 6px 0;
  width: 100%;
  padding: 0;
  margin: 10px 0;

  display: flex;
  align-items: center;
  background-color: white;
}

.input-where-header2 {
  width: 100%;
  margin: 10px 0;
  padding: 0 14px;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  height: 50px;
}

.input-where-header2::placeholder {
  color: #c8c8c8;
  font-weight: 100;
}

.input-where-header2:focus-visible {
  outline: none;
}

.auto7 > .MuiAutocomplete-root {
  width: 40.5vw;
  /* height: 58px; */
}

.auto7 > div > div > div > div > .MuiAutocomplete-endAdornment {
  position: initial;
}

/* .auto7 > div > div > div > div > .MuiOutlinedInput-notchedOutline, */
.auto7 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  width: 40.5vw;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #c5c5c5 !important;
  position: absolute;
  top: 0px;
}
.auto-what-head2 div > div > div > div > .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.auto7 {
  width: fit-content;
}

.auto7 > .MuiAutocomplete-popper {
  /* transform: translate3d(0px, 50px, 0px) !important; */
  /* transform:translate3d(0px, 990.5px, 0px) !important; ; */
  /* display: contents; */
  /* width: 569px; */
  /* left: 0px; */
  /* margin: 0px; */
  /* transform: translate3d(458.5px, 990.5px, 0px); */
  /* inset: 0px 0px 0px 0px; */
  padding: 0px 0px !important;
}

.auto7 > div > .MuiAutocomplete-paper {
  width: inherit;
  /* position: relative; */
  position: fixed;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #dcf8ed !important;
}

.MuiAutocomplete-option:hover {
  background-color: #dcf8ed !important;
}

.auto7>div>div>div>.MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto7>div>div>div>.MuiAutocomplete-inputRoot {
  height: 50px;
  padding: 0 5px 0 15px !important;
}

.auto7 > div > div > div > div > .MuiAutocomplete-input::placeholder,
.auto7 > div > div > div > div > .MuiAutocomplete-input {
  font-size: 16px !important;
}

/* .auto7 > div > div > div > div > .MuiAutocomplete-input::placeholder {
  color: #cacaca;
} */

@media only screen and (max-width: 1138.5px) {
  .header2-container {
    padding: 32px 7px 16px 7px;
  }

  .width-533px {
    margin: -27px 15px;
  }

  .searchinput-header2 {
    margin-right: 24px;
  }

  .header2-list {
    margin-right: 175px;
  }
  .logo-header2-a {
    margin-right: 20px;
  }
  .header2-dialog > .MuiDialog-container {
    right: 241px !important;
  }

  .z3 {
    right: 24px;
  }

  .logo-header2-lap {
    width: 120px;
    height: auto;
    /* margin-top: 135px; */
  }
}

.width-header2-p {
  /* width: 37px !important; */
}

/* haeader2 dialog */
.header2-dialog > .MuiBackdrop-root {
  background-color: rgb(255 255 255 / 50%);
}

.header2-dialog > .MuiDialog-container {
  position: absolute;
  right: 319px;
  top: 32px;
  align-items: start;
}

.header2-dialog > div > .MuiPaper-root {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  max-width: 40.5vw;
  width: 40.5vw;
  margin: 0px;
  border-radius: 6px;
}

/* Business Page */
.business-text1 {
  width: fit-content;
  font-size: 12px;
  color: #005589;
  margin: 18px 0;
  display: block;
}

.business-text1:hover {
  color: #00000069;
}

.business-right-img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.bh-100 {
  height: 100%;
  position: relative;
  padding-right: 6px !important;
}

.business-card-right {
  min-height: 313px;
  height: fit-content;
  padding: 30.5px 16.5px 30.5px 28px;
  border-radius: 6px;
  background-color: #f7f7f7;
  margin: 0px;
}

.business-card-img {
  width: 252px;
  height: 252px;
  border-radius: 6px;
  object-fit: cover;
}

.business-card-title {
  font-size: 22px;
  font-weight: bold;
  color: #005589;
}

.biz-flag {
  width: 20px;
  height: 20px;
  margin-bottom: 14px;
}

.biz-tags {
  width: fit-content;
  height: 17px;
  padding: 0px 8px;
  margin: 0 0 5px 5px;
  border-radius: 6px;
  background-color: #6f98b1;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: -0.2px;
  text-align: center;
  color: #f7f7f7;
  display: block;
}
.biz-tags:last-child {
  margin-left: 0px;
}
.biz-tags-container {
  /* display: flex; */
  margin: 0px;
  width: 100%;
}
.biz-tags-div {
  position: absolute;
  bottom: 33px;
}
.business-card-text1 {
  font-size: 16px;
  color: #005589;
  margin-bottom: 3px;
  letter-spacing: -0.5px;
  /* margin-top: -32px; */
}

.business-card-text2 {
  font-size: 16px;
  line-height: 1.25;
  color: #005589;
  letter-spacing: -0.6px;
}

.business-card-text3 {
  position: absolute;
  left: 0px;
  bottom: -8px;
  height: fit-content;
  /* position: relative; */
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  padding-right: 6px;
}

.business-card-icons-hov {
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* .business-card-icons-hov:hover {
  background-color: #e7e7e7;
} */
.business-card-icon1 {
  width: 18px;
  height: 23px;
}

.business-card-icon2 {
  width: 22px;
  height: 20px;
  cursor: pointer;
}

.business-card-icon3 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.business-upload-btn {
  width: 151px;
  height: 48px;
  margin-top: 18px;
  border-radius: 6px;
  border: solid 1px #d1d1d1;
  font-size: 14px;
  color: #005589;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-upload-btn-icon {
  width: 26px;
  height: 26px;
  margin: 0 14.3px 0 0;
}

.business-write-btn {
  width: 154px;
  height: 48px;
  margin-top: 18px;
  /* margin-right: 16px; */
  border-radius: 6px;
  border: solid 1px #d1d1d1;
  font-size: 14px;
  color: #005589;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.business-write-btn-icon {
  width: 25px;
  height: 23px;
  margin: 0 14.3px 0 0;
}

.business-edit-btn {
  width: 264px;
  height: 48px;
  margin-top: 18px;
  border-radius: 6px;
  border: solid 1px #d1d1d1;
  background-color: #b294f2;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-edit-btn:hover {
  color: #fff;
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 18%), 0 6px 18px -1px rgb(0 0 0 / 6%) !important;
}

.business-upload-btn:hover,
.business-write-btn:hover {
  color: #005589;
}

.business-intro {
  min-height: 106px;
  height: fit-content;
  margin: 16px 0 0 0;
  padding: 10px 18px;
  border-radius: 6px;
  border: solid 1px #c0c0c0;
  font-size: 16px;
  color: #005589;
  text-align: justify;
}

/*  */
/* .business-ownership {
 width: 352px; 
  height: 169px;
  margin-top: 16px;
  padding: 13px 0 27px 0;
  border-radius: 6px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
} */
.business-ownership {
  /* width: 352px; */
  height: 169px;
  margin-top: 16px;
  padding: 0px 2px 0px 2px;
  border-radius: 6px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.business-ownership-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 4px;
}

.business-ownership-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 119px;
  height: 33px;
  border-radius: 6px;
  background-color: #b294f2;
  font-size: 14px;
  /* letter-spacing: -0.35px; */
  color: #fff;
  font-weight: bold;
}

.business-ownership-btn:hover {
  color: #fff;
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 22%), 0 6px 18px -1px rgb(0 0 0 / 8%) !important;
}

.business-ownership-text {
  margin-top: 9px;
  margin-bottom: 0px;
  font-size: 18px;
  letter-spacing: -0.41px;
  color: #005589;
}

.business-ownership-text2 {
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: -0.32px;
  text-align: center;
  color: #005589;
  margin-bottom: 12px;
}

.business-ownership-text3 {
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.6px;
  text-align: center;
  color: #005589;
  margin-bottom: 4px;
}

.business-ownership-btn2 {
  width: 209px;
  height: 33px;
  /* margin: 2px 52px 0 64px; */
  padding: 0;
  border-radius: 6px;
  background-color: #005589;
  box-shadow: none;
}

.business-ownership-btn2:hover,
.business-ownership-btn2:active,
.business-ownership-btn2:focus {
  background-color: #005589;
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 18%), 0 6px 18px -1px rgb(0 0 0 / 6%) !important;
}

/*  */
.ml-n42 {
  margin-left: -42px;
}

.business-information {
  /* width: 352px; */
  min-height: 288px;
  height: fit-content;
  /* margin: 18px 16px 16px 139px; */
  padding: 27px 0 0 0;
  border-radius: 6px;
  background-color: #f7f7f7;
}

.business-information-1 {
  padding: 10px 16px 18px 30px;
  border-bottom: 1px solid #8ef0c0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.business-information-icon {
  width: 22px;
  height: 22px;
  margin-right: 18px;
}

.business-information-social {
  margin: 0 12px;
}

.business-information-social img {
  width: 20px;
  height: 20px;
}

.business-information-social-insta {
  /* background-image: url("../../images/business/social/instagram\ gray\(1\).png"); */
  background-size: 20px;
  background-repeat: no-repeat;
  margin: 0 12px;
  width: 20px;
  height: 20px;
}

.business-information-social-insta:hover {
  /* background-image: url("../../images/business/social/instagram.png"); */
}

.business-information-social-tel {
  /* background-image: url("../../images/business/social/telegram\ gray.png"); */
  background-size: 20px;
  background-repeat: no-repeat;
  margin: 0 12px;
  width: 20px;
  height: 20px;
}

.business-information-social-tel:hover {
  /* background-image: url("../../images/business/social/telegram.png"); */
}

.business-information-social-twit {
  /* background-image: url("../../images/business/social/twitter\ gray\ .png"); */
  background-size: 20px;
  background-repeat: no-repeat;
  margin: 0 12px;
  width: 20px;
  height: 20px;
}

.business-information-social-twit:hover {
  /* background-image: url("../../images/business/social/twitter.png"); */
}

.business-information-social-ytub {
  /* background-image: url("../../images/business/social/youtube\ gray\ \(1\).png"); */
  background-size: 20px;
  background-repeat: no-repeat;
  margin: 0 12px;
  width: 20px;
  height: 20px;
}

.business-information-social-ytub:hover {
  /* background-image: url("../../images/business/social/youtube.png"); */
}

.business-information-social-face {
  /* background-image: url("../../images/business/social/facebook\ \ gray\(1\).png"); */
  background-size: 20px;
  background-repeat: no-repeat;
  margin: 0 12px;
  width: 20px;
  height: 20px;
}

.business-information-social-face:hover {
  /* background-image: url("../../images/business/social/facebook.png"); */
}

.business-information-3 {
  display: flex;
  /* justify-content: center; */
  margin-left: 42px;
  padding: 0px !important;
  width: 100%;
}

.business-information-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.24px;
  color: #005589;
  margin: 0px;
  /* font-family: Roboto; */
  font-family: IRANSansWeb;
  cursor: pointer;
}

.business-information-text a {
  color: #005589;
}

.business-information-text a:hover,
a.business-information-text:hover {
  color: #0e52c1;
}
.business-information-text.business-information-text-disable {
  color: #67808f;
}
.business-information-text.business-information-text-disable a:hover,
a.business-information-text.business-information-text-disable:hover {
  color: #67808f;
  cursor: unset;
}
.business-information-no-address {
  margin: 0 4px 4px 0px;
  padding: 4px 16px 4px 16px;
  border-radius: 6px;
  background-color: #242b2e;
  width: fit-content;
  font-size: 13px;
  letter-spacing: -0.82px;
  text-align: right;
  color: #fefefe;
}

.business-information-img {
  width: 10px;
  height: 10px;
  margin-left: 6px;
}

.business-information-2 {
  padding: 0px 0 0px 14px;
}

.business-times {
  font-size: 13px;
  margin-bottom: 8px;
  color: #2c1264;
}

.business-times-closed {
  color: #f82020;
}

.business-times-day {
  display: block;
  width: 28px !important;
}

@media only screen and (min-width: 767.5px) {
  .business-facility-fix {
    position: sticky;
    top: 18px;
  }
}
.cursor-auto {
  cursor: auto !important;
}
.cursor-auto > span {
  cursor: auto !important;
}

.business-facility {
  /* width: 354px; */
  /* min-height: 169px; */
  height: fit-content;
  margin: 16px 0 0 0;
  padding: 6px 33px 10px 10px;
  background-color: #f7f7f7;
  border-radius: 6px;
}

.business-facility-title {
  margin: 0 0 8px 17px;
  font-size: 15px;
  font-weight: bold;
  color: #005589;
}

.business-facility-text {
  font-size: 14px;
  letter-spacing: -0.54px;
  color: #005589;
  margin: 0px 0 8px 0;
}

.business-facility-icon {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 7px;
}

.business-map {
  display: flex;
  justify-content: space-around;
  height: 169px;
  margin-top: 16px;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}

.business-map > div > div {
  border-radius: 6px;
  cursor: pointer;
}

.business-map-div {
  text-align: center;
  margin: 33px 0 0 12px;
  font-size: 14px;
  letter-spacing: -0.42px;
  color: #005589;
}

.business-map-img {
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
  color: #f82020;
}

.business-blog {
  margin-top: 36px;
  margin-bottom: 70px;
}

.tabliqat-box {
  width: auto;
  height: auto;
  border-radius: 6px;
}

.tabliqat-box a > img {
  width: 100%;
  height: inherit;
  border-radius: 6px;
}

.business-makan-position {
  position: absolute;
}

@media only screen and (max-width: 767.5px) {
  .minwidth-90px {
    /* min-width: 90px; */
    text-align: left;
  }
  .business-card-icons-hov {
    width: 34px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 12px;
  }

  .business-card-icons-hov:hover {
    background-color: #e7e7e7;
  }

  .business-padding {
    padding: 0;
  }

  .business-makan-position {
    position: inherit;
    margin-top: 16px;
  }

  .business-text1 {
    font-size: 12px;
    margin: 1px 22px 6px 0;
  }

  .business-card-right {
    min-height: fit-content;
    height: fit-content;
    padding: 25px 36.5px 5px 36.5px;
    margin: 0px;
  }

  .business-right-img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .bh-100 {
    height: 100%;
    position: relative;
    padding-right: 0px !important;
  }

  .business-card-img {
    width: 302.2px;
    height: 302px;
    margin-top: 9px;
  }

  .business-card-title {
    font-size: 21px;
    margin-bottom: 4px;
  }
  .biz-flag {
    width: 18px;
    height: 18px;
    /* margin-left: 8px; */
    margin-bottom: 0px;
  }

  .biz-tags {
    height: 15px;
    padding: 0px 8px;
    margin: 0 0 5px 5px;
    border-radius: 6px;
    background-color: #6f98b1;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    text-align: center;
    color: #f7f7f7;
    display: block;
  }
  .biz-tags-container {
    /* display: flex; */
    margin: 0px;
    width: 100%;
  }
  .biz-tags-div {
    position: unset;
    bottom: 0;
  }

  .business-card-text1 {
    font-size: 13px;
    margin-top: 0px;
    letter-spacing: -0.5px;
  }

  .business-card-text2 {
    font-size: 11px;
    line-height: 1.8;
    letter-spacing: -0.4px;
    /* position: absolute;
    top: 1px;
    left: 0; */
    margin-bottom: 0px;
  }

  .business-card-text3 {
    position: inherit;
    margin-top: 13px;
    padding-right: 0px;
  }

  .business-card-icon1 {
    width: 18px;
    height: 22px;
  }

  .business-card-icon2 {
    width: 21px;
    height: 20px;
  }

  .business-card-icon3 {
    width: 21px;
    height: 21px;
  }

  .business-card-icon4 {
    width: 21px;
    height: 21px;
  }

  .business-card-icon5 {
    width: 7.43vw;
    height: 7.43vw;
  }

  .business-edit-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 197px;
    height: 35px;
    border: none;
    border-radius: 6px;
    /* box-shadow: 1px 2px 1px 0 rgba(117, 117, 117, 0.16); */
    background-color: #c9b2f8;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .business-edit-responsive:hover {
    color: #fff;
    box-shadow: 0 2px 6px -1px rgb(0 0 0 / 18%), 0 6px 18px -1px rgb(0 0 0 / 6%) !important;
  }

  /*  */
  .business-intro {
    margin: 20px 0 0 0;
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .business-ownership {
    margin-top: 12px;
  }

  .business-ownership-icon {
    margin-bottom: 6px;
  }

  .ml-xs-n30 {
    margin-left: -30px !important;
  }

  .business-information {
    min-height: fit-content;
    height: fit-content;
    padding: 6px 0 0 0;
  }

  .business-information-1 {
    padding: 10px 40px 10px 40px;
  }

  .business-information-text {
    font-size: 15px;
    line-height: 1.33;
    /* letter-spacing: 0.21px; */
  }

  .business-information-no-address {
    margin: 0 4px 10px 0px;
  }

  .business-facility {
    min-height: fit-content;
    height: fit-content;
    margin: 37.5px 0 0 0;
    padding: 14px 33px 22px 10px;
    position: relative;
  }

  .business-facility-title {
    font-size: 15px;
    position: absolute;
    top: -30px;
  }

  .business-facility-text {
    font-size: 12px;
    display: flex;
  }

  .business-facility-icon {
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 10px;
  }

  .business-map {
    display: flex;
    height: 169px;
    margin-top: 12px;
  }

  .business-map-responsive {
    width: 151px !important;
    height: 124px !important;
    left: 8% !important;
  }

  .business-map-div {
    margin: 20px 0 0 8px;
    font-size: 14px;
  }

  .business-map-img {
    width: 25px;
    height: 25px;
  }

  .business-blog {
    margin-top: 14px;
    margin-bottom: 16px;
  }

  .tabliqat-box {
    max-width: 295px;
    width: 100%;
  }

  .business-accordion > div > .MuiButtonBase-root {
    padding-right: 40px;
    padding-left: 26px;
    min-height: 40px;
    height: fit-content;
  }

  .business-accordion
    > div
    > div
    > .MuiAccordionSummary-expandIconWrapper
    > svg {
    width: 11px;
    height: 11px;
  }

  /* dropdown business page */
  .business-drop-img {
    width: 18px !important;
    height: 18px !important;
    /* margin: 0 6px 0px 2px; */
  }

  /* .business-dropbtn {
/* } */

  .business-dropdown {
    position: relative;
  }

  .business-dropdown-content {
    bottom: 34px !important;
    left: 8px !important;
    min-width: 99px !important;
    width: max-content !important;
    z-index: 1;
  }

  .business-dropdown-content2 {
    left: 9px !important;
    min-width: 51px !important;
  }

  .business-dropdown-content3 {
    left: 6px !important;
    min-width: 51px !important;
  }

  .business-dropdown-content3 > a,
  .business-dropdown-content2 > a {
    text-align: center !important;
  }

  .business-dropdown-content > a {
    padding: 6px 4.5px !important;
    /* height: 30px; */
    font-size: 13px !important;
  }

  .bdi-lastt {
    border-bottom: 0px solid #c9c9c9 !important;
  }

  .business-dropdown-content a:hover {
    background-color: #f7f7f7;
  }

  .business-dropdown:hover .business-dropdown-content {
    display: block;
  }
}

.business-accordion > div:last-child > div {
  /* border-radius: 6px; */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
#panel1d-header-svg-none > .MuiAccordionSummary-expandIconWrapper > svg,
#panel3d-header > .MuiAccordionSummary-expandIconWrapper > svg,
#panel2d-header > .MuiAccordionSummary-expandIconWrapper > svg {
  display: none;
}

/* dropdown business page */
.business-drop-img {
  width: 16px;
  height: 16px;
  margin: 0 6px 0px 2px;
}

/* .business-dropbtn {
}

.business-dropdown {
} */

.business-dropdown-content {
  display: none;
  position: absolute;
  bottom: 34px;
  left: 6px;
  border: solid 1px #c9c9c9;
  border-radius: 6px;
  background-color: #fff;
  min-width: 98px;
  z-index: 1;
}

.business-dropdown-content2 {
  left: 46px;
  min-width: 51px;
}

.business-dropdown-content3 {
  left: 83px;
  min-width: 51px;
}

.business-dropdown-content3 > a,
.business-dropdown-content2 > a {
  text-align: center !important;
}

.business-dropdown-content a {
  text-align: left;
  color: black;
  cursor: pointer;
  padding: 3.5px 4px;
  /* height: 30px; */
  font-size: 13px;
  border-bottom: 1px solid #c9c9c9;
  /* border-radius: 6px; */
  text-decoration: none;
  display: block;
  color: #005589;
}

.bdi-lastt {
  border-bottom: 0px solid #c9c9c9 !important;
}

.business-dropdown-content a:hover {
  background-color: #f7f7f7;
  border-radius: 6px;
}

.business-dropdown:hover .business-dropdown-content {
  display: block;
}

/* for scroller html page  */

/* width */
::-webkit-scrollbar {
  width: 10px;
  /* width: 14px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* background: #f4f4f4; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #59e6a3; */
  border-radius: 10px;
  background-color: #babac0;
  /* border: 2px solid #fff; */

  /* background-color: #babac0;
  border: 3px solid #fff;
  border-radius: 16px;
  -webkit-transition: all .2s linear;
  transition: all .2s linear; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
  /* background: #59e6a3; */
}

@-moz-document url-prefix() {
  html {
    overflow-y: scroll;
    scrollbar-color: #babac0 #f1f1f1;
    scrollbar-width: 10px !important;
  }
}

/* Business Write Comment */
.writecomment-div {
  /* width: 720px; */
  min-height: 765px;
  height: fit-content;
  margin: 40px 0px 120px 0;
  /* padding: 31px 28px 69px 43px; */
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(102, 102, 102, 0.16);
  border: solid 1px #f7f7f7;
  background-color: #f6f6f6;
}

.writecomment-title1 {
  font-size: 16px;
  letter-spacing: -0.37px;
  color: #005589;
  margin: 30px 30px 30px 0;
}
.writecomment-title1 > p {
  font-size: 14px;
  letter-spacing: -0.37px;
  color: #67808f;
  margin-bottom: 0px;
}
.writecomment-text1 {
  margin: 14px 30px 17px 0;
  font-size: 16px;
  color: #005589;
}

.writecomment-text2 {
  margin: 39px 30px 11px 0;
  font-size: 16px;
  letter-spacing: -0.37px;
  color: #005589;
}

.input-error-wc {
  position: absolute;
  top: -50px;
  right: 31px;
}

.input-error-wc2 {
  position: absolute;
  top: -60px;
  right: 31px;
}

.writecomment-input1-label {
  display: block;
  margin: 12px 30px 5px 0px;
  font-size: 14px;
  letter-spacing: -0.32px;
  color: #005589;
}

.writecomment-input1 {
  width: 91%;
  height: 44px;
  margin: 0px 30px 56px 30px;
  border-radius: 6px;
  border: solid 1px #b6b6b6;
  background-color: #fff;
  padding: 0 10px;
}

.writecomment-input2 {
  width: 91%;
  height: 170px;
  resize: none;
  margin: 0px 30px 58px 30px;
  padding: 12px 16px 0 16px;
  border-radius: 6px;
  border: solid 1px #b6b6b6;
  background-color: #fff;
  font-size: none;
}

.writecomment-input1:focus,
.writecomment-input2:focus {
  border: solid 1px #33e0a0;
}

.writecomment-input1:focus-visible,
.writecomment-input2:focus-visible {
  outline: none;
}

.writecomment-input2::placeholder {
  font-size: 14px;
  color: #c2c2c2;
}

.rb-error {
  border: solid 1px #f82020;
}

.writecomment-sabt {
  width: 290px;
  height: 43px;
  margin: 0 0px 0 0;
  border-radius: 6px;
  background-color: #b294f2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  box-shadow: none;
  color: #fff;
}
.writecomment-sabt:disabled {
  background-color: #8a8a8af5 !important;
}
.writecomment-sabt:hover,
.writecomment-sabt:active,
.writecomment-sabt:focus,
.writecomment-sabt:hover {
  color: #fff;
  background-color: #b294f2;
}

.writecomment-div2 {
  height: 790px;
  margin: 40px 0px 120px 0;
}

.writecomment-title2 {
  margin: 2px -10px 8px 0px;

  font-size: 16px;
  color: #005589;
}

.custom-hr2 {
  /* width: 438px; */
  height: 1px;
  margin: 2px 0px 0px 2px;
  background-color: #c9c9c9;
}

@media only screen and (max-width: 767.5px) {
  .writecomment-div {
    min-height: fit-content;
    height: fit-content;
    margin: 16px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
    border-radius: 6px;
    box-shadow: none;
    border: none;
    background-color: #fff;
  }

  .writecomment-rate {
    display: flex;
    justify-content: right;
  }

  .writecomment-title1 {
    font-size: 16px;
    margin: 16px 0px 10px 0;
  }

  .writecomment-text1 {
    margin: 0px 0px 0px 0;
    font-size: 16px;
    padding-top: 6px;
  }

  .writecomment-text2 {
    margin: 27px 0px 12px 0;
    font-size: 16px;
    letter-spacing: -0.2px;
  }

  .input-error-wc {
    position: absolute;
    top: -48px;
    right: 3px;
  }

  .input-error-wc2 {
    position: absolute;
    top: -37px;
    right: 2px;
  }

  .writecomment-input1-label {
    margin: 0px 0px 7px 0px;
    font-size: 14px;
  }

  .writecomment-input1 {
    width: 100%;
    height: 44px;
    margin: 0px 0px 52px 0px;
    padding: 0 16px;
  }

  .writecomment-input2 {
    width: 100%;
    height: 170px;
    margin: 0px 0px 32px 0px;
    padding: 12px 16px 0 16px;
  }

  .writecomment-input2::placeholder {
    font-size: 14px;
  }

  .writecomment-sabt {
    max-width: 290px;
    width: 100%;
    height: 42px;
    margin: 0 0px 87px 0;
    font-size: 16px;
  }

  .custom-hr2 {
    height: 1px;
    margin: 2px -27px 14px -27px;
    background-color: #c9c9c9;
  }
}

/* register business page */
.register-progress {
  width: 100%;
  height: 6px;
}

.register-progress ul {
  list-style: none;
  width: 100%;
  height: 6px;
  padding-right: 0;
  background-color: #f7f7f7;
  padding: 0px;
}

.register-progress ul {
  display: flex;
}

.register-progress li {
  width: 20%;
  height: 6px;
}

.register-progress li:not(:last-child) {
  margin-left: 16px;
}

.register-progress ul li.active {
  background-color: #33e0a0;
}

.register-progress ul li.not-active {
  background-color: #eaeaea;
}

.register-business-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 111px;
  padding: 0px 40px 0px 40px;
  box-shadow: 0 2px 3px 0 rgba(129, 129, 129, 0.16);
  background-color: #fff;
}

.register-business-title {
  margin: 0px 20px 0px 0px;
  font-size: 18px;
  color: #33e0a0;
}

/* steps */
.register-steps-right {
  position: relative;
  /* width: 868px; */
  min-height: 590px;
  padding: 67px 86px 100px 86px;
  background-color: #f7f7f7;
}

.register-business-padd {
  padding-right: 0px;
}

.register-business-img {
  width: 24vw;
  height: 24vw;
  margin: 100px 0px 0 0;
}

@media only screen and (max-width: 767.5px) {
  .register-progress {
    width: 100% !important;
  }

  .register-business-padd {
    padding-left: 0px;
  }

  /*  */
  .register-progress ul {
    list-style: none;
    width: 100%;
    height: 1vw;
    padding-right: 0;
    background-color: #fff;
    padding: 0px;
  }

  .register-progress ul {
    display: flex;
  }

  .register-progress li {
    width: 20%;
    height: 1vw;
  }

  /*  */
  .register-progress li:not(:last-child) {
    margin-left: 1.6vw;
  }

  .register-progress ul li.active {
    background-color: #33e0a0;
  }

  .register-progress ul li.not-active {
    background-color: #eaeaea;
  }

  /*  */
  .register-business-header {
    min-height: 14.13vw;
    height: fit-content;
    padding: 0px 16px 0px 16px;
    box-shadow: none;
    border-bottom: 1px solid #c9c9c9;
  }

  /*  */
  .logo-header2-lap {
    /* width: 24.53vw; */
    height: auto;
  }

  /*  */
  .register-business-title {
    margin: 0px 0px 0px 0px;
    font-size: 3.73vw;
  }

  /*  */
  .bg-head-btn {
    width: 24.26vw;
    height: 8.53vw;
    background-color: #ededed;
    border: none;
    box-shadow: none;
    font-size: 3.73vw;
    color: #005589;
  }

  .bg-head-btn:hover {
    box-shadow: 0 1px 7px 0 rgb(0 0 0 / 17%), 0 2px 7px 0 rgb(0 0 0 / 7%) !important;
  }

  /* steps */
  /*  */
  .register-steps-right {
    min-height: fit-content;
    padding: 43px 24px 0px 24px;
    background-color: #fff;
  }
}

/* rb-step1 */
.rb-step1-tit1 {
  /* margin: 0 0 63px 88px; */
  font-size: 21px;
  font-weight: bold;
  text-align: right;
  color: #005589;
  margin-bottom: 12px;
}

.rb-step1-tit2 {
  margin: 0 0 26px 0;
  font-size: 16px;
  color: #005589;
  letter-spacing: 0.6px;
}

.rbtn-s1 {
  margin-top: 5px;
}

.container-rbtn-s1 {
  margin-right: 4px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* padding-right: 25px; */
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 16px;
  letter-spacing: -0.25px;
  color: #005589;
}

/* Hide the browser's default radio button */
.container-rbtn-s1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  height: 15px;
  width: 15px;
  background-color: #f7f7f7;
  border-radius: 50%;
  border: solid 1px #565656;
  margin-left: 8px;
}

/* On mouse-over, add a grey background color */
/* .container-rbtn-s1:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */
.container-rbtn-s1 input:checked ~ .checkmark {
  background-color: #33e0a0;
  border: solid 0px #33e0a0;
}

/*  */

.rb-steps-btns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 60px;
  width: 42vw;
}

.rb-steps-btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 39px;
  border-radius: 6px;
  background-color: #c9c9c9;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
}

.rb-steps-btn1:hover {
  color: #005589;
}

.rb-steps-btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 39px;
  border-radius: 6px;
  background-color: #33e0a0;
  font-size: 14px;
  font-weight: bold;
  color: #fefefe;
}

.rb-steps-btn2:hover {
  color: #fefefe;
}

/* outline inputs */
.MuiAutocomplete-paper,
.MuiOutlinedInput-input,
.MuiInputLabel-animated {
  font-family: IRANSansWeb !important;
}

.rb-step1-text {
  position: absolute;
  bottom: 32px;
  left: 2px;
  font-size: 14px;
  color: #c9c9c9;
}

#rb-step1-input1 {
  position: relative;
  width: fit-content;
}

#rb-step1-input1 > div {
  width: 42vw !important;
  height: 44px;
}

#rb-step1-input1 > div > label {
  font-size: 14px;
  color: #c9c9c9;
  top: -4px;
}

#rb-step1-input1 > div > label.Mui-focused,
#rb-step1-input1 > div > label.MuiInputLabel-shrink {
  top: 0px;
  font-size: 14px;
  font-family: IRANSansWeb !important;
}

#rb-step1-input1 > div > div > input {
  padding: 0px 20px 0px 20px;
}

#rb-step1-input1 > div > div > fieldset {
  border: solid 1px #c9c9c9;
  border-radius: 6px;
}

#rb-step1-input1 > div.rb-error > div > fieldset {
  border: solid 1px #f82020 !important;
  border-radius: 6px;
}

#rb-step1-input1 > div.rb-error > div > fieldset > legend > span {
  color: #f82020 !important;
}

/* top border */
#rb-step1-input1 > div > div > fieldset > legend > span {
  font-size: 14px;
  padding: 0px;
  margin-right: 2px;
  width: fit-content;
}

#rb-step1-input1 > div > div {
  width: 42vw;
  height: 44px;
  background-color: #fefefe;
}

/* .rb-step1-input {
  width: 42vw;
  height: 44px;
  display: block;
  padding: 0px 20px 0px 20px;
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  background-color: #fefefe;
}
.rb-step1-input:focus-visible {
  outline: none;
}
.rb-step1-input::placeholder {
  font-size: 14px;
  color: #c9c9c9;
}
 */
@media only screen and (max-width: 767.5px) {
  /*  */
  .rb-step1-tit1 {
    font-size: 4.8vw;
    text-align: center;
    margin-bottom: 8px;
  }

  /*  */
  .rb-step1-tit2 {
    text-align: center;
    margin: 0 0 32px 0;
    font-size: 3.46vw;
    letter-spacing: 0.4px;
  }

  .rbtn-s1 {
    margin-top: 26px;
  }

  /*  */
  .container-rbtn-s1 {
    margin-right: 0px;
    width: fit-content;

    position: relative;
    /* padding-right: 25px; */
    margin-bottom: 6px;
    font-size: 3.46vw;
    letter-spacing: 0px;
  }

  /* Hide the browser's default radio button */
  .container-rbtn-s1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  /*  */
  .checkmark {
    height: 4vw;
    width: 3.8vw;
    background-color: #fff;
    border-radius: 50%;
    border: solid 1px #565656;
    margin-left: 8px;
  }

  /*  */
  .checkmark-responsive {
    width: 5.52vw !important;
    height: 3.84vw;
    margin-top: 4.5px;
  }

  /*  */
  .container-rbtn-s1 input:checked ~ .checkmark-responsive {
    background-color: #33e0a0 !important;
    border: solid 0px #33e0a0 !important;
    width: 5.78vw !important;
    height: 3.84vw;
  }

  /* On mouse-over, add a grey background color */
  /* .container-rbtn-s1:hover input ~ .checkmark {
    background-color: #ccc;
  } */

  /* When the radio button is checked, add a blue background */

  .container-rbtn-s1 input:checked ~ .checkmark {
    background-color: #33e0a0;
    border: solid 0px #33e0a0;
  }

  /*  */
  /*  */
  .rb-steps-btns {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    position: inherit;
    margin-top: 55px;
    margin-bottom: 55px;
  }

  /*  */
  .rb-steps-btn1 {
    width: 32.8vw;
    height: 10.4vw;
    font-size: 3.73vw;
  }

  /*  */
  .rb-steps-btn2 {
    width: 32.8vw;
    height: 10.4vw;
    font-size: 3.73vw;
  }

  /* outline inputs */
  .MuiAutocomplete-paper,
  .MuiOutlinedInput-input,
  .MuiInputLabel-animated {
    font-family: IRANSansWeb !important;
  }

  .rb-step1-text {
    position: absolute;
    bottom: 8.5vw;
    left: 2px;
    font-size: 3.2vw;
    color: #c9c9c9;
  }

  .width-sm-100 {
    width: 100% !important;
  }

  /*  */
  #rb-step1-input1 > div {
    width: 100% !important;
    height: 10.4vw;
  }

  /*  */
  #rb-step1-input1 > div > div {
    width: 100% !important;
    height: 10.4vw;
    background-color: #fff;
  }

  #rb-step1-input1 > div > label {
    font-size: 3.73vw;
    color: #c9c9c9;
    top: -4px;
  }

  #rb-step1-input1 > div > label.Mui-focused,
  #rb-step1-input1 > div > label.MuiInputLabel-shrink {
    top: 0px;
    font-size: 3.73vw;
    font-family: IRANSansWeb !important;
  }

  #rb-step1-input1 > div > div > input {
    padding: 0px 20px 0px 20px;
  }

  #rb-step1-input1 > div > div > fieldset {
    border: solid 1px #c9c9c9;
    border-radius: 6px;
    width: 100%;
  }

  /* top border */
  #rb-step1-input1 > div > div > fieldset > legend > span {
    font-size: 14px;
  }

  #rb-step1-input1 > div > div > fieldset > legend > span {
    /* font-size: 12.5px; */
    padding: 0px;
    margin-right: 2px;
    width: fit-content;
  }
}

/* step 2 */
.rb-step2-tit1 {
  margin: 1px 0px 20px 0px;
  font-size: 21px;
  font-weight: bold;
  color: #005589;
}

.rb-step2-tit2 {
  margin: 35px 1px 6px 11px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.41px;
  color: #005589;
}

.s2-group {
  width: 42vw;
  box-shadow: none;
  justify-content: space-between;
  display: flex;
}

.s2-group:hover,
.s2-group:focus,
.s2-group:active {
  box-shadow: none;
}

.s2-group-btn label {
  width: 94px;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  background-color: #fefefe;
  box-shadow: none;
  padding: 0px;
  font-size: 15px;
  font-weight: bold;
  color: #005589 !important;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s2-group-btn > label > div > .digar-img {
  width: 16px;
  height: 15px;
  background-image: url("../../images/green-add.png");
  background-size: 14px;
  margin-right: 3px;
  background-repeat: no-repeat;
}

.btn-check:checked + .btn-primary div > .digar-img {
  width: 16px;
  height: 15px;
  background-image: url("../../images/black-add.png");
  background-size: 14px;
  margin-right: 3px;
  background-repeat: no-repeat;
}

.s2-group-btn:hover label,
.s2-group-btn:focus label,
.s2-group-btn:active label,
.s2-group-btn:focus-visible label {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 7%), 0 6px 18px -1px rgb(0 0 0 / 4%) !important;
  background-color: #fefefe !important;
  border: solid 1px #c9c9c9 !important;
  color: #565656 !important;
}

.btn-check:checked + .btn-primary {
  box-shadow: none;
  background-color: #c9b2f8 !important;
  border: solid 0px #cbcbcb !important;
  color: #005589 !important;
}

/* .s2-group-btn label img {
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px;
} */

/* step2 auto8 */
.auto8 > div > div > ul {
  max-height: 25vh;
}

.auto8 > .MuiAutocomplete-root {
  width: 42vw;
  height: 44px;
  /* height: 58px; */
}

.auto8 > div > div > div > div > .MuiAutocomplete-endAdornment {
  position: initial;
}

/* .auto8 > div > div > div > div > .MuiOutlinedInput-notchedOutline, */
.auto8 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  width: 42vw;
  height: 49px;
  border-radius: 6px;
  border: solid 1px #c9c9c9 !important;
  /* background-color: white; */
  position: absolute;
  top: -5px;
}

.auto8 {
  width: fit-content;
}

.auto8 > .MuiAutocomplete-popper {
  /* transform: translate3d(0px, 50px, 0px) !important; */
  /* transform:translate3d(0px, 990.5px, 0px) !important; ; */
  /* display: contents; */
  /* width: 569px; */
  /* left: 0px; */
  /* margin: 0px; */
  /* transform: translate3d(458.5px, 990.5px, 0px); */
  /* inset: 0px 0px 0px 0px; */
  padding: 0px 0px !important;
}

.auto8 > div > .MuiAutocomplete-paper {
  width: inherit;
  /* position: relative; */
  position: fixed;
  top: 1px;
}

.muirtl-1in441m {
  margin-left: 0px !important;
  width: fit-content !important;
}

/* .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline */
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #dcf8ed !important;
}

.MuiAutocomplete-option:hover {
  background-color: #dcf8ed !important;
}

.auto8>div>div>div>.MuiOutlinedInput-root,
/* .MuiInputBase-root, */
.auto8>div>div>div>.MuiAutocomplete-inputRoot {
  height: 44px;
  padding: 0 15px 0 5px !important;
  background-color: #fff;
}

.auto8 > div > div > div > div > div > .MuiAutocomplete-popupIndicatorOpen {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg) !important;
  -ms-transform: rotate(-90deg) !important;
  transform: rotate(-90deg) !important;
}

.auto8 > div > div > div > label {
  font-size: 14px !important;
  top: -4px;
  color: #c9c9c9;
}

.auto8 > div > div > div > div > .MuiAutocomplete-input {
  /* font-size: 14px !important ; */
  top: -4px;
  /* color: black; */
}

.auto8 > div > div > div > label.Mui-focused,
.auto8 > div > div > div > label.MuiInputLabel-shrink {
  font-size: 14px !important;
  top: 0px;
  color: #c9c9c9;
}

#rb-chip {
  margin: 7px 0px 0 11px;
  height: 26px;
  border-radius: 6px;
  background-color: #c9c9c9;
  font-size: 12px;
  color: #005589;
}

#rb-chip.header-chip {
  margin: 0px 0px 0 11px;
  height: 26px;
  border-radius: 6px;
  background-color: #c9c9c975;
  font-size: 12px;
  color: #005589;
}

#rb-chip > .MuiChip-deleteIcon {
  margin: 0 5px 0 9px;
}

#rb-chip > .MuiChip-label {
  padding: 0 10px 0 0px;
  font-family: IRANSansWeb !important;
}

.s2-chip-icon {
  width: 7px;
  height: 7px;
}

@media only screen and (max-width: 767.5px) {
  #rb-chip.header-chip {
    max-width: calc(50% - 24px);
  }
  /*  */
  .rb-step2-tit1 {
    margin: 0px 0px 30px 0px;
    font-size: 4.8vw;
    text-align: center;
  }

  .rb-step2-tit2 {
    margin: 35px 0px 6px 0px;
    font-size: 3.7vw;
    letter-spacing: 0px;
    text-align: center;
  }

  .s2-group {
    width: 100%;
    justify-content: center;
  }

  .s2-group-btn label {
    height: 7.99vw;
    width: 18.64vw;
    font-size: 3.46vw;
    margin-top: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .s2-group-btn > label > div > .digar-img {
    width: 3.19vw !important;
    height: 3.1vw !important;
    background-size: 3.19vw;
    margin-right: 5px;
  }

  .btn-check:checked + .btn-primary div > .digar-img {
    width: 3.19vw !important;
    height: 3.1vw !important;
    background-size: 3.19vw;
    margin-right: 5px;
  }

  /* step2 auto8 */
  .auto8 > div > div > ul {
    max-height: 28.2vh;
  }

  .auto8 > .MuiAutocomplete-root {
    width: 100%;
    height: 44px;
    /* height: 58px; */
  }

  .auto8 > div > div > div > div > .MuiAutocomplete-endAdornment {
    position: initial;
  }

  /* .auto8 > div > div > div > div > .MuiOutlinedInput-notchedOutline, */
  .auto8 > div > div > div > div > .MuiOutlinedInput-notchedOutline {
    width: 100% !important;
    height: 49px;
    border-radius: 6px;
    border: solid 1px #c9c9c9 !important;
    /* background-color: white; */
    position: absolute;
    top: -5px;
  }

  .auto8 > .MuiAutocomplete-popper {
    padding: 0px 0px !important;
  }

  .auto8 > div > .MuiAutocomplete-paper {
    width: inherit;
    /* position: relative; */
    position: fixed;
    top: 1px;
  }

  .muirtl-1in441m {
    margin-left: 0px !important;
    width: fit-content !important;
  }

  /* auto font */
  .auto8 div > div > ul > .MuiAutocomplete-option {
    padding: 8.5px 4px;
    font-size: 12px;
    border-bottom: 0.35px solid #dfdfdf;
    min-height: unset;
  }

  .auto8 > div > div > .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 6px;
  }

  .auto8>div>div>div>.MuiOutlinedInput-root,
  /* .MuiInputBase-root, */
  .auto8>div>div>div>.MuiAutocomplete-inputRoot {
    height: 44px;
    padding: 0 15px 0 5px !important;
    background-color: #fff;
  }

  .auto8
    > div
    > div
    > div
    > div
    > div
    > .css-113ntv0-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
  .auto8 > div > div > div > div > div > .css-1agnrrp {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg) !important;
    -ms-transform: rotate(270deg) !important;
    transform: rotate(270deg) !important;
  }

  .auto8 > div > div > div > label {
    font-size: 14px !important;
    top: -4px;
    color: #c9c9c9;
  }

  .auto8 > div > div > div > div > .MuiAutocomplete-input {
    /* font-size: 14px !important ; */
    top: -4px;
    /* color: black; */
  }

  .auto8 > div > div > div > label.Mui-focused,
  .auto8 > div > div > div > label.MuiInputLabel-shrink {
    font-size: 14px !important;
    top: 0px;
    color: #c9c9c9;
  }

  #rb-chip {
    margin: 7px 0px 0 11px;
    height: 26px;
    font-size: 12px;
  }

  #rb-chip > .MuiChip-deleteIcon {
    margin: 0 5px 0 9px;
  }

  #rb-chip > .MuiChip-label {
    padding: 0 10px 0 0px;
  }

  .s2-chip-icon {
    width: 8.25px;
    height: 8.25px;
  }
}

/* step 3a */
.rbtn-s3a {
  margin-top: 20px;
  /* display: flex; */
}

.rb-step3a-tit {
  margin: -22px 0 7px 46px;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #005589;
}

/* The container */
.container-s2 {
  min-width: 250px;
  /* display: block; */
  width: fit-content;
  position: relative;
  padding-right: 3px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.5px;
  text-align: right;
  color: #005589;
}

/* Hide the browser's default checkbox */
.container-s2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-s2 {
  /* position: absolute;
  top: 0;
  right: 0; */
  height: 15px;
  width: 15px;
  background-color: #f7f7f7;
  border-radius: 2px;
  border: solid 1px #565656;
  margin-left: 14px;
}

/* When the checkbox is checked, add a blue background */
.container-s2 input:checked ~ .checkmark-s2 {
  background-color: #33e0a0;
  border: solid 0px #33e0a0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-s2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-s2 input:checked ~ .checkmark-s2:after {
  display: block;
}

@media only screen and (max-width: 767.5px) {
  .rb-step3a-tit {
    margin: 0px 0 30px 0px;
    font-size: 4.8vw;
    text-align: center;
  }

  .rbtn-s3a {
    margin-top: 0px;
  }

  /* The container */
  .container-s2 {
    min-width: 250px;
    max-width: 311px;
    width: 100%;
    padding-right: 0px;
    margin: 8px auto -24px !important;
    margin-bottom: 6px;
    /* margin-right: 44px; */
    font-size: 14px;
    letter-spacing: -0.5px;
    text-align: right;
  }

  /* Create a custom checkbox */
  .checkmark-s2 {
    height: 15px;
    width: 15px;
    background-color: #fff;
    margin-left: 9px;
  }
}

/*  */
/* step 3 */
.rb-step3-tit1 {
  margin: 26px 0px 36px 0px;
  font-size: 21px;
  font-weight: bold;
  color: #005589;
}

#rb-step3-input1 > div {
  width: 20.4vw !important;
  height: 44px;
}

#rb-step3-input1 > div > label {
  font-size: 14px;
  color: #c9c9c9;
  top: -4px;
}

#rb-step3-input1 > div > label.Mui-focused,
#rb-step3-input1 > div > label.MuiInputLabel-shrink {
  top: 0px;
  font-family: IRANSansWeb !important;

  font-size: 14px;
}

#rb-step3-input1 > div > div > input {
  padding: 0px 20px 0px 20px;
}

#rb-step3-input1 > div > div > fieldset {
  border: solid 1px #c9c9c9;
  border-radius: 6px;
}

#rb-step3-input1 > div.rb-error > div > fieldset {
  border: solid 1px #f82020 !important;
  border-radius: 6px;
}

#rb-step3-input1 > div.rb-error > div > fieldset > legend > span {
  color: #f82020 !important;
}

/* top border */
#rb-step3-input1 > div > div > fieldset > legend > span {
  font-size: 14px;
}

#rb-step3-input1 > div > div > fieldset > legend > span {
  /* font-size: 12.5px; */
  padding: 0px;
  margin-right: 2px;
  width: fit-content;
}

#rb-step3-input1 > div > div {
  width: 20.5vw;
  height: 44px;
  background-color: #fefefe;
}

@media only screen and (max-width: 767.5px) {
  .rb-step3-tit1 {
    margin: 0px 0px 30px 0px;
    font-size: 4.8vw;
    text-align: center;
  }

  .mb-step3 {
    margin-bottom: -38px;
  }

  #rb-step3-input1 > div {
    width: 100% !important;
    height: 10.4vw;
  }

  /*  */
  #rb-step3-input1 > div > div {
    width: 100% !important;
    height: 10.4vw;
    background-color: #fff;
  }

  #rb-step3-input1 > div > label {
    font-size: 3.73vw;
    color: #c9c9c9;
    top: -4px;
  }

  #rb-step3-input1 > div > label.Mui-focused,
  #rb-step3-input1 > div > label.MuiInputLabel-shrink {
    top: 0px;
    font-size: 3.73vw;
    font-family: IRANSansWeb !important;
  }

  #rb-step3-input1 > div > div > input {
    padding: 0px 20px 0px 20px;
  }

  #rb-step3-input1 > div > div > fieldset {
    border: solid 1px #c9c9c9;
    border-radius: 6px;
    width: 100%;
  }

  /* top border */
  #rb-step3-input1 > div > div > fieldset > legend > span {
    font-size: 14px;
  }

  #rb-step3-input1 > div > div > fieldset > legend > span {
    /* font-size: 12.5px; */
    padding: 0px;
    margin-right: 2px;
    width: fit-content;
  }
}

/*  */
/* step 4 */
.rb-step4-tit1 {
  margin: -6px 0 37px 4px;
  font-size: 21px;
  font-weight: bold;
  color: #005589;
}

.rb-step4-icons-div {
  margin: 2px 0 18px 0;
  width: 42vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rb-step4-icon {
  width: 18px;
  height: 18px;
}

.rb-step4-icons {
  margin: 0 12px;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #f7f7f7;
  text-align: center;
  padding-top: 3.5px;
}

.rb-step4-icons:hover,
.rb-s4-active {
  border: solid 1px #67808f;
}

@media only screen and (max-width: 767.5px) {
  .rb-step4-tit1 {
    margin: 0px 0 30px 4px;
    font-size: 4.8vw;
    text-align: center;
  }

  .rb-step4-icons-div {
    margin: 2px 0 14px 0;
    width: 100%;
  }

  .rb-step4-icon {
    width: 4.8vw;
    height: 4.8vw;
  }

  .rb-step4-icons {
    margin: 0 8px;
    width: 9.3vw;
    height: 9.3vw;
    background-color: #f7f7f7;
    border-radius: 50%;
    border: solid 1px #f7f7f7;
    text-align: center;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rb-step4-icons:hover,
  .rb-s4-active {
    border: solid 1px #67808f;
  }
}

/*  */

/* step 5 */
.rb-step5-tit1 {
  margin: -20px 0 16px 4px;
  font-size: 21px;
  font-weight: bold;
  color: #005589;
}

.rb-step5-tit2 {
  margin: 0px 0px 14px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
}

.rb-s5-group-btn label {
  width: 104px;
  height: 40.5px;
  box-shadow: none;
  border-radius: 20.2px;
  border: solid 1px #c9c9c9;
  background-color: #f7f7f7 !important;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
  padding: 0px;
  margin-bottom: 15px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rb-s5-group-btn:hover label,
.rb-s5-group-btn:focus label,
.rb-s5-group-btn:focus-visible label {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 4%), 0 6px 13px -1px rgb(0 0 0 / 2%) !important;
  border: solid 1px #c9c9c9 !important;
  color: #005589 !important;
}

.rb-s5-group-btn > .btn-check:checked + .btn-primary {
  box-shadow: none;
  background-color: #242b2e !important;
  border: solid 0px #cbcbcb !important;
  color: #fff !important;
}

.rb-s5-group2-btn label {
  width: 181px;
  height: 41.5px;
  box-shadow: none;
  border-radius: 20.7px;
  border: solid 1px #c9c9c9;
  background-color: #f7f7f7 !important;
  font-size: 14px;
  font-weight: bold;
  color: #005589;
  padding: 0px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rb-s5-group2-btn > label:hover,
.rb-s5-group2-btn > label:focus,
.rb-s5-group2-btn > label:active,
.rb-s5-group2-btn > label:focus-visible {
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 4%), 0 6px 13px -1px rgb(0 0 0 / 2%) !important;
  border: solid 1px #c9c9c9 !important;
  color: #005589 !important;
}

.rb-s5-group2-btn > .btn-check:checked + .btn-primary {
  box-shadow: none;
  background-color: #242b2e !important;
  border: solid 0px #cbcbcb !important;
  color: #fff !important;
}

/* select custom */
.muirtl-hdw1oc {
  display: contents;
}

#step5-select1 {
  margin: 0px 0 0 22px;
}

#step5-select1 > div {
  width: 95px;
  height: 39px;
}

#step5-select1 > div > .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c9c9c9 !important;
}

#step5-select1 > div.Mui-focused > .MuiOutlinedInput-notchedOutline {
  border: 1px solid #565656 !important;
}

.MuiPaper-elevation::-webkit-scrollbar-thumb {
  width: 6px;
  /* background: #59e6a3; */
  border-radius: 12px;
  background-color: #babac0;
  border: 0px solid #fff;
}

.MuiPaper-elevation::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px !important;
}

.MuiPaper-elevation::-webkit-scrollbar-track {
  border-radius: 12px !important;
}

.MuiPaper-elevation::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

#step5-select1 > div > div {
  padding: 0;
  padding-right: 10px;
}

.s5-items {
  padding: 4px 0 0 0 !important;
  font-size: 14px !important;
  justify-content: center !important;
  font-family: IRANSansWeb !important;
}

.s5-items:hover {
  background-color: #dcf8ed !important;
}

.s5-items.Mui-selected {
  background-color: #89ffc6 !important;
}

.MuiPaper-root.MuiPaper-rounded.MuiPopover-paper {
  margin-top: 6px;
  box-shadow: none;
  border: solid 1px #707070;
  max-height: 165px;
}

.MuiPaper-root.MuiPaper-rounded.MuiPopover-paper > ul {
  padding: 0px;
}

#step5-select1 > div > svg {
  background-image: url("../../images/next-left.png");
  background-repeat: no-repeat, repeat;
  background-position: left 0em top 50%, 0 0;
  margin-left: -4px;
  margin-top: -2px;
  background-size: 15px 17px, 100%;
}

#step5-select1 > div > svg.MuiSelect-iconOpen {
  transform: rotate(-90deg);
  margin-top: -6px;
  background-size: 15px 17px, 100%;
}

#step5-select1 > div > svg > path {
  display: none;
}

.s5-select-icon {
  width: 10px;
  height: 10px;
}

@media only screen and (max-width: 767.5px) {
  /* step 5 */
  .rb-step5-tit1 {
    margin: -7px 0 15px 0px;
    font-size: 4.8vw;
    text-align: center;
  }

  .rb-step5-tit2 {
    margin: 0px 0px 23px 0px;
    font-size: 3.73vw;
    text-align: center;
  }

  .rb-s5-group {
    width: 100%;
    justify-content: center;
  }

  .rb-s5-group-btn label {
    width: 27.73vw;
    height: 11.2vw;
    border-radius: 5.4vw;
    background-color: #fff !important;
    font-size: 3.73vw;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .rb-s5-group-btn:not(:last-child) label {
    margin-left: 12px !important;
  }

  .rb-s5-group2 {
    width: 100%;
    display: block;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }

  .rb-s5-group2-btn label {
    width: 48.26vw;
    height: 11.2vw;
    border-radius: 5.4vw;
    border: solid 1px #c9c9c9;
    background-color: #fff !important;
    font-size: 3.73vw;
    margin-left: 0px;
    margin-top: 12px;
  }

  /* select custom */

  #step5-select1 {
    margin: 0px 0 0px;
  }

  #step5-select1 > div {
    width: 96px;
    height: 39px;
    text-align: right;
  }

  #step5-select1 > div > .MuiOutlinedInput-notchedOutline {
    border: 1px solid #c9c9c9 !important;
  }

  #step5-select1 > div.Mui-focused > .MuiOutlinedInput-notchedOutline {
    border: 1px solid #565656 !important;
  }

  .MuiPaper-elevation::-webkit-scrollbar-thumb {
    width: 6px;
    background: #babac0;
    border-radius: 12px;
  }

  .MuiPaper-elevation::-webkit-scrollbar {
    width: 6px;
    border-radius: 12px !important;
  }

  .MuiPaper-elevation::-webkit-scrollbar-track {
    border-radius: 12px !important;
  }

  #step5-select1 > div > div {
    padding: 0;
    padding-right: 10px;
  }

  .s5-items {
    padding: 4px 0 0 0 !important;
    font-size: 14px !important;
    justify-content: center !important;
    min-height: 10px !important;
  }

  .s5-items:hover {
    background-color: #33e0a0 !important;
  }

  .s5-items.Mui-selected {
    background-color: #89ffc6 !important;
  }

  .MuiPaper-root.MuiPaper-rounded.MuiPopover-paper {
    margin-top: 11px;
    box-shadow: none;
    border: solid 1px #707070;
    max-height: 165px;
  }

  .MuiPaper-root.MuiPaper-rounded.MuiPopover-paper > ul {
    padding: 0px;
  }

  #step5-select1 > div > svg {
    background-image: url("../../images/next-left.png");
    background-repeat: no-repeat, repeat;
    background-position: left 0em top 50%, 0 0;
    margin-left: -4px;
    margin-top: -2px;
    background-size: 15px 17px, 100%;
  }

  #step5-select1 > div > svg.MuiSelect-iconOpen {
    transform: rotate(-90deg);
    margin-top: -6px;
    background-size: 15px 17px, 100%;
  }

  #step5-select1 > div > svg > path {
    display: none;
  }

  .s5-select-icon {
    width: 10px;
    height: 10px;
  }
}

/* step6 */
.rbtn-s6 {
  padding-right: 16px;
  font-size: 18px;
  line-height: 1.7;
  color: #005589;
}

.s6-small {
  font-size: 14px;
  color: #005589;
  padding-right: 4px;
}

.rb-step6-img {
  width: 100px;
  height: 100px;
  margin-top: -20px;
}

.rb-step6-tit1 {
  margin: 40px 0px 40px 0px;
  font-size: 20px;
  font-weight: bold;
  color: #005589;
  text-align: center;
}

.rb-step6-tit2 {
  margin: 36px 9px 10px 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.rb-step6-tit3 {
  margin: 0px 9px 30px 0px;
  font-size: 18px;
  text-align: center;
  color: #005589;
  letter-spacing: 0.4px;
}

.rb-step6-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 435px;
  height: 39px;
  margin: 45px 0px -30px 0px;
  border-radius: 6px;
  background-color: #33e0a0;
  font-size: 14px;
  font-weight: bold;
  color: #fefefe;
}

.rb-step6-btn:hover {
  color: #fefefe;
}

.rbtns-s1-box {
  width: 44vw;
  text-align: right;
}

/* step 7 or 6a */
.rb-step6a-tit {
  margin: 30px 14px 22px 0;
  font-size: 20px;
  font-weight: bold;
  color: #005589;
}

input[type="file"] {
  display: none;
}

.drop-container {
  width: 126px;
  height: 126px;
  border-radius: 10px;
  border: 2px dashed #c9c9c9;
  background-color: white;
  position: relative;
  margin: 0 0 0 0;
}

.uploaded_img {
  height: 126px;
  width: 126px;
  border-radius: 15px;
  border: 1px solid #555;
  position: relative;
  padding: 0px;
  margin: 0 14px 12px 0;
}

.drop-container:hover {
  background: #eee;
  /* border-color: #111; */
}

.img-upload {
  width: 34px;
  height: 34px;
  opacity: 0.72;
  position: absolute;
  left: 46px;
  top: 46px;
}

.btn-upload-img {
  margin-top: 10px;
  background-color: #33e0a0;
  float: right;
}

.div-drop-container {
  /* margin: 0 14px  0 0; */
  padding: 0 22px 0 0;
}

@media only screen and (max-width: 767.5px) {
  /* step 7 or 6a */
  .rb-step6a-tit {
    margin: 0px 0px 14px 0;
    font-size: 4.13vw;
    text-align: center;
  }

  input[type="file"] {
    display: none;
  }

  .drop-container {
    width: 33.6vw;
    height: 33.6vw;
    border-radius: 2.6vw;
    border: 2px dashed #c9c9c9;
    background-color: white;
    position: relative;
    margin: 0 0 0 0;
  }

  .uploaded_img {
    width: 33.6vw;
    height: 33.6vw;
    border-radius: 2.6vw;
    border: 1px solid #555;
    position: relative;
    padding: 0px;
    margin: 0 14px 12px 0;
  }

  .drop-container:hover {
    background: #eee;
  }

  .img-upload {
    width: 34px;
    height: 34px;
    opacity: 0.72;
    position: absolute;
    left: 46px;
    top: 46px;
  }

  .btn-upload-img {
    margin-top: 10px;
    background-color: #33e0a0;
    float: none;
  }

  .div-drop-container {
    margin: 0 0px 0 0;
    justify-content: center;
  }

  /* step6 */
  .rbtn-s6 {
    padding-right: 0px;
    font-size: 3.7vw;
    line-height: 1.7;
  }

  .s6-small {
    font-size: 3.2vw;
    padding-right: 4px;
  }

  .rb-step6-img {
    width: 18.6vw;
    height: 18.6vw;
    margin-top: -10px;
  }

  .rb-step6-tit1 {
    margin: 20px 0px 60px 0px;
    font-size: 4.5vw;
    line-height: 1.5;
  }

  .rb-step6-tit2 {
    margin: 0px 0px 12px 0px;
    font-size: 3.7vw;
  }

  .rb-step6-tit3 {
    margin: 0px 0px 20px 0px;
    font-size: 3.7vw;
    letter-spacing: -0.3px;
  }

  .rb-step6-btn {
    width: 100%;
    height: 10.4vw;
    margin: 55px 0px 55px 0px;
    padding: 0px 0px;
    font-size: 3.73vw;
    color: #fefefe;
  }

  .rbtns-s1-box {
    width: 100%;
  }
}

/* label goes top inputs */
.material {
  width: 42vw;
  height: 44px;
  position: relative;
  margin-top: 30px;
}

.input_custom {
  /* font-size: 18px; */
  padding: 0px 14px 0px;
  display: block;
  /* width: calc(100% - 24px); */
  width: 42vw;
  height: 44px;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}

.input_custom:focus {
  outline: none;
}

.input_custom:focus ~ .lable_input_custom,
:valid ~ .lable_input_custom {
  top: -15px;
  font-size: 14px;
  color: #c9c9c9;
  padding: 0px 5px;
  /* background-image: linear-gradient(to bottom, #f7f7f7, #f7f7f7, #ffffff, #ffffff); */
  background-image: linear-gradient(
    to bottom,
    #f7f7f7,
    #f9f9f9,
    #fbfbfb,
    #fdfdfd,
    #ffffff
  );
  direction: rtl;
  transition: all 0.3s ease;
}

.input_custom:focus ~ .borda {
  /* border: 2px solid #5264ae; */
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  top: 0px;
  pointer-events: none;
}

.lable_input_custom {
  color: #c9c9c9;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 10px;
  right: 15px;
  transition: all 0.3s ease;
  padding: 0px 5px;
}

.borda {
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  pointer-events: none;
}

/* register page */
.account-signup-btn {
  padding: 0 0 0 110px;
}

.signup-checkbox {
  min-width: unset;
  width: 25px;
  /* padding-right: 49px; */
  padding-top: 6px;
  margin: 6px 49px -15px 0;
}
.signup-checkbox-text {
  font-size: 14px;
  color: #005589;
  margin-right: 73px;
  position: absolute;
  top: 3px;
}
.signup-checkbox-text > span {
  color: #b294f2;
}
.signup-checkbox > span.checkmark-s2 {
  background-color: white;
  margin-left: 8px;
}
.signup-input-spinner {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 21px;
  right: 11px;
  z-index: 1;
}

@media only screen and (max-width: 767.5px) {
  .account-signup-btn {
    padding: 0 0 0 92px;
  }

  .signup-checkbox {
    min-width: unset;
    width: 25px;
    /* padding-right: 49px; */
    padding-right: 0px !important;
    padding-top: 0px !important;
    margin: 8px 0px -24px 0 !important;
  }
  .signup-checkbox-text {
    font-size: 14px;
    color: #005589;
    margin-right: 22px;
    position: absolute;
    top: -2px;
    letter-spacing: -0.5px;
  }

  .signup-checkbox > span.checkmark-s2 {
    background-color: white;
    margin-left: 8px;
  }
  .signup-input-spinner {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 17px;
    right: 10px;
    z-index: 1;
  }
}
@media only screen and (min-width: 767.5px) {
  .w-md-fit {
    width: fit-content;
  }
}
/* result page */
@media only screen and (min-width: 767.5px) {
  .laptop-search-header-fix {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 1;
  }
}
.result-filter {
  /* width: 352px; */
  height: auto;
  margin: 0px 0px 22px 0px;
  padding: 0px 0px;
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  z-index: 2;
}

.result-filter-div {
  height: 45px;
  padding: 0px 16px 0px 23px;
  /* border-top: 1px solid #33E0A0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #005589;
}

.result-filter-div:not(:first-child) {
  border-top: 1px solid #33e0a0;
}

.result-title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.4px;
  text-align: right;
  color: #005589;
  margin-bottom: 16px;
}

.moarefi-result {
  /* width: 352px; */
  /* height: 281px; */
  margin: 0px 0 22px 0;
  padding: 33px 0px 50px 0px;
  border-radius: 6px;
  background-color: #b294f2;
  color: #fefefe;
  letter-spacing: 0.51px;
}

.moarefi-result-div {
  margin: 25px 0 24px;
  font-size: 17px;
  letter-spacing: 0.51px;
  font-weight: bold;
  text-align: center;
  color: #fefefe;
}

/* .result-box {
  width: 349px;
  border-radius: 6px;
  height: 429px;
  margin: 23px 0px 147px 0px;
  border: solid 1px #707070;
} */
.result-notfound {
  margin-top: 44px;
  margin-bottom: 66px;
  font-size: 20px;
  color: #005589;
  text-align: center;
}

.result-img {
  width: 110px;
  height: 110px;
  margin-bottom: 37px;
}

.result-solution {
  text-align: right;
  max-width: 752px;
  /* width: 720px;
  height: 141px; */
  /* margin: 21px 6px 16px 153px; */
  padding: 22px 10px 25px 10px;
  border-radius: 6px;
  background-color: #f7f7f7;
  color: #005589;
}

.result-solution ul {
  margin-bottom: 0px;
  list-style: none;
  /* Remove default bullets */
  position: relative;
}

.result-solution ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #33e0a0;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 5px;
  /* width: 1em; Also needed for space (tweak if needed) */
  margin-left: 12px;
  /* Also needed for space (tweak if needed) */
  /* position: absolute; */
  margin-top: 3px;
  right: 31px;
}

.result-city {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  text-align: right;
  color: #005589;
  padding: 7px 20px 7px;
  /* border-bottom: 1px solid #c9c9c9; */
  background-color: #ffffff7d;

  position: absolute;
  /* top: 190px; */
  z-index: 1200;
  width: 100%;
}

.result-city-img1 {
  width: 17px;
  height: 17px;
  margin-left: 9px;
  /* margin-right: 4px; */
}

.result-city-img2 {
  width: 14px !important;
  height: 16px !important;
}

@media only screen and (max-width: 767.5px) {
  .result-img {
    margin-top: 45px;
  }

  .result-notfound {
    border-top: 0.5px solid #005589;
    margin: 80px -12px 30px;
  }

  .result-solution {
    margin: 0px 8px 16px 8px;
    padding: 22px 0px 25px 0px;
    font-size: 14px;
  }

  .result-solution ul {
    padding: 0 30px 0 30px;
  }

  .result-solution ul li::before {
    right: 20px;
  }

  .moarefi-result-resp {
    margin: 16px -12px;
  }

  .moarefi-result-resp > div {
    width: 100%;
    padding: 14px 12vw 14px 12vw;
    margin: 0;
    height: fit-content;
  }

  .result-filter-modal-div {
    padding: 7px 25px 7px 20px;
    border-bottom: 1px solid #c9c9c9;
    font-size: 14px;
    color: #005589;
    display: flex;
    justify-content: space-between;
  }

  .result-filter {
    border: none;
  }

  .result-filter-div {
    padding: 0px 27px 0px 35px;
  }
}

@media only screen and (max-width: 370px) {
  .pt_0_new {
    padding-top: 0px !important;
  }
}

/* inputs */

/* .muirtl-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline */
.auto10 > div > div > div > div.Mui-focused > fieldset,
.auto9 > div > div > div > div.Mui-focused > fieldset,
.auto8 > div > div > div > div.Mui-focused > fieldset,
.custom-text-field > div.Mui-focused > fieldset {
  /* border-color: #33E0A0 !important; */
  border: 1px solid #33e0a0 !important;
}

.custom-text-field-error > div.Mui-focused > fieldset {
  /* border-color: #f82020 !important; */
}

.muirtl-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  /* color: #c9c9c9 !important; */
  /* top: 0px; */
}

.custom-text-field fieldset {
  border-radius: 6px;
  border-color: #c9c9c9 !important;
}

.custom-text-field-error fieldset {
  border-color: #f82020 !important;
}

.custom-text-field input {
  padding: 10.5px 14px;
}

.custom-text-field label {
  /* top: -6px; */
  color: #c9c9c9 !important;
  font-size: 14px;
}

.custom-text-field label {
  top: -5px;
  color: #c9c9c9 !important;
  font-size: 14px;
}

.muirtl-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  /* top: -5px !important; */
}

.custom-text-field label.Mui-focused {
  top: 0px;
  color: #c9c9c9 !important;
}

.custom-text-field label.MuiFormLabel-filled {
  top: 0px;
}

.label-field-less1 fieldset span {
  padding: 0px 5px 0 -5px !important;
}

.label-field-less2 fieldset span {
  padding: 0px 5px 0 -7px !important;
}

.label-field-less7 fieldset span {
  padding: 0px 5px 0 0px !important;
}

.label-field-more15 fieldset span {
  padding: 0px 5px 0 15px !important;
}

.label-field-more5 fieldset span {
  padding: 0px 5px 0 5px !important;
}

.label-field-more7 fieldset span {
  padding: 0px 5px 0 7px !important;
}

.label-field-more10 fieldset span {
  padding: 0px 5px 0 10px !important;
}

.label-field-more12 fieldset span {
  padding: 0 6px 0 12px !important;
}

.label-field-more14 fieldset span {
  padding: 0 6px 0 14px !important;
}

.label-field-less .muirtl-yjsfm1 {
  padding: 0 6px 0 0px !important;
}

/* contact */
.custom-text-field-contact {
  width: 42vw !important;
  background: #fff;
}

.width20vw {
  width: 20.5vw !important;
}

.width-100 {
  width: 100% !important;
}

.input_custom2::placeholder {
  color: #c9c9c9;
}

.borda:focus {
  border: 1px solid #33e0a0;
  border-left: 0px solid #33e0a0;
}

@media only screen and (max-width: 767.5px) {
  .custom-text-field-contact {
    width: 100% !important;
  }

  .custom-text-field-error {
  }

  .custom-text-field.fs-res-12 label {
    font-size: 12px;
  }

  .label-field-other fieldset span {
    width: 226px;
  }

  .width20vw {
    width: 100% !important;
  }
}

/* api  */
@media only screen and (min-width: 767.5px) {
  .maxh-blog {
    max-height: 404px;
  }
}

@media only screen and (max-width: 767.5px) {
  .cat-item2-child-caro:first-child {
    margin-right: 8px;
  }

  .cat-item-child-scro:first-child {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 575.5px) {
  .cat-item-child-scro:last-child {
    margin-left: 1px;
  }
}

/* @media only screen and (min-width: 767.5px) {
  .rec-card {
    max-width: 348.663px;
  }
} */
.show-2line {
  line-height: 1.5em;
  height: 3em;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  /* prevents extra lines from being visible */
}

@media only screen and (max-width: 767.5px) {
  .maxw-100 {
    max-width: 100%;
  }
}

.ifram-bus {
  width: 151px;
  height: 123px !important;
  /* margin-left: 24px; */
}

/* home body */
@media only screen and (min-width: 767.5px) {
  .index-rec-cards {
    display: flex;
    justify-content: space-between;
  }
  .index-rec-cards2 {
    display: flex;
    justify-content: right !important;
  }

  .index-rec-cards div.rec-center {
    padding: 0 5.4px;
  }

  .index-rec-cards div.rec-center:last-child {
    padding-right: 10.6px !important;
    padding-left: 0px !important;
  }
  .index-rec-cards2 div.rec-center:last-child {
    padding-right: 5.4px !important;
    padding-left: 0px !important;
  }

  .index-rec-cards div.rec-center:first-child {
    padding-left: 10.6px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 767.5px) {
  .index-rec-cards {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }

  .index-rec-cards div.rec-center:not(:last-child) {
    margin-bottom: 14px;
  }
}

/* @media only screen and (max-width: 767.5px) {
  .index-rec-cards {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -ms-center;
  }
  .index-rec-cards div:not(:last-child) {
    margin-bottom: 10px;
  }
} */

/* skeleton */
.h-skeleton-inherti {
  height: inherit !important;
}

.h-skeleton-inherti > div > span {
  height: 100%;
}

.skeleton-gish-rec {
  margin-left: 0px;
  float: left;
  margin-top: 42px !important;
}

.skeleton-gish-seq {
  margin-left: 0px;
  float: left;
  /* margin-top: 8px !important */
}

.skeleton-text-rec2 {
  max-width: 120px;
  width: 100%;
  margin-left: 24px;
  height: 34px !important;
}

.skeleton-currency {
  width: 100%;
  margin: 0px 12px 0 0;
  margin-top: 0px !important;
  height: 18px !important;
}

.skeleton-text-cat {
  /* height: 12px; */
  margin: 0 18px;
}

@media only screen and (max-width: 575.5px) {
  .skeleton-gish-rec {
    margin-top: 25px !important;
  }

  .skeleton-gish-seq {
    float: right;
    margin-top: -4px !important;
  }

  .skeleton-text-cat {
    margin: 0 16px;
    width: 100%;

    height: 8px !important;
  }
}

.radius-2 {
  border-radius: 6px;
}
.height-100 {
  height: 100% !important;
}
.margin-t-41px {
  margin-top: 41px;
}

.com-empty-biz {
  height: 100%;
  padding: 0 0 16px 0;
  font-size: 20px;
  color: #005589;
}

.com-empty-biz > div {
  width: 100%;
  height: inherit;
  margin: 0px 0px 20px 0;
  /* padding: 91px 97px 107px 110px; */
  border-radius: 6px;
  border: solid 1px #c9c9c9;
}
.img-com-empty-biz {
  width: 40px;
  height: 40px;
  margin: 16px 0;
}

#rb-chip-skeleton {
  width: 100px;
  height: 45px;
  border-radius: 6px;
  margin-left: 8px;
  margin-top: 12px;
}

@media only screen and (max-width: 767.5px) {
  #rb-chip-skeleton {
    width: 70px;
    height: 30px;
    border-radius: 6px;
    margin-left: 8px;
    margin-top: 8px;
  }
  .com-empty-biz {
    font-size: 15px;
  }
  .h-res-biz-skeleton {
    height: 215px !important;
  }
  .biz-detail-res-skeleton {
    /* max-height: 80px;*/
    margin: 0px 36.5px !important;
    /* width: 80%; */
  }
  .bg-f7f7f7 {
    background-color: #f7f7f7;
    padding: 15px 0;
  }
}
/* chack box business */
.container-s2 > span {
  padding: 0px;
  margin-left: 12px;
}

/* .container-s2 > span:hover {
  background-color: unset;
  cursor: auto;
} */
.right-0 {
  right: 0px !important;
}
.border-radius-50percent {
  border-radius: 50%;
}

/*  */
.card-tit-img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 4px;
}

.biz-icon-skeleton {
  position: absolute !important;
  left: 0px;
  bottom: 0px;
}
.biz-icon-skeleton2 {
  position: absolute !important;
  left: 40px;
  bottom: 0px;
}

.s5-modal24 {
  font-size: 14px;
  color: #005589;
  margin-right: 4px;
}
.pmodal {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #005589;
  margin-top: 20px;
}
.width-unset {
  width: unset !important;
}
.auto10.auto10-new > div > div > div > div.Mui-focused > fieldset,
.auto10.auto10-new
  > div
  > div
  > div
  > div
  > .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline.Mui-focused,
.auto10.auto10-new
  > div
  > div
  > div
  > div
  > .MuiOutlinedInput-notchedOutline.Mui-focused {
  border: solid 1px #33e0a0 !important;
}
.auto10.auto10-new
  > div
  > div
  > div
  > div
  > .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline,
.auto10.auto10-new > div > div > div > div > .MuiOutlinedInput-notchedOutline {
  border: solid 1px #c9c9c9 !important;
  border-radius: 6px;
}

.auto10.auto10-new > div > div > div > .MuiOutlinedInput-root,
.auto10.auto10-new > div > div > div > .MuiAutocomplete-inputRoot {
  height: 55px;
}

@media only screen and (max-width: 767.5px) {
  .auto10.auto10-new > div > div > div > .MuiOutlinedInput-root,
  .auto10.auto10-new > div > div > div > .MuiAutocomplete-inputRoot {
    height: 47px;
  }
}
/* result2 page */

.result-filter2 {
  margin-top: 4px;
  display: flex;
  justify-content: right;
  align-items: center;
  border-bottom: solid 1px #6f98b1;
  height: 56px;
  position: sticky !important;
  top: 0px;
  z-index: 1;
  background-color: #fff;
  padding: 0 16px;
  width: 100%;
  /* overflow-y: hidden; */
}

.result-filter2::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  max-width: 26.66vw;
  height: 7px !important;
  border-radius: 6px !important;
  background-color: #f1f1f1 !important;
  margin: 5px;
}

.result-filter2::-webkit-scrollbar-track {
  /* display: none; */
  background-color: #f1f1f1 !important;
  border-radius: 6px;
  border-radius: 6px !important;
  background-clip: padding-box;
  margin: 5px;
}

.result-filter2::-webkit-scrollbar-thumb {
  height: 5px !important;
  border-radius: 6px !important;
  background-clip: padding-box;
  /* color: #babac0 !important; */
  /* border: 0.5px solid #fff !important; */
  /* margin: 15px; */
}

/* .result-filter2 {
  height: auto;
  margin: 0px 0px 22px 0px;
  padding: 0px 0px;
  border-radius: 6px;
  border: solid 1px #c9c9c9;
  z-index: 2;
} */

.result-filter-div2 {
  height: 45px;
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #005589;
}
.color-005589 {
  color: #005589;
}
.result2-row {
  height: 100%;
}
.result-map-change-div {
  /* position: inherit;
  bottom: 67px; */
  margin-right: 16px;
  top: 231px;
  position: absolute !important;
  /* top: unset; */
  z-index: 1;
  /* bottom: 65px; */
  /* left: 50%; */
  /* margin-left: -19px; */
}
.result-map-change-div-open {
  margin-right: 16px;
  position: absolute !important;
  z-index: 1 !important;
  top: -68px;
  bottom: unset;
  background-color: #005589;
}
.result-map-change-btn {
  width: 38px;
  height: 38px;
  /* padding: 13.4px 12.5px 13.4px 10.5px; */
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  /* padding: 8px 22px 9px; */
  border-radius: 50%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* position: relative;
  bottom: 24px; */
}

.result-map-change-btn img {
  width: 15px;
  /* height: 22px; */
  margin-right: 0px;
}

.horizontal-sp-banner {
  max-width: 752px;
  border-radius: 6px;
  width: 100%;
}
.horizontal-sp-banner:hover,
.vertical-sp-banner:hover,
.tabliqat-box a > img:hover {
  box-shadow: 0 2px 7px -1px rgb(0 0 0 / 20%), 0 6px 21px -1px rgb(0 0 0 / 12%) !important;
}
.vertical-sp-banner {
  max-width: 752px;
  border-radius: 6px;
  width: 100%;
}
@media only screen and (max-width: 767.5px) {
  .result-filter2 {
    margin-top: 0px;
    border-bottom: solid 0px #6f98b1;
    height: auto;
    /* position: sticky !important; */
    /* top: 0px; */
    z-index: 1;
    background-color: #fff;
    padding: 10px 0px;
    width: 100%;
    overflow-y: hidden;
    position: absolute !important;
    top: 179px !important;
  }
  .result-filter2::-webkit-scrollbar {
    display: none;
  }
  .biz-icon-skeleton2 {
    position: absolute !important;
    left: 3px;
    bottom: -2px;
  }

  .horizontal-sp-banner {
    width: 95%;
  }
  .vertical-sp-banner {
    max-width: 295px;
    width: 100%;
  }
}
